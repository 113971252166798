import { Something } from "./interfaces";
import { images } from "./images";
import { music } from "./music";
import { movieClips, otherVideos } from "./videos";
import { writings } from "./writing";
import { websites } from "./websites";

export const somethings: Something[] = [
  ...music,
  ...movieClips,
  ...otherVideos,
  ...writings,
  ...images,
  ...websites,
].map((el, i) => ({ ...el, id: `${el.contentType}-${i}` }));
