/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getMediaQuery } from "../../../utils";

export const KyrieImage = () => (
  <div
    css={css`
      display: flex;
      justify-content: center;
    `}
  >
    <img
      src="/images/kyrie.png"
      css={css`
        width: 90%;
        ${getMediaQuery("tabletLg")} {
          width: 60%;
          height: auto;
        }
        ${getMediaQuery("desktopMd")} {
          width: 50%;
          height: auto;
        }
      `}
    />
  </div>
);
