/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getMediaQuery } from "../../../utils";
import parse from "html-react-parser";
import { aboutCopy } from "../../../copy/aboutCopy";

export const AboutText = () => (
  <div css={aboutText}>
    <img
      src={aboutCopy.pageHeadingImg.src}
      alt={aboutCopy.pageHeadingImg.alt}
      css={css`
        width: 60%;
        ${getMediaQuery("tabletLg")} {
          width: 50%;
        }
        ${getMediaQuery("desktopMd")} {
          width: 40%;
        }
        ${getMediaQuery("desktopMd")} {
          width: 30%;
        }
      `}
    />
    <div
      css={css`
        ${getMediaQuery("desktopMd")} {
          width: 70%;
        }
      `}
    >
      <p>{parse(aboutCopy.mainInfo)}</p>
    </div>
  </div>
);

const aboutText = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  ${getMediaQuery("mobileLg")} {
    font-size: 18px;
  }
  ${getMediaQuery("tabletSm")} {
    font-size: 14px;
  }
  ${getMediaQuery("tabletLg")} {
    font-size: 16px;
  }
  ${getMediaQuery("desktopMd")} {
    font-size: 18px;
  }
`;
