import { css, SerializedStyles } from "@emotion/react";
import { SongTag } from "./somethings";
import { Breakpoint } from "./interfaces";

export const breakpointVals: Record<Breakpoint, number> = {
  mobileSm: 375,
  mobileLg: 480,
  tabletSm: 640,
  tabletMd: 768,
  tabletLg: 960,
  desktopSm: 1024,
  desktopMd: 1280,
  desktopLg: 1440,
};

export const breakpoints: Record<Breakpoint, string> = Object.assign(
  {},
  ...Object.entries(breakpointVals).map(([breakpoint, value]) => ({
    [breakpoint]: `${value}px`,
  }))
);

export const SONGS: Record<string, SongTag> = {
  halo: "Halo",
  imNotSure: "I'm Not Sure",
  immaculate: "Immaculate",
  myAngel: "My Angel",
  neverBeSorry: "Never Be Sorry",
};

export const eternityLinks = {
  streaming: {
    spotify:
      "https://open.spotify.com/album/1FBLGpeN1qt9CmSMwMN05Q?si=4TLyrNFyRIWlulSf1Xlalg",
    bandcamp: "https://kyriehalo.bandcamp.com/album/eternity",
    youtube:
      "https://youtube.com/playlist?list=OLAK5uy_kPurHYltqhHQ4i-45D9l-l0_u802Y6Ld4&si=vt0MALIWaU9-U9ep",
  },
  tracks: {
    halo: {
      displayText: "halo by kyrie halo",
      link: "https://kyriehalo.bandcamp.com/track/halo",
      src: "https://bandcamp.com/EmbeddedPlayer/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=3699908731/transparent=true/",
      href: "https://kyriehalo.bandcamp.com/album/eternity",
      songTag: SONGS.halo,
    },
    imNotSure: {
      displayText: "i'm not sure by kyrie halo",
      link: "https://kyriehalo.bandcamp.com/track/im-not-sure",
      src: "https://bandcamp.com/EmbeddedPlayer/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=184965481/transparent=true/",
      href: "https://kyriehalo.bandcamp.com/album/eternity",
      songTag: SONGS.imNotSure,
    },
    immaculate: {
      displayText: "immaculate by kyrie halo",
      link: "https://kyriehalo.bandcamp.com/track/immaculate",
      src: "https://bandcamp.com/EmbeddedPlayer/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=3371028147/transparent=true/",
      href: "https://kyriehalo.bandcamp.com/album/eternity",
      songTag: SONGS.immaculate,
    },
    myAngel: {
      displayText: "my angel by kyrie halo",
      link: "https://kyriehalo.bandcamp.com/track/my-angel",
      src: "https://bandcamp.com/EmbeddedPlayer/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=2702287985/transparent=true/",
      href: "https://kyriehalo.bandcamp.com/album/eternity",
      songTag: SONGS.myAngel,
    },
    neverBeSorry: {
      displayText: "never be sorry by kyrie halo",
      link: "https://kyriehalo.bandcamp.com/track/never-be-sorry-2",
      src: "https://bandcamp.com/EmbeddedPlayer/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/track=855040823/transparent=true/",
      href: "https://kyriehalo.bandcamp.com/album/eternity",
      songTag: SONGS.neverBeSorry,
    },
  },
};

export const IFRAME_SIZES_VALUES = {
  mobile: { width: 285, height: 160 },
  tablet: { width: 320, height: 180 },
  desktop: { width: 480, height: 270 },
};

export const WEBSITE_IFRAME_SIZES_VALUES = {
  mobile: { width: 295, height: 170 },
  tablet: { width: 330, height: 190 },
  desktop: { width: 640, height: 350 },
};

export const IFRAME_SIZES: Record<string, SerializedStyles> = Object.assign(
  {},
  ...Object.entries(IFRAME_SIZES_VALUES).map(([screen, { width, height }]) => ({
    [screen]: css`
      width: ${width}px;
      height: ${height}px;
    `,
  }))
);

export const WEBSITE_IFRAME_SIZES: Record<string, SerializedStyles> =
  Object.assign(
    {},
    ...Object.entries(WEBSITE_IFRAME_SIZES_VALUES).map(
      ([screen, { width, height }]) => ({
        [screen]: css`
          width: ${width}px;
          height: ${height}px;
        `,
      })
    )
  );
export const IMAGE_SIZES = {
  mobile: css`
    width: 200px;
    height: auto;
  `,
  tablet: css`
    width: 300px;
    height: auto;
  `,
  desktop: css`
    width: 400px;
    height: auto;
  `,
};

export const WRITING_SIZES = {
  mobile: css`
    width: 290px;
    height: max-content;
    max-height: 300px;
  `,
  tablet: css`
    width: 350px;
    height: max-content;
    max-height: 300px;
  `,
  desktop: css`
    width: 400px;
    min-width: 400px;
    height: max-content;
    max-height: 300px;
  `,
};
