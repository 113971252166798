/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ComputerWindow } from "./ComputerWindow";
import Draggable from "react-draggable";
import { AnswerComputerWindowProps } from "./interfaces";
import { useBorderType, useHideWindow, useScreenSize } from "../hooks";
import { NoDragSection } from "./NoDragSection";
import { PropsWithChildren, useState } from "react";
import { ContentType } from "../somethings";
import { getMediaQuery, getWidthAndHeight } from "../utils";
import { getPadding } from "../utils/getPadding";

export const BasicWindow = ({
  contentType,
  hideWindow,
  onCloseClick,
  children,
}: PropsWithChildren & {
  contentType?: ContentType;
  hideWindow: boolean;
  onCloseClick: () => void;
}) => {
  const { borderType, setBorderType } = useBorderType();
  const screenSize = useScreenSize();

  const centredCoords = {
    mobileSm: { x: -165, y: 100 },
    mobileLg: { x: -170, y: 100 },
    tabletSm: { x: -200, y: 100 },
    tabletMd: { x: -200, y: 100 },
    tabletLg: { x: -200, y: 100 },
    desktopSm: { x: -200, y: 100 },
    desktopMd: { x: -200, y: 100 },
    desktopLg: { x: -200, y: 100 },
  };

  return (
    <Draggable positionOffset={centredCoords[screenSize]} cancel=".no-drag">
      <div
        css={css`
          display: ${hideWindow ? "none" : "block"};
          position: absolute;
          z-index: 5;
        `}
      >
        <ComputerWindow>
          <ComputerWindow.TopBar>
            <ComputerWindow.TopBar.CloseButton
              borderType={borderType}
              onClick={() => {
                setBorderType("inset");
                onCloseClick();
              }}
            />
          </ComputerWindow.TopBar>
          <div
            css={css`
              color: black;
              display: flex;
              flex-direction: column;
              gap: 10px;
              font-family: "Rainy Hearts";
              -webkit-font-smoothing: auto;
              font-smoothing: auto;
              padding-top: 5px;
              padding-bottom: 10px;
              button {
                color: black;
              }
              ${contentType &&
              css`
                ${getWidthAndHeight("mobile", contentType)}
                ${getMediaQuery("tabletSm")} {
                  ${getWidthAndHeight("tablet", contentType)}
                }
                ${getMediaQuery("desktopSm")} {
                  ${getWidthAndHeight("desktop", contentType)}
                }
                ${getPadding("writing")};
              `}
            `}
          >
            {children}
          </div>
        </ComputerWindow>
      </div>
    </Draggable>
  );
};
