export const aboutCopy = {
  pageHeadingImg: {
    src: "/images/display/abouttextpink.png",
    alt: "About",
  },
  mainInfo:
    "I'm an independent singer-songwriter and producer making music in my home. I'm also a programmer and this website is my way of collecting and sharing not only my music but all sorts of things I find beautiful, inspiring, interesting, funny etc. <br/> <br/> It's an ode to an era of the web when everyone had the chance to make their own little dreamworlds for people to stumble upon. I hope you find something that you like here.",
  linksSection: {
    heading: "Some other cool places to visit",
    webLinks: [
      "https://www.cameronsworld.net/",
      "https://film-grab.com/",
      "https://rarehistoricalphotos.com/",
      "https://www.radioisaforeigncountry.org/",
      "https://cari.institute/aesthetics",
      "https://www.cabinetmagazine.org/",
      "https://www.gutenberg.org/",
    ],
  },
};
