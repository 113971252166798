/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { copy } from "../copy";
import { getMediaQuery } from "../../../utils";
import parse from "html-react-parser";
import { Quote } from "../home";

export const NotSureResponse = ({
  quote,
  resetAnswer,
  resetQuote,
}: {
  quote: Quote;
  resetAnswer: () => void;
  resetQuote: () => void;
}) => (
  <div
    css={css`
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      gap: 10px;
    `}
  >
    <div css={letterContainer}>
      <p>
        <i>{parse(quote.quote)}</i>
      </p>
      <p>{quote.author}</p>
    </div>
    <div
      css={css`
        display: flex;
        gap: 10px;
      `}
    >
      <button onClick={resetQuote}>{copy.notSureResponse.notSureButton}</button>
      <button onClick={resetAnswer}>{copy.notSureResponse.readyButton}</button>
    </div>
  </div>
);

const letterContainer = css`
  background: no-repeat url("/images/display/notepadsheet.png");
  padding: 10px 10px 10px 80px;
  color: black;
  a {
    color: black;
  }
  font-family: Times, "Times New Roman", serif;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  img {
    width: 50px;
  }
  font-weight: 540;
  max-width: 270px;
  ${getMediaQuery("desktopMd")} {
    width: 390px;
    max-width: 400px;
  }
`;
