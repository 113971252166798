/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";

export const NoDragSection = ({
  width,
  children,
}: PropsWithChildren & { width?: string }) => (
  <div
    className="no-drag"
    css={css`
      width: ${width};
    `}
  >
    {children}
  </div>
);
