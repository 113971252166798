import { useMemo } from "react";
import { useScreenSize } from "./useScreenSize";

export interface Coords {
  x: number;
  y: number;
}
export const useGetCoords = () => {
  type WindowCoords = {
    [key in "mobile" | "tablet" | "desktop"]: Coords[];
  };

  const computerWindowCoords: WindowCoords = {
    mobile: [
      { x: 50, y: 250 },
      { x: 20, y: 70 },
      { x: 10, y: 400 },
    ],
    tablet: [
      { x: 250, y: 400 },
      { x: 50, y: 150 },
      { x: 280, y: 200 },
    ],
    desktop: [
      { x: 250, y: 400 },
      { x: 50, y: 150 },
      { x: 450, y: 200 },
    ],
  };

  const screenSize = useScreenSize();

  const coords = useMemo(() => {
    switch (screenSize) {
      case "desktopSm":
      case "desktopMd":
      case "desktopLg":
        return computerWindowCoords.desktop;
      case "tabletSm":
      case "tabletMd":
      case "tabletLg":
        return computerWindowCoords.tablet;
      default:
        return computerWindowCoords.mobile;
    }
  }, [screenSize]);

  return coords;
};
