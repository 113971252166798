import { Music } from "../somethings";

export const musicILove: Music[] = [
  {
    src: "/images/album_covers/whitealbum.jpg",
    title: "The Beatles",
    artist: "The Beatles",
    year: "1968",
    link: "https://youtu.be/tSk5U4oHhu0?si=iHo51zi06HulGabW",
  },
  {
    src: "/images/album_covers/whiteponycover.jpg",
    title: "White Pony",
    artist: "Deftones",
    year: "2000",
    link: "https://youtu.be/yt86EOxIgwE?si=TRNzaeSYd0EOglWX",
  },
  {
    src: "/images/album_covers/xxxcover.jpg",
    title: "XXX",
    artist: "Danny Brown",
    year: "2011",
    link: "https://youtu.be/LgCU3rC6tqU?si=s3IdAPDb_5I034zn",
  },
  {
    src: "/images/album_covers/revolvercover.jpg",
    title: "Revolver",
    artist: "The Beatles",
    year: "1966",
    link: "https://youtu.be/BT5j9OQ7Sh0?si=tyHnL6zzE1fsQWE8",
  },
  {
    src: "/images/album_covers/freshcover.jpg",
    title: "Fresh",
    artist: "Sly and the Family Stone",
    year: "1973",
    link: "https://youtu.be/xGkWuZxuP-4?si=DoABBLZRUrdFFNAX",
  },
  {
    src: "/images/album_covers/isthisitcover.png",
    title: "Is This It",
    artist: "The Strokes",
    year: "2001",
    link: "https://youtu.be/ay3AFRcq5b4?si=duvCKF8b1bqrGfKK",
  },
  {
    src: "/images/album_covers/ridofmecover.jpg",
    title: "Rid of Me",
    artist: "PJ Harvey",
    year: "1993",
    link: "https://youtu.be/a5x8p22UU_4?si=jKcXkuTG9BeNswVU",
  },
  {
    src: "/images/album_covers/madvillainycover.png",
    title: "Madvillainy",
    artist: "Madvillain",
    year: "2004",
    link: "https://youtu.be/WoxHqRPvZJE?si=j1_duCpQ4R_bpIro",
  },

  {
    src: "/images/album_covers/surferrosacover.jpg",
    title: "Surfer Rosa",
    artist: "Pixies",
    year: "1988",
    link: "https://youtu.be/Kc4NKz9Ofmk?si=wEWkj7g7-iREfMIh",
  },
  {
    src: "/images/album_covers/likeavirgincover.jpg",
    title: "Like a Virgin",
    artist: "Madonna",
    year: "1984",
    link: "https://youtu.be/SOhJHS7Rvrg?si=D2ao7sXrQFb67JrE",
  },
  // {
  //   src: "/images/album_covers/exileonmainstreetcover.webp",
  //   title: "Exile on Main St.",
  //   artist: "The Rolling Stones",
  //   year: "1972",
  //   link: "https://youtu.be/5R3xFy85fVE?si=Im82IzNjoBzz6kVH",
  // },
  {
    src: "/images/album_covers/xocover.jpg",
    title: "XO",
    artist: "Elliott Smith",
    year: "1998",
    link: "https://youtu.be/F2Be7vCN0D4?si=-VusqozBKSgKE6Ww",
  },
  {
    src: "/images/album_covers/madonnacover.png",
    title: "Madonna",
    artist: "Madonna",
    year: "1983",
    link: "https://youtu.be/ThHz9wlBeLU?si=CwlYjwWg3VCt8zUl",
  },
  {
    src: "/images/album_covers/topimpabutterflycover.png",
    title: "To Pimp A Butterfly",
    artist: "Kendrick Lamar",
    year: "2015",
    link: "https://youtu.be/Ti9LYkpZLsw?si=Zy9kjXr2vQuPNr0p",
  },
  {
    src: "/images/album_covers/romancandlecover.jpg",
    title: "Roman Candle",
    artist: "Elliott Smith",
    year: "1994",
    link: "https://youtu.be/DCZbnvzRUjQ?si=6F82p-CEWBtunDla",
  },
  {
    src: "/images/album_covers/ultraviolencecover.jpg",
    title: "Ultraviolence",
    artist: "Lana del Rey",
    year: "2014",
    link: "https://youtu.be/oKxuiw3iMBE?si=TbiYpfOTYRjI4B1n",
  },
  {
    src: "/images/album_covers/mariahnumber1s.jpg",
    title: "#1's",
    artist: "Mariah Carey",
    year: "1998",
    link: "https://youtu.be/-tCTm5M3Cp8?si=uX-VnpNjVCmor67S",
  },
  {
    src: "/images/album_covers/spiderlandcover.jpg",
    title: "Spiderland",
    artist: "Slint",
    year: "1991",
    // link: "https://youtu.be/6yEgcb167k4?si=ofRa7gbUsJsve_7t",
    link: "https://youtu.be/gBfoQjJ7guk?si=TtaWR3Pr0kJ3pRQ2",
  },

  {
    src: "/images/album_covers/rockettorussiacover.jpg",
    title: "Rocket to Russia",
    artist: "Ramones",
    year: "1977",
    link: "https://youtu.be/8Z1-R2LEuao?si=e40_bjq-cclVwXYj",
  },
  {
    src: "/images/album_covers/lovelesscover.jpg",
    title: "Loveless",
    artist: "My Bloody Valentine",
    year: "1991",
    link: "https://youtu.be/hSI_9P9rRt4?si=LaZ2OhZ9g4T5l2Ia",
  },
  {
    src: "/images/album_covers/surrealisticpillow.jpg",
    title: "Surrealistic Pillow",
    artist: "Jefferson Airplane",
    year: "1967",
    // link: "https://youtu.be/5SXy7G5dtb4?si=_K7FS-zM40AjWkRP",
    link: "https://youtu.be/SrGSt5eDt9o?si=uI-YLbIq-7Sdj5EB",
  },
  {
    src: "/images/album_covers/lanquiditycover.jpg",
    title: "Lanquidity",
    artist: "Sun Ra",
    year: "1978",
    link: "https://youtu.be/7O4c6D8Z9a8?si=bBbBQeoJW_-GnPwB",
  },
  {
    src: "/images/album_covers/qotsacover.jpg",
    title: "Queens of the Stone Age",
    artist: "Queens of the Stone Age",
    year: "1998",
    // link: "https://youtu.be/wjNbxJttwP8?si=K7wpLkmQ-5XJh1fB",
    link: "https://youtu.be/C1xCgeSwdNA?si=vGYFe82BTd_zSA6o",
  },

  {
    src: "/images/album_covers/houndsoflovecover.png",
    artist: "Kate Bush",
    title: "Hounds of Love",
    year: "1985",
    // link: "https://youtu.be/pllRW9wETzw?si=q1GsufMwEbvmEJKZ",
    link: "https://youtu.be/cnJO_vPxutk?si=L17hzt51_uMiuAcg",
  },

  {
    src: "/images/album_covers/graduationcover.jpg",
    title: "Graduation",
    artist: "Kanye West",
    year: "2007",
    link: "https://youtu.be/Vcljvd4Ef_o?si=S6nuq4n-s79RJ9fm",
  },
  {
    src: "/images/album_covers/candyapplegreycover.jpeg",
    title: "Candy Apple Grey",
    artist: "Hüsker Dü",
    year: "1986",
    link: "https://youtu.be/DxKcEurO5jA?si=nKG1mvOWF8vOyJYT",
  },

  {
    src: "/images/album_covers/odesseyandoraclecover.jpg",
    title: "Odessey and Oracle",
    artist: "The Zombies",
    year: "1968",
    link: "https://youtu.be/6FndvMeXUgU?si=kIn5qO8xvPyRcYn8",
  },
  {
    src: "/images/album_covers/kalacover.png",
    title: "Kala",
    artist: "M.I.A",
    year: "2007",
    link: "https://youtu.be/6U1mNglJJe0?si=zTH83E7n7RjnSHZC",
  },
  // {
  //   src: "/images/album_covers/donnasummercover.jpg",
  //   title: "On the Radio: Greatest Hits Volumes I & II",
  //   artist: "Donna Summer",
  //   year: "1979",
  // },
  {
    src: "/images/album_covers/laughingstockcover.jpg",
    title: "Laughing Stock",
    artist: "Talk Talk",
    year: "1991",
    link: "https://youtu.be/VkcBQCFp4NE?si=_VUc1bS2vu-W9QMg",
  },
  {
    src: "/images/album_covers/zenarcadecover.jpg",
    title: "Zen Arcade",
    artist: "Hüsker Dü",
    year: "1984",
    link: "https://youtu.be/JW2yZamiBlg?si=5pLdwWwoWQV41IUZ",
  },
  {
    src: "/images/album_covers/livethroughthiscover.png",
    title: "Live Through This",
    artist: "Hole",
    year: "1994",
    link: "https://youtu.be/nIbu7QtuARk?si=Qle6TuRBzMfmxRIN",
  },
  {
    src: "/images/album_covers/aroundthefurcover.jpg",
    title: "Around the Fur",
    artist: "Deftones",
    year: "1997",
    link: "https://youtu.be/aopye0lJUtI?si=zWMeOP-lNONN-qb3",
  },
  // {
  //   src: "/images/album_covers/thefamecover.png",
  //   title: "The Fame",
  //   artist: "Lady Gaga",
  //   year: "2008",
  //   link: "https://youtu.be/Gm_C2nqanJA?si=2lapGdZB6HrBtF_S",
  // },
  {
    src: "/images/album_covers/backtoblackcover.jpg",
    title: "Back to Black",
    artist: "Amy Winehouse",
    year: "2006",
    link: "https://www.youtube.com/watch?v=bS8-qQojlhA",
  },
  {
    src: "/images/album_covers/anemptyblissbeyondthisworld.jpeg",
    title: "An empty bliss beyond this World",
    artist: "The Caretaker",
    year: "2011",
    link: "https://youtu.be/sxKeP_AD9L4?si=g89oDFLGeUPud717",
  },
  {
    src: "/images/album_covers/treasurecover.jpeg",
    title: "Treasure",
    artist: "Cocteau Twins",
    year: "1984",
    link: "https://youtu.be/o7tcFKakZTA?si=SJmxGGIg6KOmulvF",
  },
  {
    src: "/images/album_covers/lonesomecrowdedwestcover.jpg",
    title: "Lonesome Crowded West",
    artist: "Modest Mouse",
    year: "1997",
    link: "https://youtu.be/CjwhIzByM3E?si=JPReOuAY_s9TyTna",
  },
  {
    src: "/images/album_covers/elmalquerer.png",
    title: "El Mal Querer",
    artist: "Rosalía",
    year: "2018",
    link: "https://youtu.be/isfVUG54CLc?si=C1zo3z2TdpCp2G6u",
  },
  {
    src: "/images/album_covers/nfrcover.jpeg",
    title: "Norman Fucking Rockwell!",
    artist: "Lana del Rey",
    year: "2019",
    link: "https://youtu.be/nbcXvlEa7Wk?si=bmOHaoIVuI_yo2RD",
  },
  {
    src: "/images/album_covers/diarycover.jpg",
    title: "Diary",
    artist: "Sunny Day Real Estate",
    year: "1994",
    // link: "https://youtu.be/gAotWVmVRS4?si=HYp7lNnfEBSg7Vxz",
    link: "https://youtu.be/ETtXtl-VXcY?si=buMpJl2JEeJXJo8j",
  },
  {
    src: "/images/album_covers/likeaprayercover.png",
    title: "Like a Prayer",
    artist: "Madonna",
    year: "1989",
    link: "https://youtu.be/79fzeNUqQbQ?si=ENz5EzG-hH-Rxww9",
  },
  {
    src: "/images/album_covers/treatscover.webp",
    title: "Treats",
    artist: "Sleigh Bells",
    year: "2010",
    link: "https://youtu.be/0OwYkI7m1DM?si=cV2phLxaNNPwkOKd",
  },
  {
    src: "/images/album_covers/dummycover.png",
    title: "Dummy",
    artist: "Portishead",
    year: "1994",
    link: "https://youtu.be/NJ3CA_RThDY?si=72j1RVyOkPs9UXO8",
  },
  {
    src: "/images/album_covers/truebluecover.png",
    title: "True Blue",
    artist: "Madonna",
    year: "1986",
    link: "https://youtu.be/IzAO9A9GjgI?si=XYU18AuP2iZy1uBP",
  },
  {
    src: "/images/album_covers/elliottsmithcover.webp",
    title: "Elliott Smith",
    artist: "Elliott Smith",
    year: "1995",
    link: "https://youtu.be/MJIXDOVRtVk?si=hq65naemgin_ADO7",
  },
  {
    src: "/images/album_covers/mbvcover.jpg",
    title: "mbv",
    artist: "My Bloody Valentine",
    year: "2013",
    // link: "https://youtu.be/y2FQ3ih0MoE?si=N12M_zMNKcwVBscW",
    link: "https://youtu.be/6oXJus1ajIU?si=EEtf29DUcy1ZAmQO",
  },
  {
    src: "/images/album_covers/velocitydesigncomfort.png",
    title: "Velocity : Design : Comfort",
    artist: "Sweet Trip",
    year: "2003",
    link: "https://youtu.be/o-liUFpa9ZQ?si=jmLDZTRy-FiIBbXt",
  },
  {
    src: "/images/album_covers/nevermindcover.jpg",
    artist: "Nirvana",
    title: "Nevermind",
    year: "1991",
    link: "https://youtu.be/AJUpHxlJUNQ?si=IJ-YwE2l08uB68zk",
  },
  {
    src: "/images/album_covers/abbeyroadcover.jpg",
    title: "Abbey Road",
    artist: "The Beatles",
    year: "1969",
    link: "https://youtu.be/tAe2Q_LhY8g?si=uJ3OerJ67ubSgt2C",
  },
  {
    src: "/images/album_covers/amcover.jpeg",
    title: "Am",
    artist: "Ovlov",
    year: "2013",
    link: "https://youtu.be/glV6-uXxsjo?si=G3j_cr0pqgt9x1HX",
  },
  {
    src: "/images/album_covers/youdpreferanastronautcover.jpg",
    title: "You'd Prefer an Astronaut",
    artist: "Hum",
    year: "1995",
    link: "https://www.youtube.com/watch?v=5NFVLZ3QjgY",
  },
  {
    src: "/images/album_covers/artaudcover.jpeg",
    title: "Artaud",
    artist: "Pescado Rabioso",
    year: "1973",
    link: "https://youtu.be/k5CNTHzu7eQ?si=ePU48ZZpEXDXadMZ",
  },
  {
    src: "/images/album_covers/porquetevascover.png",
    title: "Porque te vas",
    artist: "Jeanette",
    year: "1976",
    link: "https://www.youtube.com/watch?v=JuLRF7mch_I",
  },
  {
    src: "/images/album_covers/mmfoodcover.jpg",
    title: "Mm..Food",
    artist: "MF DOOM",
    year: "2004",
    link: "https://youtu.be/oYLHXItjzG4?si=94caxbqFMsMf508Q",
  },
  {
    src: "/images/album_covers/thequeenisdeadcover.png",
    title: "The Queen is Dead",
    artist: "The Smiths",
    year: "1986",
    link: "https://youtu.be/qBIHz-07veA?si=wnUFr-kvq36ArcBs",
  },
  {
    src: "/images/album_covers/nighttimemytimecover.webp",
    title: "Night Time, My Time",
    artist: "Sky Ferreira",
    year: "2013",
    link: "https://youtu.be/FFZCyoHfI7Q?si=DU1sdZfHzJWV5UHd",
    // link: "https://youtu.be/rEamE0MYPkg?si=Jd2d7XjFNxuA-I63",
  },
  {
    src: "/images/album_covers/daydreamnationcover.png",
    title: "Daydream Nation",
    artist: "Sonic Youth",
    year: "1988",
    link: "https://youtu.be/wWTkb1xVin0?si=Dg4QH9fe6mEBD5AZ",
  },
  {
    src: "/images/album_covers/goodkidmaadcitycover.jpg",
    title: "Good Kid, M.A.A.D City",
    artist: "Kendrick Lamar",
    year: "2012",
    link: "https://youtu.be/HJNa9Ih3haA?si=72Lf5O6y_0HAljr7",
  },
  {
    src: "/images/album_covers/blondcover.webp",
    title: "Blond",
    artist: "Frank Ocean",
    year: "2016",
    link: "https://youtu.be/r4l9bFqgMaQ?si=PzaD9w9i9xEVropu",
  },
  {
    src: "/images/album_covers/hybridtheory.jpg",
    title: "Hybrid Theory",
    artist: "Linkin Park",
    year: "2000",
    link: "https://youtu.be/KwN_f0fTHoE?si=-T6NNj4HaiTzUaVP",
  },
  {
    src: "/images/album_covers/redmedicinecover.jpg",
    title: "Red Medicine",
    artist: "Fugazi",
    year: "1995",
    link: "https://youtu.be/1J94qkE85uo?si=8rdReALUqfCRjW5P",
  },
  {
    src: "/images/album_covers/clubedaesquinacover.png",
    title: "Clube da Esquina",
    artist: "Milton Nascimento and Lô Borges",
    year: "1972",
    link: "https://www.youtube.com/watch?v=Ie89fdGuazg",
  },
  {
    src: "/images/album_covers/toxicitycover.jpg",
    title: "Toxicity",
    artist: "System of a Down",
    year: "2001",
    link: "https://youtu.be/1xD2ItImFEU?si=UA_hZHLJUeaR_mq4",
  },
  {
    src: "/images/album_covers/thedreamingcover.webp",
    title: "The Dreaming",
    artist: "Kate Bush",
    year: "1982",
    link: "https://www.youtube.com/watch?v=V3XAeg3B0To",
  },
  {
    src: "/images/album_covers/eitherorcover.webp",
    title: "Either/Or",
    artist: "Elliott Smith",
    year: "1997",
    link: "https://youtu.be/ku_BFF6dUo0?si=C1plEpICtdQMnI_b",
  },
  {
    src: "/images/album_covers/doolittlecover.jpg",
    title: "Doolittle",
    artist: "Pixies",
    year: "1989",
    link: "https://youtu.be/qKTLsQIzL4o?si=c4reVRvRDOPoA4DO",
  },
  {
    src: "/images/album_covers/africabrasilcover.jpg",
    title: "África Brasil",
    artist: "Jorge Ben Jor",
    year: "1976",
    link: "https://youtu.be/Kk3Bci3jVBs?si=lh1vSnTlRcyXsu1y",
  },
  {
    src: "/images/album_covers/shangrilas.jpeg",
    title: "Leader of the Pack",
    artist: "The Shangri-Las",
    year: "1965",
    link: "https://www.youtube.com/watch?v=01YePzk29Mc",
  },
  {
    src: "/images/album_covers/b52scover.jpg",
    title: "The B-52's",
    artist: "The B-52's",
    year: "1979",
    link: "https://www.youtube.com/watch?v=n4QSYx4wVQg",
  },
  {
    src: "/images/album_covers/idontwanttogrowupcover.jpg",
    title: "Descendents",
    artist: "I Don't Want To Grow Up",
    year: "1985",
    link: "https://www.youtube.com/watch?v=0OGuRBoxEOk",
  },
  {
    src: "/images/album_covers/thedownwardspiralcover.png",
    title: "The Downward Spiral",
    artist: "Nine Inch Nails",
    year: "1994",
    link: "https://youtu.be/pwjLcwbT-xE?si=w3Xj_-1-0uxID77z",
  },
  {
    src: "/images/album_covers/inuterocover.jpg",
    title: "In Utero",
    artist: "Nirvana",
    year: "1993",
    link: "https://youtu.be/P7B-AlKTdGQ?si=hZS7uKQ2_hTo3-3p",
  },
  {
    src: "/images/album_covers/siamesedreamcover.jpg",
    title: "Siamese Dream",
    artist: "The Smashing Pumpkins",
    year: "1993",
    link: "https://youtu.be/x4Sb0EEDCj4?si=f7BLDOhm5qP1B0yG",
  },
  {
    src: "/images/album_covers/devotioncover.png",
    title: "Devotion",
    artist: "Beach House",
    year: "2008",
    link: "https://youtu.be/qxf6OgbvayM?si=X7MWE27XtK0MtaPw",
  },
  {
    src: "/images/album_covers/version20cover.png",
    title: "Version 2.0",
    artist: "Garbage",
    year: "1998",
    link: "https://youtu.be/Mic6yYmPT84?si=QqLSozcgyQ43qcNu",
  },
  {
    src: "/images/album_covers/aaliyahcover.png",
    title: "Aaliyah",
    artist: "Aaliyah",
    year: "2001",
    link: "https://youtu.be/xgYUj_pAD-w?si=-DJ2-aiWTMT9Hmk6",
  },
  {
    src: "/images/album_covers/motomamicover.webp",
    title: "Motomami",
    artist: "Rosalía",
    year: "2022",
    link: "https://youtu.be/PHvhnnMa7Dc?si=7_SPmqM9NDc41XPf",
  },
  {
    src: "/images/album_covers/mbdtfcover.jpeg",
    title: "My Beautiful Dark Twisted Fantasy",
    artist: "Kanye West",
    year: "2010",
    link: "https://youtu.be/EMnQwBTJnMM?si=QOAzE9zhAkMUc0p3",
  },
  {
    src: "/images/album_covers/heavenorlasvegascover.jpeg",
    title: "Heaven or Las Vegas",
    artist: "Cocteau Twins",
    year: "1990",
    link: "https://youtu.be/PbbUeLkZt74?si=rfH_vHzJ8lupUHy9",
  },
  {
    src: "/images/album_covers/whenthepawncover.jpg",
    title: "When the Pawn...",
    artist: "Fiona Apple",
    year: "1999",
    link: "https://youtu.be/NbxqtbqyoRk?si=qbBI7imuld9VWWy7",
  },
  {
    src: "/images/album_covers/soadcover.jpeg",
    title: "System of a Down",
    artist: "System of a Down",
    year: "1998",
    link: "https://youtu.be/bqNgSEU5YOs?si=mn8WoJXx7RuF0We7",
  },
  {
    src: "/images/album_covers/donutscover.jpg",
    title: "Donuts",
    artist: "J Dilla",
    year: "2006",
    link: "https://youtu.be/ogwouE_Msd4?si=8AikL-C8BKjD-XQx",
  },
  {
    src: "/images/album_covers/areyouexperiencedcover.jpg",
    title: "Are You Experienced",
    artist: "The Jimi Hendrix Experience",
    year: "1967",
    link: "https://youtu.be/cjjkHoR4FXo?si=MohDsaLDUS1o_a2K",
  },
  {
    src: "/images/album_covers/firstbasecover.jpg",
    title: "First Base",
    artist: "Babe Ruth",
    year: "1972",
    link: "https://youtu.be/jQlYBhEdPH8?si=TQ9ft136otN4-l-k",
  },
  {
    src: "/images/album_covers/theresariotgoinoncover.jpeg",
    title: "There's a Riot Goin' On",
    artist: "Sly and the Family Stone",
    year: "1971",
    link: "https://youtu.be/-YzitR0IKW4?si=bicm2dNRDG9RIQIn",
  },
  {
    src: "/images/album_covers/damncover.png",
    title: "Damn",
    artist: "Kendrick Lamar",
    year: "2017",
    link: "https://youtu.be/JYuUOgED1sg?si=5RVNVoGSm573-PtT",
  },
  {
    src: "/images/album_covers/strawberryjamcover.jpg",
    title: "Strawberry Jam",
    artist: "Animal Collective",
    year: "2007",
    link: "https://youtu.be/xE76LFB1saE?si=xDrSrlF25T3QaZCN",
  },
  // {
  //   src: "/images/album_covers/crystalcastlescover.webp",
  //   title: "Crystal Castles",
  //   artist: "Crystal Castles",
  //   year: "2008",
  // },
  // {
  //   src: "/images/album_covers/icare4ucover.jpg",
  //   title: "I Care 4 U",
  //   artist: "Aaliyah",
  //   year: "2002",
  // },

  // {
  //   src: "/images/album_covers/yeezuscover.png",
  //   title: "Yeezus",
  //   artist: "Kanye West",
  //   year: "2013",
  // },
  // {
  //   src: "/images/album_covers/ronettescover.jpg",
  //   title: "Presenting the Fabulous Ronettes",
  //   artist: "The Ronettes",
  //   year: "1964",
  // },

  // {
  //   src: "/images/album_covers/closetoyoucover.jpg",
  //   title: "Close to You",
  //   artist: "The Carpenters",
  //   year: "1970",
  // },
].map((el) => ({ ...el, contentType: "music" }));
