/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useHideWindow, useScreenWidth } from "../../hooks";
import { useState } from "react";
import { VideoWindow } from "../../components/VideoWindow";
import { Footer } from "../../components";
import { RainbowDivider } from "./components/RainbowDivider";
import { breakpointVals } from "../../constants";
import { Music } from "../../somethings";
import { MobileLayout } from "./layouts/mobile";
import { TabletLayout } from "./layouts/tablet";
import { DesktopLayout } from "./layouts/desktop";

export const About = () => {
  const width = useScreenWidth();
  const [selectedAlbum, setSelectedAlbum] = useState<Music>();
  const { hideWindow, setHideWindow } = useHideWindow();
  const layoutProps = { selectedAlbum, setHideWindow, setSelectedAlbum };

  return (
    <div css={mainContainer}>
      {selectedAlbum && (
        <div
          css={css`
            display: ${hideWindow ? "none" : "block"};
          `}
        >
          <VideoWindow
            something={selectedAlbum}
            onCloseClick={() => setSelectedAlbum(undefined)}
            showArtistName={true}
            showYear={true}
          />
        </div>
      )}
      {width < breakpointVals.tabletSm && <MobileLayout {...layoutProps} />}
      {width > breakpointVals.mobileLg && width < breakpointVals.desktopSm && (
        <TabletLayout {...layoutProps} />
      )}
      {width >= breakpointVals.desktopSm && <DesktopLayout {...layoutProps} />}
      <RainbowDivider />
      <Footer />
    </div>
  );
};

const mainContainer = css`
  background: url("/images/starryskygif.webp");
`;
