import { SONGS } from "../constants";
import { Website } from "./interfaces";

export const websites: Website[] = [
  {
    title: "Buffalo buffalo Buffalo buffalo buffalo buffalo Buffalo buffalo",
    link: "https://en.wikipedia.org/wiki/Buffalo_buffalo_Buffalo_buffalo_buffalo_buffalo_Buffalo_buffalo",
  },
  {
    title: "Cameron's World",
    link: "https://www.cameronsworld.net/",
  },
  // {
  //   title: "The Number Ones: Kanye West’s “Stronger” - Tom Breihan",
  //   link: "https://www.stereogum.com/2218062/the-number-ones-kanye-wests-stronger/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Aaliyah’s “Try Again” - Tom Breihan",
  //   link: "https://www.stereogum.com/2195882/the-number-ones-aaliyahs-try-again/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: TLC’s “Unpretty” - Tom Breihan",
  //   link: "https://www.stereogum.com/2193725/the-number-ones-tlcs-unpretty/columns/the-number-ones/",
  // },
  // {
  //   title:
  //     "The Number Ones: Britney Spears’ “Baby One More Time” - Tom Breihan",
  //   link: "https://www.stereogum.com/2191267/the-number-ones-britney-spears-baby-one-more-time/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Mariah Carey’s “Fantasy” - Tom Breihan",
  //   link: "https://www.stereogum.com/2181534/the-number-ones-mariah-careys-fantasy/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Madonna’s “Like A Prayer” - Tom Breihan",
  //   link: "https://www.stereogum.com/2153515/the-number-ones-madonnas-like-a-prayer/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: The Bangles' “Eternal Flame”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2152102/the-number-ones-the-bangles-eternal-flame/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Madonna’s “Live To Tell”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2110323/the-number-ones-madonnas-live-to-tell/columns/the-number-ones/",
  // },
  // {
  //   title:
  //     "The Number Ones: Bonnie Tyler’s “Total Eclipse Of The Heart”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2092526/the-number-ones-bonnie-tylers-total-eclipse-of-the-heart/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: TLC’s “No Scrubs”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2191924/the-number-ones-tlcs-no-scrubs/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Mariah Carey’s “Vision Of Love”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2161924/the-number-ones-mariah-careys-vision-of-love/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Olivia Newton-John’s “Physical”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2085123/the-number-ones-olivia-newton-johns-physical/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Lipps, Inc.’s “Funkytown”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2077732/the-number-ones-lipps-inc-s-funkytown/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Donna Summer’s “MacArthur Park”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2069326/the-number-ones-donna-summers-macarthur-park/columns/the-number-ones/",
  // },
  // {
  //   title: "The Number Ones: Fleetwood Mac’s “Dreams”  - Tom Breihan",
  //   link: "https://www.stereogum.com/2063379/the-number-ones-fleetwood-macs-dreams/columns/the-number-ones/",
  // },
  // {
  //   title:
  //     "The Number Ones: Sly & The Family Stone’s “Thank You (Falettinme Be Mice Elf Agin)” - Tom Breihan",
  //   link: "https://www.stereogum.com/2026526/sly-the-family-stones-thank-you-falettinme-be-mice-elf-agin-review/columns/the-number-ones/",
  // },
  // {
  //   title:
  //     "The Number Ones: Joan Jett And The Blackhearts’ “I Love Rock ‘N Roll” - Tom Breihan",
  //   link: "https://www.stereogum.com/2085860/the-number-ones-joan-jett-and-the-blackhearts-i-love-rock-n-roll/columns/the-number-ones/",
  // },
  // {
  //   title: "Glasgow ice cream wars",
  //   link: "https://en.wikipedia.org/wiki/Glasgow_ice_cream_wars",
  // },
  // {
  //   title: "Great Stink",
  //   link: "https://en.wikipedia.org/wiki/Great_Stink",
  // },
  {
    title: "Fallstreak hole",
    link: "https://en.wikipedia.org/wiki/Fallstreak_hole",
  },
  {
    title: "Human mail",
    link: "https://en.wikipedia.org/wiki/Human_mail",
  },
  {
    title: "Jenny Haniver",
    link: "https://en.wikipedia.org/wiki/Jenny_Haniver",
  },
  {
    title: "Kryptos",
    link: "https://en.wikipedia.org/wiki/Kryptos",
  },
  {
    title: "L'Inconnue de la Seine",
    link: "https://en.wikipedia.org/wiki/L%27Inconnue_de_la_Seine",
  },
  {
    title: "Lillian Alling",
    link: "https://en.wikipedia.org/wiki/Lillian_Alling",
  },
  {
    title: "The Most Mysterious Song on the Internet",
    link: "https://en.wikipedia.org/wiki/The_Most_Mysterious_Song_on_the_Internet",
  },
  {
    title: "The Shaggs",
    link: "https://en.wikipedia.org/wiki/The_Shaggs",
  },
  {
    title: "Folly",
    link: "https://en.wikipedia.org/wiki/Folly",
  },
  {
    title: "Folly",
    link: "https://en.wikipedia.org/wiki/Folly",
  },
  {
    title: "Spite house",
    link: "https://en.wikipedia.org/wiki/Spite_house",
  },
  {
    title: "Shell Grotto, Margate",
    link: "https://en.wikipedia.org/wiki/Shell_Grotto,_Margate",
  },
  {
    title: "Abode of Chaos",
    link: "https://en.wikipedia.org/wiki/Abode_of_Chaos",
  },
  {
    title: "Bucket Lake",
    link: "https://en.wikipedia.org/wiki/Bucket_Lake",
  },
  {
    title: "Cerne Abbas Giant",
    link: "https://en.wikipedia.org/wiki/Cerne_Abbas_Giant",
  },
  {
    title: "Crooked Forest",
    link: "https://en.wikipedia.org/wiki/Crooked_Forest",
  },
  {
    title: "Dumb Woman's Lane",
    link: "https://en.wikipedia.org/wiki/Dumb_Woman%27s_Lane",
  },
  {
    title: "Philadelphia's Magic Gardens",
    link: "https://en.wikipedia.org/wiki/Philadelphia%27s_Magic_Gardens",
  },
  {
    title: "Postman Cheval's palace and tomb",
    link: "http://www.wingsunfurled-web.com/en/travel/france/rhone-alps/hauterives-ideal-palace-postman-cheval.html#raw-art",
  },
  {
    title: "Cancún Underwater Museum",
    link: "https://en.wikipedia.org/wiki/Canc%C3%BAn_Underwater_Museum",
  },
  {
    title: "The Island of the Dolls",
    link: "https://en.wikipedia.org/wiki/The_Island_of_the_Dolls",
  },
  {
    title: "Lençóis Maranhenses National Park",
    link: "https://en.wikipedia.org/wiki/Len%C3%A7%C3%B3is_Maranhenses_National_Park",
  },
  {
    title: "El Ojo",
    link: "https://en.wikipedia.org/wiki/El_Ojo",
  },
  {
    title: "Capitol Hill mystery soda machine",
    link: "https://en.wikipedia.org/wiki/Capitol_Hill_mystery_soda_machine",
  },
  {
    title: "Crazy Horse Memorial",
    link: "https://en.wikipedia.org/wiki/Crazy_Horse_Memorial",
  },
  {
    title: "Gum Wall",
    link: "https://en.wikipedia.org/wiki/Gum_Wall",
  },
  {
    title: "Just Room Enough Island",
    link: "https://en.wikipedia.org/wiki/Just_Room_Enough_Island",
  },
  {
    title: "Cardrona Bra Fence",
    link: "https://en.wikipedia.org/wiki/Cardrona_Bra_Fence",
  },
  {
    title:
      "Taumatawhakatangi­hangakoauauotamatea­turipukakapikimaunga­horonukupokaiwhen­uakitanatahu",
    link: "https://en.wikipedia.org/wiki/Taumatawhakatangi%C2%ADhangakoauauotamatea%C2%ADturipukakapikimaunga%C2%ADhoronukupokaiwhen%C2%ADuakitanatahu",
  },
  {
    title: "Whanganui River",
    link: "https://en.wikipedia.org/wiki/Whanganui_River",
  },
  {
    title: "Great Moon Hoax",
    link: "https://en.wikipedia.org/wiki/Great_Moon_Hoax",
  },
  {
    title: "Mango cult",
    link: "https://en.wikipedia.org/wiki/Mango_cult",
  },
  {
    title: "0.999...",
    link: "https://en.wikipedia.org/wiki/0.999...",
  },
  {
    title: "Ulam spiral",
    link: "https://en.wikipedia.org/wiki/Ulam_spiral",
  },
  {
    title: "Bouba/kiki effect",
    link: "https://en.wikipedia.org/wiki/Bouba/kiki_effect",
  },
  {
    title: "Irreversible binomial",
    link: "https://en.wikipedia.org/wiki/Irreversible_binomial",
  },
  {
    title: "Tsundoku",
    link: "https://en.wikipedia.org/wiki/Tsundoku",
  },
  {
    title: "Colors of noise",
    link: "https://en.wikipedia.org/wiki/Colors_of_noise",
  },
  {
    title: "Impossible color",
    link: "https://en.wikipedia.org/wiki/Impossible_color",
  },
  {
    title: "Dancing mania",
    link: "https://en.wikipedia.org/wiki/Dancing_mania",
  },
  {
    title: "Odd-eyed cat",
    link: "https://en.wikipedia.org/wiki/Odd-eyed_cat",
  },
  {
    title: "Phosphorescence",
    link: "https://en.wikipedia.org/wiki/Phosphorescence",
  },
  {
    title: "Bioluminescence",
    link: "https://en.wikipedia.org/wiki/Bioluminescence",
  },
  {
    title: "Human",
    link: "https://en.wikipedia.org/wiki/Human",
  },
  {
    title: "Dream",
    link: "https://en.wikipedia.org/wiki/Dream",
  },
  {
    title: "Dreamachine",
    link: "https://en.wikipedia.org/wiki/Dreamachine",
  },
  {
    title: "Garden hermit",
    link: "https://en.wikipedia.org/wiki/Garden_hermit",
  },
  {
    title: "List of works based on dreams",
    link: "https://en.wikipedia.org/wiki/List_of_works_based_on_dreams",
  },
  {
    title: "Droste effect",
    link: "https://en.wikipedia.org/wiki/Droste_effect",
  },
  {
    title: "Jazz (design)",
    link: "https://en.wikipedia.org/wiki/Jazz_(design)",
  },
  {
    title: "Latrinalia",
    link: "https://en.wikipedia.org/wiki/Latrinalia",
  },
  {
    title: "Hyperart Thomasson",
    link: "https://en.wikipedia.org/wiki/Hyperart_Thomasson",
  },
  {
    title: "Earworm",
    link: "https://en.wikipedia.org/wiki/Earworm",
  },
  {
    title: "Musikalisches Würfelspiel",
    link: "https://en.wikipedia.org/wiki/Musikalisches_W%C3%BCrfelspiel",
  },
  {
    title: "Rockism and poptimism",
    link: "https://en.wikipedia.org/wiki/Rockism_and_poptimism",
  },
  {
    title: "Electroencephalophone",
    link: "https://en.wikipedia.org/wiki/Electroencephalophone",
  },
  {
    title: "Kit Kats in Japan",
    link: "https://en.wikipedia.org/wiki/Kit_Kats_in_Japan",
  },
  {
    title: "Square watermelon",
    link: "https://en.wikipedia.org/wiki/Square_watermelon",
  },
  {
    title: "Halo (optical_phenomenon)",
    link: "https://en.wikipedia.org/wiki/Halo_(optical_phenomenon)",
    songTags: [SONGS.halo],
  },
  {
    title: "Cottingley Fairies",
    link: "https://en.wikipedia.org/wiki/Cottingley_Fairies",
  },
  {
    title: "Faxlore",
    link: "https://en.wikipedia.org/wiki/Faxlore",
  },
  {
    title: "Dead mall",
    link: "https://en.wikipedia.org/wiki/Dead_mall",
  },
  {
    title: "Liminal space",
    link: "https://en.wikipedia.org/wiki/Liminal_space_(aesthetic)",
  },
].map((el) => ({
  ...el,
  contentType: "website",
}));
