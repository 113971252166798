/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./pages/home";
import { Music } from "./pages/music";
import { About } from "./pages/about";
import { Eternity } from "./pages/eternity/eternity";
import { NavContextProvider } from "./NavProvider";
import { Layout } from "./pages/Layout";
import { Analytics } from "@vercel/analytics/react";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/music",
        element: <Music />,
      },
      {
        path: "/ETERNITY",
        element: <Eternity />,
      },
      {
        path: "/about",
        element: <About />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StrictMode>
    <div
      css={css`
        width: 100%;
        overflow-x: hidden;
        height: 100vh;
      `}
    >
      <NavContextProvider>
        <RouterProvider router={router} />
      </NavContextProvider>
      <Analytics />
    </div>
  </StrictMode>
);
