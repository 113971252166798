/** @jsxImportSource @emotion/react */
import {
  AnswerComputerWindow,
  DraggableComputerWindow,
} from "../../components";
import { MusicPlayer } from "./components/MusicPlayer";
import { useGetSomethings, useScreenSize, useScreenWidth } from "../../hooks";
import { Something, SongTag } from "../../somethings";
import { getMediaQuery } from "../../utils";
import { css } from "@emotion/react";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { breakpoints, breakpointVals, SONGS } from "../../constants";
import Collapsible from "react-collapsible";
import { IoIosArrowDown } from "react-icons/io";
import { Description } from "./components/Description";
import { useNavigate } from "react-router-dom";
import { eternityCopy } from "../../copy/eternityCopy";
import * as R from "ramda";
import { Breakpoint } from "../../interfaces";
import "../../styles.css";

export const Eternity = () => {
  const [songPlaying, setSongPlaying] = useState<SongTag>(SONGS.halo);
  const [answer, setAnswer] = useState<string>();
  const [itemAtFront, setItemAtFront] = useState<Something>();
  const [noMoreSomethings, setNoMoreSomethings] = useState(false);

  const QUANTITY = 5;
  const { somethingsToDisplay, getNewSomething, setRefresh } = useGetSomethings(
    songPlaying,
    QUANTITY
  );

  useEffect(() => {
    if (noMoreSomethings) {
      if (somethingsToDisplay.some((el) => !R.isNil(el))) {
        setNoMoreSomethings(false);
      }
    }
  }, [somethingsToDisplay]);

  const navigate = useNavigate();

  const changeToRandomSong = () => {
    const getRandomSong = () => {
      let randomSong = songPlaying;
      while (randomSong === songPlaying) {
        const randomNumber = Math.floor(
          Math.random() * Object.values(SONGS).length
        );
        const randomKey = Object.keys(SONGS)[randomNumber];
        randomSong = SONGS[randomKey];
      }
      return randomSong;
    };
    const randomSong = getRandomSong();
    setSongPlaying(randomSong);
    setRefresh(true);
    setAnswer(undefined);
  };

  const refreshItems = () => {
    setRefresh(true);
    setAnswer(undefined);
  };

  useEffect(() => {
    if (answer) {
      if (answer === "Refresh items") {
        refreshItems();
      }
      if (answer === "Choose a different song") {
        changeToRandomSong();
      }
      if (answer === "Go home") {
        navigate("/");
      }
    }
  }, [answer]);

  const screenSize = useScreenSize();

  const coords: Record<Breakpoint, { x: number; y: number }[]> = Object.assign(
    {},
    ...Object.keys(breakpoints).map((breakpoint, i) => {
      const extraSpacing = i * 10;
      return {
        [breakpoint]: [
          { x: 0, y: 0 },
          { x: 0, y: 0 },
          { x: 80 + extraSpacing, y: 0 },
          { x: 0, y: 0 },
          { x: 40 + extraSpacing, y: 0 },
          { x: 100 + extraSpacing, y: 0 },
        ],
      };
    })
  );

  const [closeClickCounter, setCloseClickCounter] = useState(0);
  const setHideWindowToFalseCondition = useMemo(() => {
    return closeClickCounter > QUANTITY / 2;
  }, [closeClickCounter]);

  useEffect(() => {
    if (setHideWindowToFalseCondition) {
      setCloseClickCounter(0);
    }
  }, [setHideWindowToFalseCondition]);

  const width = useScreenWidth();

  return (
    <div
      css={css`
        display: flex;
        gap: 20px;
        height: 90vh;
        font-family: "Rainy Hearts";
        -webkit-font-smoothing: auto;
        font-smoothing: auto;
        flex-direction: column;
        ${getMediaQuery("tabletLg")} {
          flex-direction: row;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          color: black;
          ${getMediaQuery("tabletSm")} {
            flex-direction: row;
            justify-content: space-around;
          }
          ${getMediaQuery("tabletLg")} {
            flex-direction: column;
            width: 35%;
            justify-content: flex-start;
          }
          background-color: silver;
          .Collapsible {
            padding: 5px 10px 0px 5px;
          }
          .Collapsible__trigger.is-open {
            svg {
              transform: rotate(180deg);
            }
          }
        `}
      >
        <MusicPlayer
          songPlaying={songPlaying}
          setSongPlaying={setSongPlaying}
        />
        {width < breakpointVals.tabletSm ? (
          <Collapsible trigger={(<IoIosArrowDown />) as ReactElement}>
            <Description />
          </Collapsible>
        ) : (
          <Description />
        )}
      </div>
      <div
        className="windows-section"
        css={css`
          position: relative;
          width: 60%;
          height: 70%;
        `}
      >
        {!noMoreSomethings ? (
          somethingsToDisplay.map((somethingToDisplay, i, arr) => {
            if (arr.every((el) => R.isNil(el))) {
              setNoMoreSomethings(true);
            }
            if (R.isNil(somethingToDisplay)) {
              return (
                <div
                  css={css`
                    display: none;
                  `}
                />
              );
            }
            return (
              <DraggableComputerWindow
                something={somethingToDisplay}
                onCloseClick={() => {
                  getNewSomething(somethingToDisplay);
                  setCloseClickCounter(closeClickCounter + 1);
                }}
                key={`draggable-computer-window-${i}`}
                bounds=".windows-section"
                moveToFront={itemAtFront === somethingToDisplay}
                onDrag={() => setItemAtFront(somethingToDisplay)}
                defaultPosition={coords[screenSize][i]}
              />
            );
          })
        ) : (
          <AnswerComputerWindow
            {...eternityCopy.answerComputerWindow}
            setAnswer={setAnswer}
            defaultPosition={coords[screenSize][0]}
          />
        )}
      </div>
    </div>
  );
};
