/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ComputerWindow } from "./ComputerWindow";
import Draggable from "react-draggable";
import { AnswerComputerWindowProps } from "./interfaces";
import { useScreenSize } from "../hooks";
import { NoDragSection } from "./NoDragSection";

export const AnswerComputerWindow = ({
  question,
  answers,
  setAnswer,
  defaultPosition,
}: AnswerComputerWindowProps) => {
  const screenSize = useScreenSize();

  const centredCoords = {
    mobileSm: { x: 50, y: 100 },
    mobileLg: { x: 100, y: 100 },
    tabletSm: { x: 180, y: 100 },
    tabletMd: { x: 250, y: 100 },
    tabletLg: { x: 350, y: 100 },
    desktopSm: { x: 400, y: 100 },
    desktopMd: { x: 500, y: 100 },
    desktopLg: { x: 600, y: 100 },
  };

  return (
    <Draggable
      positionOffset={
        defaultPosition ? defaultPosition : centredCoords[screenSize]
      }
      cancel=".no-drag"
    >
      <div>
        <ComputerWindow>
          <ComputerWindow.TopBar />
          <div
            css={css`
              color: black;
              display: flex;
              flex-direction: column;
              gap: 10px;
              font-family: "Rainy Hearts";
              -webkit-font-smoothing: auto;
              font-smoothing: auto;
              padding-top: 5px;
              padding-bottom: 10px;
              button {
                color: black;
              }
            `}
          >
            {question}
            <NoDragSection>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  gap: 10px;
                `}
              >
                {answers.map((buttonText, i) => (
                  <button
                    onClick={() => setAnswer(buttonText)}
                    key={`answer-button-${i}`}
                  >
                    {buttonText}
                  </button>
                ))}
              </div>
            </NoDragSection>
          </div>
        </ComputerWindow>
      </div>
    </Draggable>
  );
};
