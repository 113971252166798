/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getMediaQuery } from "../../../utils";

export const KyrieImages = () => (
  <div
    css={css`
      display: flex;
      padding: 10px;
      gap: 10px;
      align-items: center;
      img {
        height: auto;
        width: 50%;
        ${getMediaQuery("tabletSm")} {
          width: 70%;
        }
        ${getMediaQuery("tabletLg")} {
          width: 50%;
        }
      }
      width: calc(100% - 30px);
      ${getMediaQuery("tabletSm")} {
        flex-direction: column;
      }
      ${getMediaQuery("tabletLg")} {
        flex-direction: row;
      }
      ${getMediaQuery("desktopMd")} {
        align-self: center;
      }
    `}
  >
    <img src="/images/kyrie2.png" />
    <img src="/images/kyrie3.png" />
  </div>
);
