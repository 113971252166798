/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { IoMdPlay } from "react-icons/io";
import { eternityLinks, SONGS } from "../../../constants";
import { getMediaQuery } from "../../../utils";
import { MusicPlayerProps } from "../../../components";
import { useMemo } from "react";
import { NowPlayingBar } from "./NowPlayingBar";

export const MusicPlayer = ({
  songPlaying,
  setSongPlaying,
}: MusicPlayerProps) => {
  const embedLink = useMemo(
    () =>
      Object.values(eternityLinks.tracks).find(
        (el) => el.songTag === songPlaying
      ) ?? Object.values(eternityLinks.tracks)[0],
    [songPlaying]
  );

  return (
    <div css={mainContainer}>
      <NowPlayingBar songPlaying={songPlaying} />
      <div
        css={css`
          background-color: white;
          color: black;
        `}
      >
        <div css={songsContainer}>
          <iframe
            style={{ border: 0, width: "140px", height: "140px" }}
            src={embedLink.src}
            seamless
          >
            <a href={embedLink.link}>{embedLink.displayText}</a>
          </iframe>
          <div>
            {Object.values(SONGS).map((song, i) => (
              <div
                css={songCss}
                onClick={() => setSongPlaying(song)}
                key={`song-playing-${i}`}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    overflow: hidden;
                  `}
                >
                  {songPlaying === song && (
                    <div
                      css={css`
                        font-size: 10px;
                      `}
                    >
                      <IoMdPlay />
                    </div>
                  )}
                  {song}{" "}
                  <span
                    css={css`
                      font-family: sans-serif;
                    `}
                  >
                    · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
                    · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
                    · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
                    · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mainContainer = css`
  font-size: 16px;
  width: 100%;
  ${getMediaQuery("tabletSm")} {
    width: 50%;
  }
  ${getMediaQuery("tabletLg")} {
    width: 100%;
  }
`;

const songsContainer = css`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-overflow: clip;
  height: 150px;
  overflow: hidden;
  ${getMediaQuery("tabletSm")} {
    height: auto;
  }
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  gap: 10px;
`;

const songCss = css`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px;
  :hover {
    background: rgba(236, 147, 232, 1);
    color: white;
  }
`;
