export const indecisionQuotes = [
  {
    quote: "I used to be indecisive but now I am not quite sure.",
    author: "Tommy Cooper",
  },
  {
    quote:
      "The man of decision cannot be stopped! The man of indecision cannot be started! Take your own choice.",
    author: "Napoleon Hill",
  },
  {
    quote:
      "More is lost by indecision than wrong decision. Indecision is the thief of opportunity. It will steal you blind.",
    author: "Marcus Tullius Cicero",
  },
  {
    quote:
      "I saw myself sitting in the crotch of this fig tree, starving to death, just because I couldn't make up my mind which of the figs I would choose. I wanted each and every one of them, but choosing one meant losing all the rest, and, as I sat there, unable to decide, the figs began to wrinkle and go black, and, one by one, they plopped to the ground at my feet.",
    author: "Sylvia Plath in The Bell Jar (1963)",
  },
  {
    quote: "Nothing is so exhausting as indecision, and nothing is so futile.",
    author: "Bertrand Russell",
  },
  {
    quote:
      "I don’t do anything with my life except romanticise and decay with indecision",
    author: "Allen Ginsberg",
  },
  // {
  //   quote:
  //     "Doubt is an uncomfortable condition, but certainty is a ridiculous one.",
  //   author: "Voltaire",
  // },
  {
    quote:
      "Once you make a decision, the universe conspires to make it happen.",
    author: "Ralph Waldo Emerson",
  },
  {
    quote:
      '"Would you tell me, please, which way I ought to go from here?"<br/> <br/>"That depends a good deal on where you want to get to," said the Cat. <br/> <br/> "I don\'t much care where -" said Alice. <br/> <br/> "Then it doesn\'t matter which way you go," said the Cat. <br/> <br/> "- so long as I get SOMEWHERE," Alice added as an explanation. <br/> <br/> "Oh, you\'re sure to do that," said the Cat, "if you only walk long enough."',
    author: "Lewis Carroll in Alice's Adventures in Wonderland (1865)",
  },
  {
    quote:
      "I must have a prodigious amount of mind; it takes me as much as a week, sometimes, to make it up!",
    author: "Mark Twain",
  },
  {
    quote:
      "IN THE END… We only regret the chances we didn’t take, the relationships we were afraid to have,and the decisions we waited too long to make.",
    author: "Lewis Carroll",
  },
  {
    quote:
      "Decisions are never really made – at best they manage to emerge, from a chaos of peeves, whims, hallucinations...",
    author: "Thomas Pynchon in Gravity's Rainbow (1973)",
  },
  {
    quote:
      "You have brains in your head. You have feet in your shoes. You can steer yourself any direction you choose. You're on your own. And you know what you know. And YOU are the one who'll decide where to go...",
    author: "Dr. Seuss in Oh, the Places You'll Go! (1990)",
  },
  {
    quote:
      "All we have to decide is what to do with the time that is given us.",
    author: " J.R.R. Tolkien in The Fellowship of the Ring (1954)",
  },
];
