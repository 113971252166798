import { useEffect, useState } from "react";

export const useHideWindow = (setHideWindowToFalseCondition?: boolean) => {
  const [hideWindow, setHideWindow] = useState<boolean>(false);

  useEffect(() => {
    if (setHideWindowToFalseCondition !== undefined) {
      if (setHideWindowToFalseCondition === true) {
        setHideWindow(false);
      }
    } else {
      setTimeout(() => {
        setHideWindow(false);
      }, 500);
    }
  }, [setHideWindowToFalseCondition, hideWindow]);

  return { hideWindow, setHideWindow };
};
