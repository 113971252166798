/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Loading } from "../pages/music/components/Loading";
import { useBorderType, useHideWindow } from "../hooks";
import { getTitle } from "../utils";
import { ComputerWindow, DraggableComputerWindowProps } from "./";

export const DraggableComputerWindow = ({
  something,
  onCloseClick,
  defaultPosition,
  onDrag,
  moveToFront,
  bounds,
  setHideWindowToFalseCondition,
}: DraggableComputerWindowProps) => {
  const [isContentLoading, setIsContentLoading] = useState(
    something.contentType === "music" ||
      something.contentType === "video" ||
      something.contentType === "image"
      ? true
      : false
  );
  const { hideWindow, setHideWindow } = useHideWindow(
    setHideWindowToFalseCondition
  );
  const { borderType, setBorderType } = useBorderType();

  return (
    <Draggable
      cancel=".no-drag"
      positionOffset={defaultPosition}
      onStart={onDrag}
      bounds={bounds}
    >
      <div
        css={css`
          display: ${hideWindow ? "none" : "block"};
          width: max-content;
          height: max-content;
          position: absolute;
          z-index: ${moveToFront ? 1 : 0};
        `}
      >
        <ComputerWindow
          contentType={something.contentType}
          titleLength={something.title.length}
        >
          <ComputerWindow.TopBar title={getTitle(something)}>
            <ComputerWindow.TopBar.CloseButton
              borderType={borderType}
              onClick={() => {
                setBorderType("inset");
                onCloseClick();
                setHideWindow(true);
                setIsContentLoading(false);
              }}
            />
          </ComputerWindow.TopBar>
          {/* <div
            css={css`
              ${isContentLoading &&
              css`
                display: none;
              `};
            `}
          > */}
          <ComputerWindow.Content
            something={something}
            onContentLoad={() => setIsContentLoading(false)}
          />
          {/* </div> */}
          {/* {isContentLoading && <Loading />} */}
        </ComputerWindow>
      </div>
    </Draggable>
  );
};
