import { css } from "@emotion/react";
import {
  IFRAME_SIZES,
  IMAGE_SIZES,
  WEBSITE_IFRAME_SIZES,
  WRITING_SIZES,
} from "../constants";
import { ContentType } from "../somethings";

const DEFAULT_WIDTH = 200;
const DEFAULT_HEIGHT = 400;

export const getWidthAndHeight = (
  screen: "desktop" | "tablet" | "mobile",
  contentType?: ContentType
) => {
  switch (contentType) {
    case "video":
    case "music":
      return IFRAME_SIZES[screen];
    case "website":
      return WEBSITE_IFRAME_SIZES[screen];
    case "image":
      return IMAGE_SIZES[screen];
    case "writing":
      return WRITING_SIZES[screen];
    default:
      return css`
        width: ${DEFAULT_WIDTH};
        height: ${DEFAULT_HEIGHT};
      `;
  }
};
