import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

export const NavContext = createContext<{
  menuIsOpen: boolean;
  setMenuIsOpen: (menuIsOpen: boolean) => void;
}>({
  menuIsOpen: false,
  setMenuIsOpen: (menuIsOpen: boolean) => console.log("setMenuIsOpen"),
});

export const NavContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <NavContext.Provider
      value={{
        menuIsOpen,
        setMenuIsOpen,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => useContext(NavContext);
