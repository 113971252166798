export const musicCopy = {
  mainInfo:
    "I've been releasing music as Kyrie Halo since 2020. All the songs on this page were written, recorded and produced by me. <br/> The music videos are made up of clips from various movies, tv shows and other footage I found online. You can find the sources in the description of each video and if you'd like to know the origin of a specific clip, just drop a comment including the timestamp and I'll let you know.",
  trackIcon: {
    src: "/images/display/windows_cd.png",
    alt: "CD icon",
  },
  folderIcon: {
    src: "/images/display/windows_folder.png",
    alt: "Folder icon",
    text: "Eternity",
  },
  fileIcon: {
    src: "/images/display/windows_file_icon.png",
    alt: "File icon",
    text: "Info",
  },
};
