import * as R from "ramda";

export const arrayIncludesObj = <Type>(arr: Type[], obj: Type) => {
  for (let i = 0; i < arr.length; i++) {
    if (R.equals(arr[i], obj)) {
      return true;
    }
  }
  return false;
};
