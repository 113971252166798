import { Music } from "./interfaces";
import { SONGS } from "../constants";

export const music: Music[] = [
  {
    title: "San Vicente",
    artist: "Milton Nascimento",
    link: "https://www.youtube.com/watch?v=Ie89fdGuazg",
  },
  {
    title: "Condor Avenue",
    artist: "Elliott Smith",
    link: "https://youtu.be/DCZbnvzRUjQ?si=6F82p-CEWBtunDla",
  },
  {
    title: "Soy Rebelde",
    artist: "Jeanette",
    link: "https://www.youtube.com/watch?v=JuLRF7mch_I",
  },
  {
    title: "Give Him a Great Big Kiss",
    artist: "The Shangri-Las",
    link: "https://www.youtube.com/watch?v=01YePzk29Mc",
  },
  {
    title: "I Hate It Too",
    artist: "Hum",
    link: "https://www.youtube.com/watch?v=5NFVLZ3QjgY",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Naomi Elizabeth - God Sent Me Here To Rock You",
    artist: "Naomi Elizabeth",
    link: "https://www.youtube.com/watch?v=9wW5QsRFacE",

    songTags: [SONGS.halo],
  },
  {
    title: "Mermaid Motel",
    artist: "Lana Del Rey",
    link: "https://www.youtube.com/watch?v=YXcM2XZk9Ac",
  },
  {
    title: "Filthy Pop",
    artist: "Lady Gaga",
    link: "https://www.youtube.com/watch?v=rYTG46J9GMw",
  },
  {
    title: "Popular",
    artist: "Nada Surf",
    link: "https://www.youtube.com/watch?v=hAFuD-S-e_E",
  },
  {
    title: "Caught Out There",
    artist: "Kelis",
    link: "https://www.youtube.com/watch?v=N3JFwd1bk4Q",
  },
  {
    title: "Rock Lobster",
    artist: "The B-52's",
    link: "https://www.youtube.com/watch?v=n4QSYx4wVQg",
  },
  {
    title: "Our Lips Are Sealed",
    artist: "The Go-Go's",
    link: "https://www.youtube.com/watch?v=r3kQlzOi27M",
  },
  {
    title: "Pienso en tu mirá",
    artist: "Rosalía",
    link: "https://www.youtube.com/watch?v=p_4coiRG_BI",
  },
  {
    title: "Ballade 4 part 1",
    artist: "Glover Gill and the Tosca Tango Orchestra",
    link: "https://www.youtube.com/watch?v=oYhzYc2WnGc",
  },
  {
    title: "All For You",
    artist: "Janet Jackson",
    link: "https://www.youtube.com/watch?v=J551f-TyqjY",
  },
  {
    title: "Fantasy (O.D.B. Remix)",
    artist: "Mariah Carey",
    link: "https://youtu.be/-tCTm5M3Cp8?si=uX-VnpNjVCmor67S",
  },
  {
    title: "I Luv U",
    artist: "Dizzee Rascal",
    link: "https://youtu.be/YH0KWX2a8zY?si=z9bBhj9AFFAFJrDc",
  },
  {
    title: "Genius of Love",
    artist: "Tom Tom Club",
    link: "https://www.youtube.com/watch?v=aCWCF19nUhA",
  },
  {
    title: "First Love",
    artist: "Uffie",
    link: "https://www.youtube.com/watch?v=5PYzwdbbgF8",
    songTags: [SONGS.halo],
  },
  {
    title: "March of the Pigs",
    artist: "Nine Inch Nails",
    link: "https://youtu.be/pwjLcwbT-xE?si=w3Xj_-1-0uxID77z",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Seven",
    artist: "Sunny Day Real Estate",
    link: "https://youtu.be/gAotWVmVRS4?si=HYp7lNnfEBSg7Vxz",
  },
  {
    title: "Ready to Uff",
    artist: "Uffie",
    link: "https://youtu.be/kXlccYcXHAs?si=qdbk2uggAI00vfXj",
  },
  {
    title: "Violet",
    artist: "Hole",
    link: "https://youtu.be/cH_rfGBwamc?si=oE3aE73blr7LK4E5",
  },
  {
    title: "Regular John",
    artist: "Queens of the Stone Age",
    link: "https://youtu.be/wjNbxJttwP8?si=K7wpLkmQ-5XJh1fB",
  },
  {
    title: "Anima",
    artist: "Milton Nascimento",
    link: "https://youtu.be/Jo0J_fZcyno?si=j0OwKy0mvzcteyzQ",
  },
  {
    title: "Washer",
    artist: "Slint",
    link: "https://youtu.be/6yEgcb167k4?si=ofRa7gbUsJsve_7t",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Dramamine",
    artist: "Modest Mouse",
    link: "https://youtu.be/tvqlt7OWTOk?si=S5OSEhWE15P5nZ-l",
  },
  {
    title: "Umut",
    artist: "Lale Belkıs",
    link: "https://www.youtube.com/watch?v=QgfL7AoAwWQ",
  },
  {
    title: "Kookies",
    artist: "MF DOOM",
    link: "https://youtu.be/8iYSwvdEfeY?si=fYNXBaBm1TggUkem",
  },
  {
    title: "Hope",
    artist: "Descendents",
    link: "https://www.youtube.com/watch?v=wHd-PLgKlbs",
  },
  {
    title: "National Anthem (demo)",
    artist: "Lana del Rey",
    link: "https://www.youtube.com/watch?v=R3mHV7TCUM0",
    songTags: [SONGS.halo],
  },
  {
    title: "Lucky Star",
    artist: "Madonna",
    link: "https://youtu.be/ThHz9wlBeLU?si=CwlYjwWg3VCt8zUl",
    songTags: [SONGS.halo],
  },
  {
    title: "Potholderz",
    artist: "MF DOOM",
    link: "https://youtu.be/oYLHXItjzG4?si=94caxbqFMsMf508Q",
  },
  {
    title: "Trailer Trash",
    artist: "Modest Mouse",
    link: "https://youtu.be/CjwhIzByM3E?si=JPReOuAY_s9TyTna",
  },
  {
    title: "Softer, Softest",
    artist: "Hole",
    link: "https://youtu.be/nIbu7QtuARk?si=Qle6TuRBzMfmxRIN",
  },
  {
    title: "Bang Bang ('My Baby Shot Me Down')",
    artist: "Nancy Sinatra",
    link: "https://youtu.be/OEFa4ztm9P0?si=Gu43_6HFmRqFdfEw",
    songTags: [SONGS.myAngel],
  },
  {
    title: "No Name #3",
    artist: "Elliott Smith",
    link: "https://youtu.be/lL6f419zXj4?si=O0r7iZ33uxvCOpBo",
  },
  {
    title: "Man-Size",
    artist: "PJ Harvey",
    link: "https://youtu.be/WuJE40OBt48?si=6VvjXoIwrIHSaKGr",
  },
  {
    title: "Bolero",
    artist: "Isao Tomita",
    link: "https://www.youtube.com/watch?v=Xnt_8bUXVpw",
  },
  {
    title: "Just like a baby",
    artist: "Sly & the Family Stone",
    link: "https://youtu.be/-YzitR0IKW4?si=bicm2dNRDG9RIQIn",
  },

  {
    title: "Genesis",
    artist: "Grimes",
    link: "https://www.youtube.com/watch?v=WizNXQGBMEk",
    songTags: [SONGS.halo],
  },
  {
    title: "Suspended in Gaffa",
    artist: "Kate Bush",
    link: "https://www.youtube.com/watch?v=V3XAeg3B0To",
  },
  {
    title: "Cloudbusting",
    artist: "Kate Bush",
    link: "https://youtu.be/pllRW9wETzw?si=q1GsufMwEbvmEJKZ",
  },
  {
    title: "Sat In Your Lap",
    artist: "Kate Bush",
    link: "https://www.youtube.com/watch?v=-4csr6pLZLg",
  },
  {
    title: "Silver Springs",
    artist: "Fleetwood Mac",
    link: "https://youtu.be/eDwi-8n054s?si=kAJUDWgOkDu52oq7",
  },
  {
    title: "Happiness is a Butterfly",
    artist: "Lana del Rey",
    link: "https://youtu.be/nbcXvlEa7Wk?si=bmOHaoIVuI_yo2RD",
  },
  {
    title: "Live To Tell",
    artist: "Madonna",
    link: "https://youtu.be/IzAO9A9GjgI?si=XYU18AuP2iZy1uBP",
  },
  {
    title: "Girls Just Want To Have Fun",
    artist: "Cyndi Lauper",
    link: "https://youtu.be/PIb6AZdTr-A?si=Nvk5SpFDjYR7a2cx",
  },
  {
    title: "Total Eclipse of the Heart",
    artist: "Bonnie Tyler",
    link: "https://youtu.be/lcOxhH8N3Bo?si=bkXW7rQiC_SRdJi6",
  },
  {
    title: "Like A Prayer",
    artist: "Madonna",
    link: "https://youtu.be/79fzeNUqQbQ?si=ENz5EzG-hH-Rxww9",
    songTags: [SONGS.halo],
  },
  {
    title: "Crazy For You",
    artist: "Madonna",
    link: "https://youtu.be/79fzeNUqQbQ?si=ENz5EzG-hH-Rxww9",
  },
  {
    title: "Paranoid",
    artist: "Kanye West",
    link: "https://youtu.be/9HtQD82Irdo?si=0RjZgw_ivoO7cFs5",
  },
  {
    title: "L.A.",
    artist: "Elliott Smith",
    link: "https://youtu.be/WxLn44KhFrc?si=fG-lvRMQd-05XOsu",
  },
  {
    title: "Nancy Boy",
    artist: "Placebo",
    link: "https://www.youtube.com/watch?v=PBxuq_eWW94",
  },
  {
    title: "Wow",
    artist: "Kate Bush",
    link: "https://youtu.be/0ar7vovnH5I?si=FjPbtmdTKbgrOjXG",
  },
  {
    title: "Seek Bromance",
    artist: "Tim Berg",
    link: "https://youtu.be/eWUC5Q0RCAA?si=rAHMjvK6gBINi_DV",
  },
  {
    title: "Everything is Embarrassing",
    artist: "Sky Ferreira",
    link: "https://youtu.be/rEamE0MYPkg?si=Jd2d7XjFNxuA-I63",
  },
  {
    title: "I Feel Love",
    artist: "Donna Summer",
    link: "https://youtu.be/9ZqqvrWCs3Q?si=n-9us3LLaET-mmLO",
  },
  {
    title: "Are You That Somebody?",
    artist: "Aaliyah",
    link: "https://youtu.be/uTMuqL0qx08?si=JOR7R4GiI3L6VONM",
  },
  {
    title: "More Than a Woman",
    artist: "Aaliyah",
    link: "https://youtu.be/xgYUj_pAD-w?si=-DJ2-aiWTMT9Hmk6",
  },
  {
    title: "Don't Know What To Tell Ya",
    artist: "Aaliyah",
    link: "https://youtu.be/HfFTlEPjJQI?si=HYuKa4fbXPvrUE8a",
  },
  {
    title: "At Your Best (You Are Love)",
    artist: "Aaliyah",
    link: "https://youtu.be/xwdubODPSjA?si=60AE1KGLHfm20-w3",
  },
  {
    title: "Honey",
    artist: "Mariah Carey",
    link: "https://youtu.be/w3KOowB4k_k?si=F0H_AuryzhtaT0hm",
  },
  {
    title: "Venus as Boy",
    artist: "Björk",
    link: "https://www.youtube.com/watch?v=7Z5aPaDwAkU",
  },
  {
    title: "Fast As You Can",
    artist: "Fiona Apple",
    link: "https://youtu.be/NbxqtbqyoRk?si=qbBI7imuld9VWWy7",
  },
  {
    title: "Las habladurias del mundo",
    artist: "Pescado Rabioso",
    link: "https://youtu.be/k5CNTHzu7eQ?si=ePU48ZZpEXDXadMZ",
  },
  {
    title: "Street Carp",
    artist: "Deftones",
    link: "https://youtu.be/yt86EOxIgwE?si=TRNzaeSYd0EOglWX",
  },
  {
    title: "Sexy Sadie",
    artist: "The Beatles",
    link: "https://youtu.be/tSk5U4oHhu0?si=iHo51zi06HulGabW",
  },
  {
    title: "Blunt After Blunt",
    artist: "Danny Brown",
    link: "https://youtu.be/LgCU3rC6tqU?si=s3IdAPDb_5I034zn",
  },
  {
    title: "I'm Only Sleeping",
    artist: "The Beatles",
    link: "https://youtu.be/BT5j9OQ7Sh0?si=tyHnL6zzE1fsQWE8",
  },
  {
    title: "Qué será, será",
    artist: "Sly and the Family Stone",
    link: "https://youtu.be/xGkWuZxuP-4?si=DoABBLZRUrdFFNAX",
  },
  {
    title: "Hard To Explain",
    artist: "The Strokes",
    link: "https://youtu.be/ay3AFRcq5b4?si=duvCKF8b1bqrGfKK",
  },
  {
    title: "Rub 'Til It Bleeds",
    artist: "PJ Harvey",
    link: "https://youtu.be/a5x8p22UU_4?si=jKcXkuTG9BeNswVU",
  },
  {
    title: "Rhinestone Cowboy",
    artist: "MF DOOM",
    link: "https://youtu.be/WoxHqRPvZJE?si=j1_duCpQ4R_bpIro",
  },
  {
    title: "Break My Body",
    artist: "Pixies",
    link: "https://youtu.be/Kc4NKz9Ofmk?si=wEWkj7g7-iREfMIh",
  },
  {
    title: "Dress You Up",
    artist: "Madonna",
    link: "https://youtu.be/SOhJHS7Rvrg?si=D2ao7sXrQFb67JrE",
  },
  {
    title: "Sweet Virginia",
    artist: "The Rolling Stones",
    link: "https://youtu.be/5R3xFy85fVE?si=Im82IzNjoBzz6kVH",
  },
  {
    title: "Bottle Up And Explode!",
    artist: "Elliott Smith",
    link: "https://youtu.be/F2Be7vCN0D4?si=-VusqozBKSgKE6Ww",
  },
  {
    title: "West Coast",
    artist: "Lana del Rey",
    link: "https://youtu.be/oKxuiw3iMBE?si=TbiYpfOTYRjI4B1n",
  },
  {
    title: "Breadcrumb Trail",
    artist: "Slint",
    link: "https://youtu.be/gBfoQjJ7guk?si=TtaWR3Pr0kJ3pRQ2",
  },
  {
    title: "Rockaway Beach",
    artist: "Ramones",
    link: "https://youtu.be/8Z1-R2LEuao?si=e40_bjq-cclVwXYj",
  },
  {
    title: "sometimes",
    artist: "My Bloody Valentine",
    link: "https://youtu.be/hSI_9P9rRt4?si=LaZ2OhZ9g4T5l2Ia",
  },
  {
    title: "Today",
    artist: "Jefferson Airplane",
    link: "https://youtu.be/5SXy7G5dtb4?si=_K7FS-zM40AjWkRP",
  },
  {
    title: "Somebody To Love",
    artist: "Jefferson Airplane",
    link: "https://youtu.be/SrGSt5eDt9o?si=uI-YLbIq-7Sdj5EB",
  },
  {
    title: "Lanquidity",
    artist: "Sun Ra",
    link: "https://youtu.be/7O4c6D8Z9a8?si=bBbBQeoJW_-GnPwB",
  },
  {
    title: "You Can't Quit Me Baby",
    artist: "Queens of the Stone Age",
    link: "https://youtu.be/C1xCgeSwdNA?si=vGYFe82BTd_zSA6o",
  },
  {
    title: "Hounds of Love",
    artist: "Kate Bush",
    link: "https://youtu.be/cnJO_vPxutk?si=L17hzt51_uMiuAcg",
  },
  {
    title: "Can't Tell Me Nothing",
    artist: "Kanye West",
    link: "https://youtu.be/Vcljvd4Ef_o?si=S6nuq4n-s79RJ9fm",
  },
  {
    title: "Don't Want To Know If You Are Lonely",
    artist: "Hüsker Dü",
    link: "https://youtu.be/DxKcEurO5jA?si=nKG1mvOWF8vOyJYT",
  },
  {
    title: "Time of the Season",
    artist: "The Zombies",
    link: "https://youtu.be/6FndvMeXUgU?si=kIn5qO8xvPyRcYn8",
  },
  {
    title: "This Will Be Our Year",
    artist: "The Zombies",
    link: "https://youtu.be/kI2lTwY0Jx8?si=U_NF4q-7Niqz7xhz",
  },
  {
    title: "New Grass",
    artist: "Talk Talk",
    link: "https://youtu.be/VkcBQCFp4NE?si=_VUc1bS2vu-W9QMg",
  },
  {
    title: "Pink Turns To Blue",
    artist: "Hüsker Dü",
    link: "https://youtu.be/JW2yZamiBlg?si=5pLdwWwoWQV41IUZ",
  },
  {
    title: "Dai The Flu",
    artist: "Deftones",
    link: "https://youtu.be/aopye0lJUtI?si=zWMeOP-lNONN-qb3",
  },
  {
    title: "MX",
    artist: "Deftones",
    link: "https://youtu.be/k9kgN2938lI?si=BNpPbsj9W7PVcVLK",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Lorelei",
    artist: "Cocteau Twins",
    link: "https://youtu.be/o7tcFKakZTA?si=SJmxGGIg6KOmulvF",
    songTags: [SONGS.halo],
  },
  {
    title: "Amelia",
    artist: "Cocteau Twins",
    link: "https://youtu.be/xL-X46Dl51c?si=dNClqlosvpLzmcGe",
  },
  {
    title: "Pandora (For Cindy)",
    artist: "Cocteau Twins",
    link: "https://youtu.be/LCaNDUcqHlQ?si=BinlrK8h-4u8-oq4",
  },
  {
    title: "Beatrix",
    artist: "Cocteau Twins",
    link: "https://youtu.be/eAbtFwqTkXw?si=M3Qj5TRyDnJh-3Q_",
  },
  {
    title: "Teeth Like God's Shoeshine",
    artist: "Modest Mouse",
    link: "https://youtu.be/VuCXo7Vtrks?si=sP4BOA8ODD_um2ym",
  },
  {
    title: "Cowboy Dan",
    artist: "Modest Mouse",
    link: "https://youtu.be/1e4Hcm2kRjA?si=rQQSnG1wY2q_zYk1",
  },
  {
    title: "Song About An Angel",
    artist: "Sunny Day Real Estate",
    link: "https://youtu.be/ETtXtl-VXcY?si=buMpJl2JEeJXJo8j",
  },
  {
    title: "Sometimes",
    artist: "Sunny Day Real Estate",
    link: "https://youtu.be/JpisbakY1Js?si=bnl9DYD8AXt8xada",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Riot Rhythm",
    artist: "Sleigh Bells",
    link: "https://youtu.be/0OwYkI7m1DM?si=cV2phLxaNNPwkOKd",
  },
  {
    title: "Wandering Star",
    artist: "Portishead",
    link: "https://youtu.be/NJ3CA_RThDY?si=72j1RVyOkPs9UXO8",
  },
  {
    title: "Roads",
    artist: "Portishead",
    link: "https://youtu.be/bTxIfAvg8VM?si=qgOMyleSynD0cIHM",
  },
  {
    title: "St Ide's Heaven",
    artist: "Elliott Smith",
    link: "https://youtu.be/MJIXDOVRtVk?si=hq65naemgin_ADO7",
  },
  {
    title: "only tomorrow",
    artist: "My Bloody Valentine",
    link: "https://youtu.be/y2FQ3ih0MoE?si=N12M_zMNKcwVBscW",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "in another way",
    artist: "My Bloody Valentine",
    link: "https://youtu.be/6oXJus1ajIU?si=EEtf29DUcy1ZAmQO",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Chocolate Matter",
    artist: "Sweet Trip",
    link: "https://youtu.be/o-liUFpa9ZQ?si=jmLDZTRy-FiIBbXt",
  },
  {
    title: "Drain You",
    artist: "Nirvana",
    link: "https://youtu.be/AJUpHxlJUNQ?si=IJ-YwE2l08uB68zk",
  },
  {
    title: "I Want You (She's So Heavy)",
    artist: "The Beatles",
    link: "https://youtu.be/tAe2Q_LhY8g?si=uJ3OerJ67ubSgt2C",
  },
  {
    title: "Nu Punk",
    artist: "Ovlov",
    link: "https://youtu.be/glV6-uXxsjo?si=G3j_cr0pqgt9x1HX",
  },
  {
    title: "Where's My Dini?",
    artist: "Ovlov",
    link: "https://youtu.be/7Y5aR10R3Xo?si=Na3s2BYdkONA4mNy",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Never Had No One Ever",
    artist: "The Smiths",
    link: "https://youtu.be/qBIHz-07veA?si=wnUFr-kvq36ArcBs",
  },
  {
    title: "Boys",
    artist: "Sky Ferreira",
    link: "https://youtu.be/FFZCyoHfI7Q?si=DU1sdZfHzJWV5UHd",
  },
  {
    title: "Silver Rocket",
    artist: "Sonic Youth",
    link: "https://youtu.be/wWTkb1xVin0?si=Dg4QH9fe6mEBD5AZ",
  },
  {
    title: "Maybe After He's Gone",
    artist: "The Zombies",
    link: "https://youtu.be/0N-FRiu84P8?si=LDKR2HRGMI0SUnjn",
  },
  {
    title: "Money Trees",
    artist: "Kendrick Lamar",
    link: "https://youtu.be/HJNa9Ih3haA?si=72Lf5O6y_0HAljr7",
  },
  {
    title: "One Step Closer",
    artist: "Linkin Park",
    link: "https://youtu.be/KwN_f0fTHoE?si=-T6NNj4HaiTzUaVP",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Do You Like Me",
    artist: "Fugazi",
    link: "https://youtu.be/1J94qkE85uo?si=8rdReALUqfCRjW5P",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Bed For The Scraping",
    artist: "Fugazi",
    link: "https://youtu.be/SSFDzFxlNRE?si=8r6VLWGri-AYntrO",
  },
  {
    title: "Deer Dance",
    artist: "System of a Down",
    link: "https://youtu.be/1xD2ItImFEU?si=UA_hZHLJUeaR_mq4",
  },
  {
    title: "Chop Suey!",
    artist: "System of a Down",
    link: "https://youtu.be/CSvFpBOe8eY?si=ICu2ZTILiQeBGnuc",
  },
  {
    title: "Angeles",
    artist: "Elliott Smith",
    link: "https://youtu.be/ku_BFF6dUo0?si=C1plEpICtdQMnI_b",
  },
  {
    title: "No. 13 Baby",
    artist: "Pixies",
    link: "https://youtu.be/qKTLsQIzL4o?si=c4reVRvRDOPoA4DO",
  },
  {
    title: "Hey",
    artist: "Pixies",
    link: "https://youtu.be/tVCUAXOBF7w?si=lt8hA-RG4XuHs_ip",
  },
  {
    title: "Ponta de Lança Africano",
    artist: "Jorge Ben Jor",
    link: "https://youtu.be/Kk3Bci3jVBs?si=lh1vSnTlRcyXsu1y",
  },
  {
    title: "Radio Friendly Unit Shifter",
    artist: "Nirvana",
    link: "https://youtu.be/P7B-AlKTdGQ?si=hZS7uKQ2_hTo3-3p",
  },
  {
    title: "Mayonaise",
    artist: "Smashing Pumpkins",
    link: "https://youtu.be/x4Sb0EEDCj4?si=f7BLDOhm5qP1B0yG",
  },
  {
    title: "Gila",
    artist: "Beach House",
    link: "https://youtu.be/qxf6OgbvayM?si=X7MWE27XtK0MtaPw",
  },
  {
    title: "I Think I'm Paranoid",
    artist: "Garbage",
    link: "https://youtu.be/Mic6yYmPT84?si=QqLSozcgyQ43qcNu",
  },
  {
    title: "Cherry Coloured Funk",
    artist: "Cocteau Twins",
    link: "https://youtu.be/PbbUeLkZt74?si=rfH_vHzJ8lupUHy9",
  },
  {
    title: "Fifty-fifty Clown",
    artist: "Cocteau Twins",
    link: "https://youtu.be/b-WUAxgHgHw?si=6HESxF9L_jOEQ7bc",
  },
  {
    title: "Frou-frou Foxes in Midsummer Fires",
    artist: "Cocteau Twins",
    link: "https://youtu.be/qgffvFM1J-Q?si=tbbAfqX2CdMBwQUI",
  },
  {
    title: "Sugar",
    artist: "System of a Down",
    link: "https://youtu.be/bqNgSEU5YOs?si=mn8WoJXx7RuF0We7",
  },
  {
    title: "Highway Chile",
    artist: "The Jimi Hendrix Experience",
    link: "https://youtu.be/cjjkHoR4FXo?si=MohDsaLDUS1o_a2K",
  },
  {
    title: "The Mexican",
    artist: "Babe Ruth",
    link: "https://youtu.be/jQlYBhEdPH8?si=TQ9ft136otN4-l-k",
  },
  {
    title: "Fireworks",
    artist: "Animal Collective",
    link: "https://youtu.be/xE76LFB1saE?si=xDrSrlF25T3QaZCN",
  },
  {
    title: "That's The Way Love Goes",
    artist: "Janet Jackson",
    link: "https://www.youtube.com/watch?v=VITU-kdhiVs",
  },
  {
    title: "WHOA",
    artist: "Earl Sweatshirt",
    link: "https://www.youtube.com/watch?v=anRkutaPS9w",
  },
  {
    title: "Standing In The Way Of Control",
    artist: "Gossip",
    link: "https://youtu.be/yn043ArR98M?si=Vk7C5-jaUwjPNGpt",
  },
  {
    title: "Reptilia",
    artist: "The Strokes",
    link: "https://youtu.be/b8-tXG8KrWs?si=2-3ekBbLFpMEMlxH",
  },
  {
    title: "PPP",
    artist: "Beach House",
    link: "https://youtu.be/NFhJRTdmviA?si=LBXPjUdZuksO4pQd",
  },
  {
    title: "Broccoli",
    artist: "DRAM ft. Lil Yachty",
    link: "https://youtu.be/K44j-sb1SRY?si=xXgwkctUa3Bs8QGM",
  },
  {
    title: "Leaf House",
    artist: "Animal Collective",
    link: "https://youtu.be/VPWR98JCCRE?si=JtAlF7SW1sQgroFA",
  },
  {
    title: "That's Not Me",
    artist: "Skepta ft. JME",
    link: "https://youtu.be/_xQKWnvtg6c?si=wVz6-MjWr0qsaNga",
  },
  {
    title: "One More Hour",
    artist: "Sleater Kinney",
    link: "https://youtu.be/3VoWc3yypHs?si=rYuzJ2bzI4YH3vHR",
  },
  {
    title: "Hatıralar Hayal Oldu",
    artist: "Darío Moreno",
    link: "https://www.youtube.com/watch?v=Ad1vvVUqRQ8",
  },
  {
    title: "Stand Up Tall",
    artist: "Dizzee Rascal",
    link: "https://youtu.be/ehXuYYoA-ME?si=XHS-9JaIC7PVciw3",
  },
  {
    title: "Caliente, caliente",
    artist: "Raffaella Carrà",
    link: "https://www.youtube.com/watch?v=l3FrBFhZg28",
  },
  {
    title: "Ronald Reagan Era",
    artist: "Kendrick Lamar",
    link: "https://youtu.be/bk-_RTrUEkw?si=ux8wain6-7LHam9Q",
  },
  {
    title: "I Started Something I Couldn't Finish",
    artist: "The Smiths",
    link: "https://www.youtube.com/watch?v=5IR3hu5EyXY",
  },
  {
    title: "Video Girl",
    artist: "Stacey Q",
    link: "https://www.youtube.com/watch?v=zLSOcWrM76w",
  },
  {
    title: "Remember Me",
    artist: "Al Bowlly",
    link: "https://www.youtube.com/watch?v=W-hlYJeOSeA",
  },
  {
    title: "Close Your Eyes",
    artist: "Al Bowlly",
    link: "https://www.youtube.com/watch?v=fwzDIgK9_R8",
  },
].map((el) => ({ ...el, contentType: "music" }));
