import { useEffect, useState } from 'react';

export const useBorderType = () => {
  const [borderType, setBorderType] = useState<'ridge' | 'inset'>('ridge');

  useEffect(() => {
    setTimeout(() => {
      if (borderType === 'inset') {
        setBorderType('ridge');
      }
    }, 100);
  }, [borderType]);

  return { borderType, setBorderType };
};
