/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { myLinks } from "../data/links";

export const Footer = () => {
  return (
    <div
      css={css`
        left: 0;
        bottom: 0;
        width: calc(100% - 40px);
        color: white;
        text-align: center;
        padding: 20px;
        font-size: 12px;
        a {
          color: white;
        }
        background-color: black;
      `}
    >
      The images, websites and pieces of writing displayed on this site are not
      intended for any commercial use. If something is improperly credited,
      described or you would like something removed from the site please contact
      me via email at <a href={myLinks.mail}>kyriehalomusic@gmail.com</a>.
    </div>
  );
};
