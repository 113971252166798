/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { IoMenuSharp } from "react-icons/io5";
import { useNav } from "../NavProvider";
import { breakpointVals } from "../constants";
import { useScreenWidth } from "../hooks";
import { getMediaQuery } from "../utils";
import { SocialLink } from "./SocialLink";

const Header = ({ onClick }: { onClick: () => void }) => {
  const width = useScreenWidth();
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
      `}
    >
      {width < breakpointVals.tabletSm && (
        <div
          css={css`
            font-size: 20px;
          `}
          onClick={onClick}
        >
          <IoMenuSharp />
        </div>
      )}
      <img
        src="/images/display/kyriehalotextsmooth.png"
        css={css`
          width: 120px;
        `}
      />
    </div>
  );
};

const NavLink = ({
  href,
  name,
  imgSrc,
  imgWidth = 50,
}: {
  href: string;
  name: string;
  imgSrc: string;
  imgWidth?: number;
}) => (
  <a href={href}>
    <div
      css={css`
        ${linkWithHoverGif};
        :hover {
          img {
            width: ${imgWidth}px;
            margin: 0 auto;
          }
        }
      `}
    >
      {name}
      <img src={imgSrc} />
    </div>
  </a>
);

const PageLinks = () => {
  const { setMenuIsOpen } = useNav();
  return (
    <div css={pageLinks} onBlur={() => setMenuIsOpen(false)}>
      <NavLink href="/" name="home" imgSrc="/images/housegif.gif" />
      <NavLink
        href="/music"
        name="music"
        imgSrc="/images/musicgif.gif"
        imgWidth={25}
      />
      <NavLink
        href="/about"
        name="about"
        imgSrc="/images/animatedstarsgif.gif"
        imgWidth={28}
      />
      <a href="/ETERNITY">
        <div
          css={css`
            :hover {
              ${eternityLinkImage("/images/vortexgif.gif")};
            }
          `}
        >
          <div
            css={css`
              ${eternityLinkImage("/images/display/eternitytext2.png", 30)}
            `}
          />
        </div>
      </a>
    </div>
  );
};

export const NavBar = () => {
  const width = useScreenWidth();
  const { menuIsOpen, setMenuIsOpen } = useNav();

  return (
    <div css={mainContainer}>
      <div css={navContainer}>
        <Header onClick={() => setMenuIsOpen(!menuIsOpen)} />
        {width >= breakpointVals.tabletSm && <PageLinks />}
        <ul css={socialLinks}>
          <li>
            <SocialLink.Bandcamp />
          </li>
          <li>
            <SocialLink.Spotify />
          </li>
          <li>
            <SocialLink.Youtube />
          </li>
          <li>
            <SocialLink.Instagram />
          </li>
          <li>
            <SocialLink.Mail />
          </li>
        </ul>
      </div>
      {menuIsOpen && <PageLinks />}
    </div>
  );
};

const linkWithHoverGif = css`
  ${getMediaQuery("tabletSm")} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  min-width: 50px;
  img {
    display: none;
  }
  :hover {
    img {
      display: block;
    }
  }
`;

const mainContainer = css`
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 250;
  text-rendering: optimizeLegibility;
  position: fixed;
  width: 100%;
  background-color: black;
  top: 0;
`;

const navContainer = css`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  gap: 10px;
  height: 50px;
  z-index: 100;
`;

const pageLinks = css`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: white;
  padding: 5px 10px;
  a {
    color: white;
  }
  width: 100%;
  font-size: 18px;
  background-color: black;
  ${getMediaQuery("tabletSm")} {
    align-items: center;
    flex-direction: row;
    width: auto;
    position: static;
    gap: 10px;
    font-size: 14px;
  }
`;

const eternityLinkImage = (backgroundImage: string, height = 50) => css`
  background: no-repeat center url(${backgroundImage});
  background-size: contain;
  background-position: center;
  width: 150px;
  height: 70px;
  ${getMediaQuery("tabletSm")} {
    width: 100px;
    height: ${height}px;
  }
`;

const socialLinks = css`
  list-style: none;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    height: 30px;
    :hover {
      font-family: "Jacquarda Bastarda 9";
    }
    a {
      color: white;
      text-decoration: none;
      :visited {
        color: white;
      }
    }
  }
`;
