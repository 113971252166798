export const myLinks = {
  spotify:
    "https://open.spotify.com/artist/1TXDxqGPOp6aoz6UyAE4O0?si=WzEmAYEJQYKqFECnthplFQ",
  youtube: "https://www.youtube.com/channel/UCoucElZAIJNac0JcyH4_Yrg",
  instagram: "https://instagram.com/kyriehalo",
  bandcamp: "https://kyriehalo.bandcamp.com/",
  mail: "mailto:kyriehalomusic@gmail.com",
} as const;

export const playlistLinks = {
  eternityDNA:
    "https://open.spotify.com/playlist/4m1kOLSmFqNz7tK7NjZ6I9?si=6ab805a00061497e",
};
