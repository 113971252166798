/** @jsxImportSource @emotion/react */
import "../../../styles.css";
import { css } from "@emotion/react";
import { copy } from "../../../pages/home/copy";

export const NoResponse = ({ resetAnswer }: { resetAnswer: () => void }) => {
  return (
    <div>
      <img
        {...copy.noResponse.image}
        css={css`
          width: 300px;
        `}
      />
      <p>
        {copy.noResponse.text}
        <button
          css={css`
            background-color: transparent;
            border: none;
            color: white;
            padding: 0;
            margin: 0;
            cursor: pointer;
            text-decoration: underline;
          `}
          onClick={resetAnswer}
        >
          {copy.noResponse.buttonText}
        </button>
      </p>
    </div>
  );
};
