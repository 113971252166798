/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getMediaQuery } from "../../../utils";

export const Televisions = () => (
  <div
    css={css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 3px;
      background-color: black;
      .tv-image {
        border-radius: 5px;
        width: calc(100% - 5px);
        ${getMediaQuery("desktopMd")} {
          width: 90%;
        }
        border: 3px ridge black;
      }
      .tv {
        background: linear-gradient(
          #5f5f5f 5%,
          #9d9d9d 30%,
          #c0c0c0 50%,
          #a3a3a3 80%,
          #999 95%,
          #777676 70%
        );
        border-top: outset 10px #878787;
        border-left: outset 10px #878787;
        border-right: outset 10px #878787;
        width: calc(50% - 23px);
        ${getMediaQuery("desktopMd")} {
          width: calc(50% - 23px);
        }
        /* ${getMediaQuery("desktopLg")} {
          width: 35%;
        } */
        border-radius: 5px;
      }
      .tv-light {
        width: 5px;
      }
      .button {
        border-radius: 2px;
        border: inset 3px #878787;
        border-style: outset;
        height: 3px;
        background: linear-gradient(
          #5f5f5f 5%,
          #9d9d9d 30%,
          #c0c0c0 50%,
          #a3a3a3 80%,
          #999 95%,
          #777676 70%
        );
        width: 6px;
        height: 6px;
      }
      .tv-controls {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    `}
  >
    <div className="tv">
      <img src="/images/vhssunsetgif4.gif" className="tv-image" />
      <div className="tv-controls">
        <div className="button" />
        <div>
          <img src="/images/flashingredlightf.gif" className="tv-light" />
          <img src="/images/flashinggreenlight.gif" className="tv-light" />
        </div>
      </div>
    </div>
    <div className="tv">
      <img src="/images/vhssunsetgif.gif" className="tv-image" />
      <div className="tv-controls">
        <div className="button" />
        <div>
          <img src="/images/flashingredlightf.gif" className="tv-light" />
          <img src="/images/flashinggreenlight.gif" className="tv-light" />
        </div>
      </div>
    </div>
    <div className="tv">
      <img src="/images/vhssunsetgif3.gif" className="tv-image" />
      <div className="tv-controls">
        <div className="button" />
        <div>
          <img src="/images/flashingredlightf.gif" className="tv-light" />
          <img src="/images/flashinggreenlight.gif" className="tv-light" />
        </div>
      </div>
    </div>
    <div className="tv">
      <img src="/images/vhssunsetgif2.gif" className="tv-image" />
      <div className="tv-controls">
        <div className="button" />
        <div>
          <img src="/images/flashingredlightf.gif" className="tv-light" />
          <img src="/images/flashinggreenlight.gif" className="tv-light" />
        </div>
      </div>
    </div>
  </div>
);
