/** @jsxImportSource @emotion/react */
import { getMediaQuery } from "../../../utils";
import { css } from "@emotion/react";
import { musicILove } from "../../../data/musicILove";
import React from "react";
import { Music } from "../../../somethings";

export const Albums = ({
  selectedAlbum,
  setSelectedAlbum,
}: {
  selectedAlbum?: Music;
  setSelectedAlbum: (album?: Music) => void;
}) => {
  const handleAlbumClick = (i: number, album: Music) =>
    selectedAlbum === album
      ? setSelectedAlbum(undefined)
      : setSelectedAlbum(album);

  return (
    <div css={mainContainer}>
      {musicILove.map((album, i) => {
        return (
          <React.Fragment key={`album-${i}`}>
            <img
              src={album.src}
              onClick={() => handleAlbumClick(i, album)}
              css={css`
                border: inset 2px #c07850;
              `}
              alt={`${album.title} by ${album.artist}`}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

const mainContainer = css`
  display: flex;
  flex-wrap: wrap;
  border: ridge 2px#4b3124;
  img {
    width: calc(20% - 4px);
    cursor: pointer;
  }
  overflow-y: scroll;
  height: 500px;
  ${getMediaQuery("tabletSm")} {
    height: 100%;
  }
  margin: 5px 0px 5px 5px;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #2c1d15;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background: #c07850;
    border: 2px outset #2c1d15;
  }
`;
