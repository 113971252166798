/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
  AnswerComputerWindow,
  DraggableComputerWindow,
} from "../../components";
import { Something } from "../../somethings";
import { useGetSomethings, useGetCoords } from "../../hooks";
import "../../styles.css";
import { indecisionQuotes } from "../../data/indecision";

import { copy } from "../../pages/home/copy";
import { NoResponse } from "./components/NoResponse";
import { NotSureResponse } from "./components/NotSureResponse";

export type Quote = {
  quote: string;
  author: string;
};

export const Home = () => {
  const coords = useGetCoords();
  const [answer, setAnswer] = useState<string>();
  const { somethingsToDisplay, getNewSomething } = useGetSomethings();
  const [itemAtFront, setItemAtFront] = useState<Something>();
  const [quote, setQuote] = useState<Quote>(indecisionQuotes[0]);

  const getRandomQuote = () => {
    let randomNumber = Math.floor(Math.random() * indecisionQuotes.length);
    let randomQuote = indecisionQuotes[randomNumber];
    while (randomQuote === quote) {
      randomQuote = getRandomQuote();
    }
    return randomQuote;
  };

  useEffect(() => {
    let randomQuote = getRandomQuote();
    setQuote(randomQuote);
  }, []);

  return (
    <>
      <div>
        {!answer && (
          <AnswerComputerWindow
            {...copy.answerComputerWindow}
            setAnswer={setAnswer}
          />
        )}
      </div>
      <div>
        {answer === "Yes" &&
          somethingsToDisplay.map((something, i) => {
            if (!something) {
              return <div>{copy.noMoreContentMessage}</div>;
            }
            return (
              <DraggableComputerWindow
                something={something}
                onCloseClick={() => {
                  getNewSomething(something);
                }}
                defaultPosition={coords[i]}
                onDrag={() => {
                  setItemAtFront(something);
                }}
                moveToFront={itemAtFront === something}
                key={something.link}
              />
            );
          })}
        {answer === "No" && (
          <NoResponse resetAnswer={() => setAnswer(undefined)} />
        )}
        {answer === "Not sure" && (
          <NotSureResponse
            quote={quote}
            resetAnswer={() => setAnswer(undefined)}
            resetQuote={() => {
              const randomQuote = getRandomQuote();
              setQuote(randomQuote);
            }}
          />
        )}
      </div>
    </>
  );
};
