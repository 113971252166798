/** @jsxImportSource @emotion/react */
import { getMediaQuery } from "../../../utils";
import { css } from "@emotion/react";

export const SoundSystem = () => {
  return (
    <div
      css={css`
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        width: 100%;
        gap: 5px;
        img {
          width: 60px;
          height: auto;
        }
        .button {
          border-radius: 2px;
          border: inset 3px #878787;
          border-style: outset;
          height: 3px;
          background: linear-gradient(
            #5f5f5f 5%,
            #9d9d9d 30%,
            #c0c0c0 50%,
            #a3a3a3 80%,
            #999 95%,
            #777676 70%
          );
        }
      `}
    >
      <img src="/images/speakergif3.gif" />
      <div
        css={css`
          background: linear-gradient(
            #5f5f5f 5%,
            #bdbdbd 10%,
            #9d9d9d 30%,
            #c0c0c0 50%,
            #a3a3a3 80%,
            #999 95%,
            #777676 70%
          );
          width: 300px;
          height: 60px;
          border-radius: 2px;
          border: inset 8px #6a6a6a;
          border-style: outset;
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 5px;
        `}
      >
        <div
          css={css`
            background-color: black;
            border: 2px #6a6a6a ridge;
            padding: 10px;
            width: 80%;
            height: 10px;
            display: flex;
            .equaliser {
              width: 20px;
            }
            border-radius: 2px;
            justify-content: space-between;
          `}
        >
          <img src="/images/equalisergif.gif" className="equaliser" />
          <img src="/images/digitalscreenmusicgif.gif" />
          <img src="/images/equalisergif.gif" className="equaliser" />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            align-self: flex-start;
            .flashing-light {
              width: 5px;
            }
            gap: 10px;
            width: 100%;
            justify-content: space-around;
            height: 20px;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 3px;
            `}
          >
            <img
              src="/images/flashingredlightf.gif"
              className="flashing-light"
            />
            <img
              src="/images/flashinggreenlight.gif"
              className="flashing-light"
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 2px;
            `}
          >
            <div
              css={css`
                width: 4px;
              `}
              className="button"
            />
            <div
              css={css`
                width: 6px;
              `}
              className="button"
            />
            <div
              css={css`
                width: 4px;
              `}
              className="button"
            />
            <div
              css={css`
                width: 10px;
              `}
              className="button"
            />
            <div
              css={css`
                width: 4px;
              `}
              className="button"
            />
            <div
              css={css`
                width: 4px;
              `}
              className="button"
            />
          </div>
          <div
            css={css`
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 1px ridge #989898;
              background: conic-gradient(
                #484848,
                #6d6c6c,
                #a3a3a3,
                #585858,
                #777676
              );
            `}
          />
        </div>
      </div>
      <img src="/images/speakergif3.gif" />
      {/* <img src="/images/cdgif.gif" />
  <h2>Some music I love</h2>
  <img src="/images/cdgif.gif" /> */}
    </div>
  );
};
