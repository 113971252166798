import { Image } from "./interfaces";
import { SONGS } from "../constants";
import { creativeCommonsImages } from "./creativeCommonsImages";

export const images: Image[] = [
  ...creativeCommonsImages,
  {
    title: "Still from Belladonna of Sadness (1973)",
    src: "/images/belladonna1.jpeg",
  },
  // {
  //   title: "Still from Belladonna of Sadness (1973)",
  //   src: "/images/belladonna2.jpeg",
  // },
  {
    title: "Still from Belladonna of Sadness (1973)",
    src: "/images/belladonna3.jpeg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Belladonna of Sadness (1973)",
    src: "/images/belladonna4.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Belladonna of Sadness (1973)",
    src: "/images/belladonna5.jpeg",
  },
  {
    title: "Still from Belladonna of Sadness (1973)",
    src: "/images/belladonna6.jpeg",
  },
  {
    title: "Still from Belladonna of Sadness (1973)",
    src: "/images/belladonna7.jpeg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Belladonna of Sadness (1973)",
    src: "/images/belladonna8.jpeg",
  },
  {
    title: "Waterloo Bridge, London, at Dusk (1904) - Claude Monet",
    src: "/images/monet1.jpg",
    // songTags: [SONGS.imNotSure],
  },
  {
    title: "Country Garden with Sunflowers (1907) - Gustav Klimt",
    src: "/images/klimt1.jpeg",
  },
  {
    title: "The Joy of Life (1905 - 1906) - Henri Matisse",
    src: "/images/matisse1.jpg",
  },
  {
    title: "Woman Bather (1906) - Henri Manguin",
    src: "/images/henrimanguin.jpg",
  },
  {
    title: "Murnau - Landscape with Green House (1909) - Wassily Kandinsky",
    src: "https://www.wassilykandinsky.net/images/works/729.jpg",
  },
  {
    title: "The Seine at Chatou (1906) - Maurice de Vlaminck",
    src: "https://upload.wikimedia.org/wikipedia/en/2/21/SeineChatou.JPG",
  },
  {
    title: "Nypmh and Satyr (1871) - Arnold Böcklin",
    src: "/images/nymphandsatyr.jpeg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Open Window, Collioure (1905) - Henri Matisse",
    src: "https://smarthistory.org/wp-content/uploads/2020/01/matisse-open-window-collioure.jpg",
  },
  {
    title: "Waterloo Bridge (1906) - André Derain",
    src: "https://www.museothyssen.org/sites/default/files/styles/full_resolution/public/imagen/obras/1972.17_puente-waterloo.jpg",
  },
  {
    title: "Luxe, Calme et Volupté (1904) - Henri Matisse",
    src: "https://cdn.mediatheque.epmoo.fr/link/xuf189jqbt9ojsw.jpg",
  },
  {
    title: "Satyr Carrying a Nymph on His Back (1769/71) - Salomon Gessner",
    src: "/images/satyrsalomon.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Hylas and the Nymphs (1896) - John William Waterhouse",
    src: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Waterhouse_Hylas_and_the_Nymphs_Manchester_Art_Gallery_1896.15.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "The Farm (1921-1922) - Joan Miró",
    src: "https://www.sartle.com/media/artwork/the-farm-joan-miro.jpg",
  },
  {
    title: "Helmut Newton for British Vogue, September 1968",
    src: "/images/helmutnewton1.jpeg",
  },
  {
    title: "Twiggy by Helmut Newton for British Vogue, April 1967",
    src: "/images/helmutnewton2.jpeg",
  },
  {
    title: "Helmut Newton for British Vogue",
    src: "https://media.vogue.co.uk/photos/5f9fe306c73a6a3552b09116/2:3/w_2240,c_limit/Helmut%20Newton%20October%201st%201973%20.jpg",
  },
  {
    title: "Madonna by Mark Lebon for i-D magazine, April 1984",
    src: "/images/madonnaidmag.jpg",
  },
  {
    title: "Madonna",
    src: "/images/madonna.jpg",
  },
  {
    title: "Oluchi Onweagba for Luca Luca, Fall 2001",
    src: "https://i.pinimg.com/736x/17/0f/af/170faf6ce5eb355221a264406fa72365.jpg",
  },
  {
    title: "Namie Amuro for Kosé",
    src: "https://i.pinimg.com/564x/e6/5c/40/e65c40f5acc07b85210d148019b935ff.jpg",
  },
  {
    title: "Beyoncé, early 2000s",
    src: "/images/beyonce1.jpeg",
  },
  {
    title: "Gokinjo Monogatari",
    src: "/images/gokinjomonogatari.jpeg",
  },
  {
    title: "Versus Istante, Spring 1995",
    src: "/images/fashion1.jpeg",
  },
  {
    title: "Versus Istante, Spring 1995",
    src: "/images/fashion2.jpeg",
  },
  {
    title: "John Galliano, Spring / Summer 2004",
    src: "/images/fashion3.jpeg",
  },
  {
    title: "Kurt in Hamburg, 1991",
    src: "/images/kurt1.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Penelope Cruz, 1992",
    src: "/images/penelope1.jpeg",
  },
  {
    title: "Penelope Cruz",
    src: "/images/penelope2.jpeg",
  },
  {
    title: "Penelope Cruz",
    src: "/images/penelope3.webp",
    songTags: [SONGS.halo],
  },
  {
    title: "Penelope Cruz",
    src: "/images/penelope4.jpeg",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup065.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup064.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup063.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup062.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup061.jpg?bwg=1546960204",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup059.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup058.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup057.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup056.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup055.jpg?bwg=1546960204",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup054.jpg?bwg=1546960204",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup051.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup050.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup049.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup048.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup047.jpg?bwg=1546960204",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup046.jpg?bwg=1546960204",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup042.jpg?bwg=1546960205",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup041.jpg?bwg=1546960205",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup040.jpg?bwg=1546960205",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup039.jpg?bwg=1546960205",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup036.jpg?bwg=1546960205",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup031.jpg?bwg=1546960205",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup030.jpg?bwg=1546960205",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup027.jpg?bwg=1546960205",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup026.jpg?bwg=1546960205",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup022.jpg?bwg=1546960205",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup016.jpg?bwg=1546960205",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup013.jpg?bwg=1546960206",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup008.jpg?bwg=1546960206",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup004.jpg?bwg=1546960206",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup003.jpg?bwg=1546960206",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://dp9a3tyzxd5qs.cloudfront.net/tie-me-up-tie-me-down-tame-1989__800.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/tiemeup001.jpg?bwg=1546960206",
  },
  {
    title: "Victoria Abril in Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://www.austinchronicle.com/binary/1591/tieme.jpg",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://images.squarespace-cdn.com/content/v1/5a7dcf69d7bdce185884a6b5/1574862277313-H417N40D30Q9A7PIVGKY/image-w1280.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Scene card for Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://cdn.posteritati.com/posters/000/000/025/338/tie-me-up-tie-me-down-md-web.jpg",
  },
  {
    title: "Scene card for Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://cdn.posteritati.com/posters/000/000/025/339/tie-me-up-tie-me-down-md-web.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Scene card for Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://cdn.posteritati.com/posters/000/000/063/149/tie-me-up-tie-me-down-md-web.jpg",
  },
  {
    title: "Still from Tie Me Up! Tie Me Down! (Átame) (1989)",
    src: "https://a.ltrbxd.com/resized/sm/upload/te/36/a0/d9/tie-me-up-tie-me-down-1200-1200-675-675-crop-000000.jpg?v=becd54dcfa",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from La ciénaga (2001)",
    src: "https://assets.mubicdn.net/images/artworks/621774/images-original.png?1690559871",
  },
  {
    title: "Still from La ciénaga (2001)",
    src: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgNxpTPRkIUjonkRpkUAQ6-2V61sq-b2XgrX32QwJ1hM3QawRufGSqyRkRMfspulGUOrYo_Y-gHdr4f50agiA95UIyMZY234pOb9b4pkZnIRiI8ReTbrz4fsh9I5IHmpzQwTcSxomlFQvSv/w1200-h630-p-k-no-nu/lacienagacap2.jpg",
  },
  {
    title: "Still from La ciénaga (2001)",
    src: "https://pbs.twimg.com/media/EvzD2ncWQAYZhmb.jpg:large",
  },
  {
    title: "Still from La ciénaga (2001)",
    src: "/images/lacienaga1.png",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from La ciénaga (2001)",
    src: "https://criterion-production.s3.amazonaws.com/carousel-files/ee0ad6b2e633a86e8c87c7d135aca87e.jpeg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from The Green Ray (Le Rayon vert) (1986)",
    src: "https://celluloidwickerman.com/wp-content/uploads/2018/05/940__the_green_ray_x04_blu-ray__.jpg",
  },
  {
    title: "Still from The Green Ray (Le Rayon vert) (1986)",
    src: "/images/thegreenray1.jpeg",
  },
  {
    title: "Still from The Green Ray (Le Rayon vert) (1986)",
    src: "https://seeingthingssecondhand.com/wp-content/uploads/2019/10/screenshot-440.png?w=640",
  },
  {
    title: "Still from The Green Ray (Le Rayon vert) (1986)",
    src: "/images/thegreenray2.webp",
  },
  {
    title: "Still from The Green Ray (Le Rayon vert) (1986)",
    src: "https://m.media-amazon.com/images/M/MV5BN2YyOWM2ZjgtOWQ5Mi00ZDE5LWE5MzUtMDRiZTQ3YjczZTcxXkEyXkFqcGdeQXVyOTc5MDI5NjE@._V1_.jpg",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://inreviewonline.com/wp-content/uploads/2019/03/Eyes_Wide_Shut_still_5-e1595293128290.jpg",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://pyxis.nymag.com/v1/imgs/5aa/620/6da05296acac1ff8060c8c815e15ea22de-eyes-wide-shut-fnmc.2x.rsocial.w600.jpg",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://static1.srcdn.com/wordpress/wp-content/uploads/2021/01/Nicole-Kidman-in-Eyes-Wide-shut.jpg",
  },
  {
    title: "Nicole Kidman, 1995",
    src: "https://lh3.googleusercontent.com/proxy/xNTwfrGpjLCkxOFMUZzhhf4cI3zZjBLHrM-5MCfmpT-45jw3d9RiE4PLgzQl_lpJv6QxyhkGgohDic0MxXYnifEzXB443DDTbLLHWptpBjnP7_Qp8CTZ5R2-jZaYLOJrsMgyndJnxKuLYZqd3bcziQUvHjTsFwju9laW4YO_VeiT64Z21wqigiEvSf-HRCGYVed7fsN3wg",
  },
  {
    title: "Pam Grier",
    src: "https://www.gainesville.com/gcdn/authoring/2010/05/27/NTGS/ghows-LK-6cdc3bbc-a52e-41ae-ad78-dded442d44e2-6986d298.jpeg?width=660&height=846&fit=crop&format=pjpg&auto=webp",
    songTags: [SONGS.halo],
  },
  {
    title: "Pam Grier",
    src: "https://images.theconversation.com/files/550615/original/file-20230927-21-2hsi10.jpg?ixlib=rb-4.1.0&rect=2%2C5%2C1855%2C1069&q=45&auto=format&w=926&fit=clip",
  },
  {
    title: "Pam Grier",
    src: "https://1.bp.blogspot.com/-fFHa_VFKk4w/XaNOo9gemBI/AAAAAAACGUE/8WmqXNTx3jkODsGx3BFHyNagiQp1EizFgCLcBGAsYHQ/s1600/pam-grier-9.jpg",
  },
  {
    title: "Pam Grier",
    src: "https://1.bp.blogspot.com/-4VaWNT5YV20/XaNOmP9nYzI/AAAAAAACGTw/awvksQoV6x4nbqggRhmIP69cnTIby62kACLcBGAsYHQ/s1600/pam-grier-4.jpg",
  },
  {
    title: "Pam Grier",
    src: "https://1.bp.blogspot.com/-2DyA5Xdh5lA/XaNOb6-crYI/AAAAAAACGSY/2CGLBOnL1uMBWw_tGIgon2Mhf_lpTT2vACLcBGAsYHQ/s1600/pam-grier-10.jpg",
  },
  {
    title: "Pam Grier",
    src: "https://1.bp.blogspot.com/-OYBy_QNskJQ/XaNOe5vzKAI/AAAAAAACGSg/GdGDiNfn13cH1eGxyufGOaUpQ2GK5TMGwCLcBGAsYHQ/s1600/pam-grier-12.jpg",
  },
  {
    title: "Pam Grier",
    src: "https://1.bp.blogspot.com/-xXpBjubKZMk/XaNOgUs0J2I/AAAAAAACGSw/0uhj4OQ92Hk3aFZqvHpnehg6oCaNYAmlQCLcBGAsYHQ/s1600/pam-grier-16.jpg",
  },
  {
    title: "Pam Grier",
    src: "https://1.bp.blogspot.com/-KspvCPYTz-U/XaNOiIsxwyI/AAAAAAACGTE/IMP5H35pwqUJjVK9l_ryi7hBzWAtH1wOQCLcBGAsYHQ/s1600/pam-grier-20.jpg",
  },
  {
    title: "Pam Grier",
    src: "https://1.bp.blogspot.com/-1qFeDL1Imn4/XaNOkhHfVAI/AAAAAAACGTc/YiRh05Ta_Hoxq3K4XA85u9DFZG0O5U5_gCLcBGAsYHQ/s1600/pam-grier-25.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Advert for Sno Blo",
    src: "https://1.bp.blogspot.com/-xWjqunNmJ3s/YZvrZxAnu2I/AAAAAAAAg7o/WjRfxTOygxcr8OVlf-LBxcQf7jdUdlFlwCLcBGAsYHQ/s1078/cocaine-paraphernalia-ads-1970s%2B%252817%2529.jpg",
    link: "https://rarehistoricalphotos.com/cocaine-paraphernalia-ads-1970s/",
  },
  {
    title: "Advert for The Blue Lady",
    src: "https://1.bp.blogspot.com/-fB9cZkqTM6s/YZvrXR8JbpI/AAAAAAAAg7Q/2bEccezlNYkP1DhWYV3lOZsVwOLFj75cACLcBGAsYHQ/s1324/cocaine-paraphernalia-ads-1970s%2B%25281%2529.jpg",
    link: "https://rarehistoricalphotos.com/cocaine-paraphernalia-ads-1970s/",
  },
  {
    title: "Advert for Easy Liner products",
    src: "https://1.bp.blogspot.com/-G2C-ticcDLg/YZvrcLwJmyI/AAAAAAAAg8A/l9MK1OLJmC4cNdSBOuMLais_JB3tZUD_ACLcBGAsYHQ/s1370/cocaine-paraphernalia-ads-1970s%2B%25284%2529.jpg",
    link: "https://rarehistoricalphotos.com/cocaine-paraphernalia-ads-1970s/",
  },
  {
    title: "Advert for Precious Things",
    src: "https://1.bp.blogspot.com/-mejskuHLFX4/YZvrXbhu1wI/AAAAAAAAg7I/EFpcG5nqNtUZY4AxvBJ5lrMKQD2YXl6DgCLcBGAsYHQ/s1000/cocaine-paraphernalia-ads-1970s%2B%252811%2529.jpg",
    link: "https://rarehistoricalphotos.com/cocaine-paraphernalia-ads-1970s/",
  },
  {
    title: "Advert for Tout de Suite",
    src: "https://1.bp.blogspot.com/-ZrC2pWVniBc/YZvrwZie1XI/AAAAAAAAg9E/YIEYTLeYIok1oNENFclwhUkg277W2qbgACLcBGAsYHQ/s1440/cocaine-paraphernalia-ads-1970s%2B%252829%2529.jpg",
    link: "https://rarehistoricalphotos.com/cocaine-paraphernalia-ads-1970s/",
  },
  {
    title: "Cover of Head magazine",
    src: "https://1.bp.blogspot.com/-egAROIDmrBk/YZvryk65kEI/AAAAAAAAg9Y/g7m0CvRI-Gg-AcS3p5219RekwmSV1h1bQCLcBGAsYHQ/s1361/cocaine-paraphernalia-ads-1970s%2B%252834%2529.jpg",
    link: "https://rarehistoricalphotos.com/cocaine-paraphernalia-ads-1970s/",
  },
  {
    title: "Vintage ad for Beyer's heroin",
    src: "https://4.bp.blogspot.com/-yB2OY3JKMXU/XoyCg01aeEI/AAAAAAAAVcI/bUu_BWS-j-cie1f9twO7WhoIv61yuoafACLcBGAsYHQ/s1600/Vintage_drug_ads%2B%25284%2529.jpg",
    link: "https://rarehistoricalphotos.com/vintage-ads-cocaine-heroin-1880-1920/",
  },
  {
    title: "Vintage ad for Coca-Cola",
    src: "https://2.bp.blogspot.com/-2pqo0TWJrew/XoyChVB6qAI/AAAAAAAAVcQ/0nRN6K27RMsc9FzGs1bO7jQRrzX8QJd_ACLcBGAsYHQ/s1600/Vintage_drug_ads%2B%25286%2529.jpg",
    link: "https://rarehistoricalphotos.com/vintage-ads-cocaine-heroin-1880-1920/",
  },
  {
    title: "Vintage ad for cocaine tablets",
    src: "https://4.bp.blogspot.com/-JLMRI58Eh88/XoyChtn8pMI/AAAAAAAAVcY/rMIMzjzYe98Y-C1P0uorswCNubNOKa-iwCLcBGAsYHQ/s1600/Vintage_drug_ads%2B%25287%2529.jpg",
    link: "https://rarehistoricalphotos.com/vintage-ads-cocaine-heroin-1880-1920/",
  },
  {
    title: "Heroin Hydrochlor",
    src: "https://4.bp.blogspot.com/-manFTCO4208/XoyCilamayI/AAAAAAAAVcc/lSbyviSJ_yggaoktl8vHRqnLQHLRBcb7ACLcBGAsYHQ/s1600/Vintage_drug_ads%2B%25289%2529.jpg",
    link: "https://rarehistoricalphotos.com/vintage-ads-cocaine-heroin-1880-1920/",
  },
  {
    title: "Vintage ad for Vin Mariani",
    src: "https://3.bp.blogspot.com/-f15tV0Clu0A/XoyCe0kcUxI/AAAAAAAAVbs/stk4U09K39catEmGjUu407omK3XuhviywCLcBGAsYHQ/s1600/Vintage_drug_ads%2B%252815%2529.jpg",
    link: "https://rarehistoricalphotos.com/vintage-ads-cocaine-heroin-1880-1920/",
  },
  {
    title: "Berlin, 1920s",
    src: "https://3.bp.blogspot.com/-F42Clv3j1s8/VT6JgVxEyiI/AAAAAAABd7M/G6FH_3SYPpw/s1600/Berlin%2C%2B1920s%2B(4).jpg",
    link: "https://rarehistoricalphotos.com/berlin-vintage-photos-1920s/",
  },
  {
    title: "Berlin, 1920s",
    src: "https://3.bp.blogspot.com/-vxiUtHmnCYU/VT6Ji_IZZeI/AAAAAAABd7Y/fZSNiGNxLlQ/s1600/Berlin%2C%2B1920s%2B(6).jpg",
    link: "https://rarehistoricalphotos.com/berlin-vintage-photos-1920s/",
  },
  {
    title: "London press pic from 1960s",
    src: "https://media.gq-magazine.co.uk/photos/5f3f95bd503430459301bd0a/16:9/w_2240,c_limit/20200821-swinging-01.jpg",
  },
  {
    title: "London, 1960s",
    src: "https://media.gq-magazine.co.uk/photos/5f3f95bede9e55565bc1f94a/master/w_1600,c_limit/20200821-swinging-05.jpg",
  },
  {
    title: "Jean Shrimpton, 1960s",
    src: "https://media.gq-magazine.co.uk/photos/5f3f95bdde9e55565bc1f948/master/w_1600,c_limit/20200821-swinging-02.jpg",
  },
  {
    title: "Bianca Jagger",
    src: "https://hips.hearstapps.com/hmg-prod/images/bianca-morena-de-macias-fiance-of-mick-jagger-during-a-news-photo-1590001747.jpg",
  },
  {
    title: "Mick and Bianca Jagger",
    src: "https://i.pinimg.com/originals/7d/7b/54/7d7b54559b2a4c166bdeabd3c9bb2563.jpg",
  },
  {
    title: "Bianca Jagger",
    src: "https://i.pinimg.com/originals/c1/b8/fc/c1b8fcdeb1206ccab056002f83f19b29.jpg",
  },
  {
    title: "Bianca Jagger",
    src: "https://www.whistles.com/on/demandware.static/-/Library-Sites-WHSharedLibrary/default/dw914797af/images/inspiration/Whistles-Muse-Bianca-Jagger-12-768x995.jpg",
  },
  {
    title: "Bianca Jagger",
    src: "https://cdn.mos.cms.futurecdn.net/E7ggvZYgfRbZuWLScwJJHU.jpg",
  },
  {
    title: "Kate Moss, Gucci Fall 1996",
    src: "/images/kate1.jpeg",
  },
  {
    title: "Kate Moss",
    src: "/images/kate2.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Kate Moss",
    src: "https://metronews.it/wp-content/uploads/2024/02/Kate-Moss.jpg",
  },
  {
    title: "Kate Moss",
    src: "https://i.pinimg.com/736x/7a/53/cc/7a53ccdc186e688fac18ce5b4cfa9989.jpg",
  },
  {
    title: "Devon Aoki for Chanel Haute Couture Spring/Summer 1999",
    src: "/images/devon1.jpeg",
  },
  {
    title: "Christy Turlington for Chanel 1991/1992",
    src: "/images/christy1.jpeg",
  },
  {
    title: "Kate Moss",
    src: "https://i.pinimg.com/736x/c1/28/e3/c128e372d19f2d052ee7a0ab9d8f941e.jpg",
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/03%20(713).jpg?bwg=1547237983",
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/04%20(714).jpg?bwg=1547237983",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/08%20(714).jpg?bwg=1547237983",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/16%20(728).jpg?bwg=1547237983",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/25%20(728).jpg?bwg=1547237982",
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/28%20(727).jpg?bwg=1547237982",
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/35%20(725).jpg?bwg=1547237982",
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/40%20(723).jpg?bwg=1547237982",
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/42%20(717).jpg?bwg=1547237982",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/45%20(713).jpg?bwg=1547237982",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/46%20(709).jpg?bwg=1547237982",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/54%20(676).jpg?bwg=1547237982",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/55%20(673).jpg?bwg=1547237982",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/56%20(663).jpg?bwg=1547237982",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/57%20(663).jpg?bwg=1547237982",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/63%20(559).jpg?bwg=1547237982",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Mulholland Drive (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/64%20(537).jpg?bwg=1547237982",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/01%20(361).jpg?bwg=1547215795",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/02%20(361).jpg?bwg=1547215795",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/03%20(361).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/05%20(361).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/07%20(361).jpg?bwg=1547215795",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/08%20(361).jpg?bwg=1547215795",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/10%20(368).jpg?bwg=1547215794",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/11%20(368).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/13%20(368).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/14%20(368).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/16%20(368).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/21%20(368).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/22%20(368).jpg?bwg=1547215795",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/23%20(368).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/24%20(368).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/26%20(368).jpg?bwg=1547215794",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/27%20(368).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/33%20(367).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/37%20(366).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/38%20(366).jpg?bwg=1547215794",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/43%20(360).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/46%20(356).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/47%20(350).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/55%20(335).jpg?bwg=1547215794",
  },
  {
    title: "Still from Eyes Wide Shut (1999)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/58%20(326).jpg?bwg=1547215794",
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/09%20(1272).jpg?bwg=1547479422",
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/14%20(1299).jpg?bwg=1547479423",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/23%20(1299).jpg?bwg=1547479422",
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/24%20(1299).jpg?bwg=1547479422",
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/27%20(1299).jpg?bwg=1547479422",
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/51%20(1243).jpg?bwg=1547479422",
    songTags: [SONGS.myAngel, SONGS.immaculate],
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/58%20(1175).jpg?bwg=1547479422",
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/59%20(1165).jpg?bwg=1547479422",
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/61%20(1114).jpg?bwg=1547479422",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Vertigo (1958)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/65%20(878).jpg?bwg=1547479422",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_001.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_004.jpg?bwg=1648811307",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_012.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_013.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_014.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_015.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_016.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_017.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_020.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_021.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_022.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_024.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_025.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_026.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_032.jpg?bwg=1648811307",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_039.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_040.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_041.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_042.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_043.jpg?bwg=1648811307",
  },
  {
    title: "Still from Black Orpheus (Orfeu Negro) (1959)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Black_Orpheus_044.jpg?bwg=1648811307",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien010.jpg?bwg=1546976162",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien011.jpg?bwg=1546976162",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien012.jpg?bwg=1546976162",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien015.jpg?bwg=1546976162",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien025.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien026.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien029.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien032.jpg?bwg=1546976161",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien033.jpg?bwg=1546976161",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien034.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien037.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien038.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien039.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien040.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien043.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien046.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien047.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien048.jpg?bwg=1546976161",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien049.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien050.jpg?bwg=1546976161",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien052.jpg?bwg=1546976161",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien053.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien054.jpg?bwg=1546976161",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien055.jpg?bwg=1546976161",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien056.jpg?bwg=1546976161",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien058.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien059.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien061.jpg?bwg=1546976161",
  },
  {
    title: "Still from Y tu mamá también (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/imported_from_media_libray/ytumamatambien062.jpg?bwg=1546976161",
  },
  {
    title: "Still from Good Time (2017)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/goodtime011.jpg?bwg=1551285089",
  },
  {
    title: "Still from Good Time (2017)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/goodtime012.jpg?bwg=1551285089",
  },
  {
    title: "Still from Good Time (2017)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/goodtime013.jpg?bwg=1551285089",
  },
  {
    title: "Still from Good Time (2017)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/goodtime034.jpg?bwg=1551285089",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Good Time (2017)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/goodtime036.jpg?bwg=1551285089",
  },
  {
    title: "Still from Good Time (2017)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/goodtime038.jpg?bwg=15512850899",
  },
  {
    title: "Still from Good Time (2017)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/goodtime042.jpg?bwg=1551285089",
  },
  {
    title: "Still from Good Time (2017)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/goodtime054.jpg?bwg=1551285089",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/01%20(628).jpg?bwg=1547229474",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/24%20(642).jpg?bwg=1547229474",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/26%20(642).jpg?bwg=1547229473",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/32%20(639).jpg?bwg=15472294733",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/33%20(639).jpg?bwg=1547229473",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/34%20(639).jpg?bwg=1547229473",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/36%20(638).jpg?bwg=1547229473",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/37%20(638).jpg?bwg=1547229473",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/38%20(638).jpg?bwg=1547229473",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/43%20(630).jpg?bwg=1547229473",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/45%20(628).jpg?bwg=15472294733",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/47%20(620).jpg?bwg=1547229473",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/49%20(618).jpg?bwg=1547229473",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/53%20(603).jpg?bwg=1547229473",
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/55%20(595).jpg?bwg=1547229473",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Lost Highway (1997)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/64%20(474).jpg?bwg=1547229473",
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_001.jpg?bwg=1660726910",
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_004.jpg?bwg=16607269100",
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_005.jpg?bwg=1660726910",
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_008.jpg?bwg=1660726910",
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_011.jpg?bwg=1660726910",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_012.jpg?bwg=1660726910",
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_013.jpg?bwg=1660726910",
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_016.jpg?bwg=1660726910",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_027.jpg?bwg=1660726910",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_028.jpg?bwg=1660726910",
  },
  {
    title: "Still from Sans Soleil (1983)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/Sans_Soleil_030.jpg?bwg=1660726910",
  },
  {
    title: "Still from The Shining (1980)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/01%20(1165).jpg?bwg=1547470755",
  },
  {
    title: "Still from The Shining (1980)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/04%20(1164).jpg?bwg=1547470755",
  },
  {
    title: "Still from The Shining (1980)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/18%20(1188).jpg?bwg=1547470754",
  },
  {
    title: "Still from The Shining (1980)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/36%20(1183).jpg?bwg=1547470754",
  },
  {
    title: "Still from The Shining (1980)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/43%20(1170).jpg?bwg=1547470754",
  },
  {
    title: "Still from The Shining (1980)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/48%20(1149).jpg?bwg=1547470754",
  },
  {
    title: "Still from The Shining (1980)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/54%20(1107).jpg?bwg=1547470754",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/01%20(978).jpg?bwg=1547412935",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/02%20(976).jpg?bwg=1547412934",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/06%20(977).jpg?bwg=1547412935",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/12%20(1000).jpg?bwg=1547412934",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/15%20(1000).jpg?bwg=1547412934",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/24%20(1000).jpg?bwg=1547412935",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/31%20(994).jpg?bwg=1547412934",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/32%20(997).jpg?bwg=1547412934",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/33%20(997).jpg?bwg=1547412934",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/38%20(996).jpg?bwg=1547412934",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/41%20(990).jpg?bwg=1547412934",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/45%20(982).jpg?bwg=1547412934",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/49%20(967).jpg?bwg=1547412934",
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/60%20(880).jpg?bwg=1547412934",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/61%20(850).jpg?bwg=1547412934",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/62%20(816).jpg?bwg=1547412934",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Taxi Driver (1976)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/63%20(772).jpg?bwg=1547412934",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/01%20(943).jpg?bwg=1547407306",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/02%20(941).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/09%20(940).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/11%20(964).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/14%20(964).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/15%20(964).jpg?bwg=1547407306",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/16%20(964).jpg?bwg=1547407306",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/17%20(963).jpg?bwg=1547407306",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/23%20(964).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/25%20(964).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/26%20(964).jpg?bwg=1547407304",
  },
  // {
  //   title: "Still from Spirit Of The Beehive (1973)",
  //   src: "https://film-grab.com/wp-content/uploads/photo-gallery/27%20(964).jpg?bwg=1547407306",
  //   songTags: [SONGS.halo],
  // },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/28%20(963).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/29%20(963).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/31%20(958).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/35%20(961).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/36%20(960).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/37%20(960).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/38%20(960).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/39%20(959).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/40%20(958).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/41%20(954).jpg?bwg=1547407304",
  },
  // {
  //   title: "Still from Spirit Of The Beehive (1973)",
  //   src: "https://film-grab.com/wp-content/uploads/photo-gallery/42%20(952).jpg?bwg=1547407304",
  // },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/43%20(949).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/44%20(948).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/46%20(942).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/48%20(932).jpg?bwg=1547407304",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/49%20(931).jpg?bwg=1547407304",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/50%20(924).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/51%20(920).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/56%20(877).jpg?bwg=1547407304",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/57%20(875).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/58%20(864).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/61%20(818).jpg?bwg=1547407304",
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/64%20(712).jpg?bwg=1547407304",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Spirit Of The Beehive (1973)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/65%20(648).jpg?bwg=1547407304",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/01%20(251).jpg?bwg=1547205221",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/07%20(251).jpg?bwg=1547205221",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/09%20(251).jpg?bwg=1547205222",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/12%20(255).jpg?bwg=1547205222",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/13%20(255).jpg?bwg=1547205222",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/16%20(255).jpg?bwg=1547205222",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/17%20(255).jpg?bwg=1547205222",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/18%20(255).jpg?bwg=1547205222",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/26%20(255).jpg?bwg=1547205221",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/27%20(255).jpg?bwg=1547205222",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/30%20(255).jpg?bwg=1547205221",
    songTags: [SONGS.imNotSure, SONGS.neverBeSorry],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/32%20(254).jpg?bwg=1547205221",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/48%20(245).jpg?bwg=1547205221",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/52%20(241).jpg?bwg=1547205221",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/53%20(238).jpg?bwg=1547205221",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/54%20(235).jpg?bwg=1547205221",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/55%20(234).jpg?bwg=1547205221",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/56%20(230).jpg?bwg=1547205221",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/59%20(226).jpg?bwg=1547205221",
  },
  {
    title: "Still from Close Encounters of the Third Kind (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/60%20(224).jpg?bwg=1547205221",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction.jpg",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction2.jpg",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction3.jpg",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction4.jpg",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction5.jpg",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction6.jpg",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction7.jpg",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction8.jpg",
  },
  {
    title: "Still from Pulp Fiction (1994)",
    src: "/images/pulpfiction9.jpg",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/01%20(61).jpg?bwg=1547143600",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/02%20(62).jpg?bwg=1547143600",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/06%20(62).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/07%20(62).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/09%20(62).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/16%20(63).jpg?bwg=1547143600",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/17%20(63).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/19%20(63).jpg?bwg=1547143600",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/22%20(63).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/24%20(63).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/25%20(63).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/27%20(63).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/29%20(63).jpg?bwg=1547143600",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/33%20(62).jpg?bwg=1547143600",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/36%20(62).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/38%20(62).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/41%20(62).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/42%20(62).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/45%20(61).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/47%20(59).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/48%20(59).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/49%20(59).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/50%20(59).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/52%20(59).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/63%20(45).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/64%20(43).jpg?bwg=1547143599",
  },
  {
    title: "Still from Amélie (2001)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/65%20(40).jpg?bwg=1547143599",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/04%20(1).jpg?bwg=1547047415",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/05%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/08%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/14%20(1).jpg?bwg=1547047415",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/15%20(1).jpg?bwg=1547047415",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/16%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/17%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/18%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/19%20(1).jpg?bwg=1547047415",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/21%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/22%20(1).jpg?bwg=1547047415",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/23%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/25%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/30%20(1).jpg?bwg=1547047414",
    songtags: [SONGS.imNotSure],
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/32%20(1).jpg?bwg=1547047415",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/34%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/36%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/37%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/38%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/39%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/40%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/41%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/42%20(1).jpg?bwg=1547047414",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/45%20(1).jpg?bwg=1547047414",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/46%20(1).jpg?bwg=1547047414",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/47%20(1).jpg?bwg=1547047414",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/48%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/50%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/52%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/56%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/58%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/59%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/63%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/64%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Still from 3 Women (1977)",
    src: "https://film-grab.com/wp-content/uploads/photo-gallery/65%20(1).jpg?bwg=1547047414",
  },
  {
    title: "Princess Diana in 1981",
    src: "https://media.vogue.fr/photos/5c3613665dbd058d416e2d83/2:3/w_2560%2Cc_limit/la_princesse_diana_endormie_au_victoria_and_albert_museum_en_1981_4618.jpeg?lang=eng",
    songTags: [SONGS.halo],
  },
  {
    title: "Mumtaz, Prem Kahani, 1975",
    src: "https://m.media-amazon.com/images/M/MV5BZGNhODQzNmYtZGE1OC00ZmM1LWJhMWItYjQ4NTI2YmI1YjUyXkEyXkFqcGdeQXVyMTU1OTgyNDEz._V1_.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Sharon Stone in Casino, 1995",
    src: "https://www.indiewire.com/wp-content/uploads/2023/03/Sharon-Stone-in-22Casino22.jpg",
  },
  {
    title: "Aaliyah in Queen of the Damned, 2002",
    src: "/images/aaliyah5.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Aaliyah",
    src: "/images/aaliyah6.jpeg",
  },
  {
    title: "Aaliyah in 2001",
    src: "/images/aaliyah7.jpeg",
  },
  {
    title: "Aaliyah in 2001",
    src: "/images/aaliyah8.jpeg",
  },
  {
    title: "Aaliyah at Summer Jam Concert in NYC, 1997",
    src: "/images/aaliyah9.jpeg",
  },
  {
    title: "Aaliyah in 2000",
    src: "/images/aaliyah10.jpeg",
  },
  {
    title: "Aaliyah in 2000",
    src: "/images/aaliyah11.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Aaliyah in 1997",
    src: "/images/aaliyah12.jpeg",
  },
  {
    title: "Aaliyah in 1997",
    src: "/images/aaliyah13.jpeg",
  },
  {
    title: "Aaliyah in 1997",
    src: "/images/aaliyah14.jpeg",
  },
  {
    title: "Aaliyah",
    src: "/images/aaliyah15.jpeg",
  },
  // {
  //   title: "Aaliyah",
  //   src: "https://scontent-lhr8-1.cdninstagram.com/v/t51.29350-15/400279805_1057643295366680_4377022076493703183_n.jpg?stp=dst-jpg_e35_p1080x1080&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyLmYyOTM1MCJ9&_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=108&_nc_ohc=XrC1r9ztf7QQ7kNvgGwynUf&edm=ANTKIIoBAAAA&ccb=7-5&oh=00_AYA9yU347zzqFw3ScsdMU1-6tfkwkmYIEng3wKHxQikxGA&oe=667DE6D7&_nc_sid=cf751b",
  // },
  {
    title: "Aaliyah, look for Try Again music video",
    src: "/images/aaliyah1.jpeg",
    // songTags: [SONGS.immaculate],
  },
  {
    title: "Adriana Sklenarikova-Karembeu for Torrente Haute Couture S/S 1998",
    src: "/images/fashion4.jpg",
    // songTags: [SONGS.halo],
  },
  {
    title: "Gisele Bündchen",
    src: "/images/gisele1.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Gisele Bündchen",
    src: "/images/gisele2.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Gisele Bündchen",
    src: "/images/gisele5.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Gisele Bündchen for Versace, S/S 1999",
    src: "/images/gisele3.jpeg",
  },
  {
    title: "Gisele Bündchen for Versace, S/S 1999",
    src: "/images/gisele4.jpeg",
  },
  {
    title: "Still from The Little Mermaid (1976)",
    src: "/images/mermaid3.jpg",
    // songTags: [SONGS.halo],
  },
  // {
  //   title: 'Still from The Little Mermaid (1976)',
  //   src: 'https://i.pinimg.com/736x/d2/e8/33/d2e833283275f580384d75448b7b1292--fairy-hair-the-little-mermaid.jpg',
  //   songTags: [SONGS.halo],
  // },
  // {
  //   title: 'Still from The Little Mermaid (1976)',
  //   src: 'https://m.media-amazon.com/images/M/MV5BZDE2YzQyZmQtYzQwNS00NmEwLTg4NTctNzM2MGVmMTlmYTA2XkEyXkFqcGdeQXVyNjg3MTIwODI@._V1_.jpg',
  //   songTags: [SONGS.halo],
  // },
  {
    title: "Still from The Little Mermaid (1976)",
    src: "/images/mermaid1.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Little Mermaid (1976)",
    src: "/images/mermaidgif1.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Little Mermaid (1976)",
    src: "/images/mermaid2.jpg",
    songTags: [SONGS.halo],
  },
  // {
  //   title: 'Still from The Little Mermaid (1976)',
  //   src: 'https://pbs.twimg.com/media/FCrSvs7WUAUx6ka.jpg',
  //   songTags: [SONGS.halo],
  // },
  {
    title: "The Little Mermaid (1976)",
    src: "/images/mermaidgif2.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "The Little Mermaid (1976)",
    src: "/images/mermaidgif3.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "The Little Mermaid (1976)",
    src: "/images/mermaidgif4.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Little Mermaid (1976)",
    src: "/images/mermaid2.jpeg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "The Little Mermaid (1976)",
    src: "/images/mermaidgif5.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "The Little Mermaid (1976)",
    src: "/images/mermaidgif6.gif",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "The Little Mermaid (1976)",
    src: "/images/mermaidgif7.gif",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Head of girl with long blonde hair (1923) - Wladyslaw T. Benda",
    src: "https://tile.loc.gov/storage-services/service/pnp/ppmsca/01500/01585v.jpg#h=1024&w=1001",
    songTags: [SONGS.halo],
  },
  {
    title: "Jerry Hall by Antonio Lopez, 1974",
    src: "/images/jerryhall1.jpeg",
  },
  {
    title: "Jerry Hall in NYC, 1977",
    src: "/images/jerryhall2.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Jerry Hall, 1975",
    src: "/images/jerryhall3.jpeg",
  },
  {
    title: "Jerry Hall, 1978",
    src: "/images/jerryhall4.jpeg",
  },
  { title: "Angelina Jolie", src: "/images/angelina1.webp" },
  { title: "Angelina Jolie", src: "/images/angelina2.jpeg" },
  { title: "Angelina Jolie", src: "/images/angelina3.jpeg" },
  {
    title: "Angelina Jolia in Gia (1998)",
    src: "/images/angelina4.jpeg",
  },
  {
    title: "Lana del Rey",
    src: "/images/lana1.jpeg",
  },
  {
    title: "Lana del Rey",
    src: "/images/lana2.jpeg",
  },
  {
    title: "Lana del Rey",
    src: "/images/lana3.jpeg",
  },
  {
    title: "Lana del Rey",
    src: "/images/lana4.jpeg",
  },
  {
    title: "Lana del Rey",
    src: "/images/lana6.jpeg",
  },
  {
    title: "Lana del Rey in 2012",
    src: "/images/lana6.png",
  },
  {
    title: "Lana del Rey in 2012",
    src: "/images/lana7.jpeg",
  },
  {
    title: "Lana del Rey",
    src: "/images/lana8.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Lana del Rey",
    src: "/images/lana9.jpeg",
  },
  {
    title: "Lana del Rey",
    src: "/images/lana10.png",
  },
  {
    title: "Lana del Rey in 2012",
    src: "/images/lana132012.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Lana del Rey in 2012",
    src: "/images/lana142012.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Versace April 1994",
    src: "/images/versace1994.jpeg",
  },
  {
    title: "Still from Jak se budí princezny (1977)",
    src: "/images/jaksebudiprincezny.png",
  },
  {
    title: "Still from Jak se budí princezny (1977)",
    src: "/images/jaksebudiprincezny2.png",
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders2.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders4.jpg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders6.jpg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders7.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders8.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders10.jpg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders12.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwonders13.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwondersgif1.gif",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwondersgif2.gif",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwondersgif4.webp",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Still from Valerie and Her Week of Wonders (Valerie a týden divů) (1970)",
    src: "/images/valerieweekofwondersgif5.webp",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from 9½ Weeks (1986)",
    src: "/images/912weeks1.jpeg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from 9½ Weeks (1986)",
    src: "/images/912weeks2.jpeg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Clip from My Own Summer (Shove It) (1997) - Deftones music video",
    src: "/images/deftonesgif1.gif",
  },
  {
    title: "Kurt Cobain",
    src: "/images/kurtgif1.gif",
  },
  {
    title: "Patricia Arquette in Lost Highway (1997)",
    src: "/images/losthighway1.jpeg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Patricia Arquette in Lost Highway (1997)",
    src: "/images/losthighway2.jpeg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Patricia Arquette in Lost Highway (1997)",
    src: "/images/losthighway3.jpeg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Patricia Arquette in Lost Highway (1997)",
    src: "/images/losthighwaygif1.gif",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Kirsten Dunst behind the scenes of Marie Antoinette (2006)",
    src: "/images/marieantoinette20061.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Kirsten Dunst behind the scenes of Marie Antoinette (2006)",
    src: "/images/marieantoinette20063.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Marie Antoinette (2006)",
    src: "/images/marieantoinette20064.jpeg",
  },
  {
    title: "Still from Marie Antoinette (2006)",
    src: "/images/marieantoinette20065.jpeg",
  },
  {
    title: "Still from Marie Antoinette (2006)",
    src: "/images/marieantoinette20067.webp",
    songTags: [SONGS.halo],
  },
  {
    title: "Clip from Only Shallow (1991) - My Bloody Valentine music video",
    src: "/images/mbvgif1.gif",
  },
  {
    title: "Clip from Only Shallow (1991) - My Bloody Valentine music video",
    src: "/images/mbvgif2.gif",
  },
  {
    title: "Clip from Only Shallow (1991) - My Bloody Valentine music video",
    src: "/images/mbvgif3.gif",
  },
  {
    title: "Nirvana",
    src: "/images/nirvanagif.gif",
  },
  {
    title: "PJ Harvey on tour circa 2000",
    src: "/images/pjharveygif1.gif",
  },
  {
    title: "PJ Harvey on tour circa 2000",
    src: "/images/pjharveygif2.gif",
  },
  {
    title: "Lana del Rey for Flaunt magazine, 2017",
    src: "/images/lanaflauntmag2017.jpeg",
  },
  {
    title: "Lana del Rey for Flaunt magazine 2017, behind the scenes",
    src: "/images/lanaflauntmag2017bts.webp",
    songTags: [SONGS.halo],
  },
  {
    title: "Natalie Wood holding her daughter Natasha, 1971",
    src: "/images/nataliewooddaught1971.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Natalie Wood",
    src: "/images/nataliewood.jpg",
  },
  {
    title: "Natalie Wood",
    src: "/images/nataliewood2.jpeg",
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskin1970.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskin19702.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskin19703.webp",
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskin19704.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskin19706.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskin19707.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskingif1.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskingif2.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskingif3.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Donkey Skin (Peau d'âne) (1970)",
    src: "/images/donkeyskin19708.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Nicole Kidman in 1996",
    src: "/images/nicole1996.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Nicole Kidman in 1996",
    src: "/images/nicole19962.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Shelley Duvall in Brewster McCloud (1970)",
    src: "/images/shelley1.jpeg",
  },
  {
    title: "Still from The Holy Mountain (La montaña sagrada) (1973)",
    src: "/images/holymountain.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Holy Mountain (La montaña sagrada) (1973)",
    src: "/images/holymountain1.jpg",
  },
  {
    title: "Still from The Holy Mountain (La montaña sagrada) (1973)",
    src: "/images/holymountain2.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Holy Mountain (La montaña sagrada) (1973)",
    src: "/images/holymountain4.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Shelley Duvall in Nashville (1975)",
    src: "/images/shelleyduvallnashville1975.jpeg",
  },
  {
    title: "Used lipsticks by Stacy Greene, 1991 - 1993",
    src: "/images/usedlipsticksstacygreene9193.jpeg",
  },
  {
    title: "Sofia Vergara in the 90s",
    src: "/images/sofiavergara.png",
    songTags: [SONGS.halo],
  },
  {
    title: "Leighton Meester by Sofia Coppola, 2000",
    src: "/images/leightonmeester2000.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Behind the scenes of The Virgin Suicides (1999)",
    src: "/images/virginsuicides.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Behind the scenes of The Virgin Suicides (1999)",
    src: "/images/virginsuicides2.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Mariah Carey",
    src: "/images/mariah.webp",
    songTags: [SONGS.halo],
  },
  {
    title: "Nicole Kidman",
    src: "/images/nicole4.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Teresa Graves, 1974",
    src: "/images/teresagif1.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "Teresa Graves, 1974",
    src: "/images/teresagif2.gif",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Last Unicorn (1982)",
    src: "/images/unicorn1.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Last Unicorn (1982)",
    src: "/images/unicorn2.webp",
    songTags: [SONGS.halo],
  },
  {
    title: "The Last Unicorn (1982)",
    src: "/images/unicorngif1.gif",
    songTags: [SONGS.halo],
  },
  {
    title: "Carrie (1976)",
    src: "/images/carrie1976.png",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Female Vampire (1973)",
    src: "/images/femalevampire.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Female Vampire (1973)",
    src: "/images/femalevampire2.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Female Vampire (1973)",
    src: "/images/femalevampire3.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Female Vampire (1973)",
    src: "/images/femalevampire4.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from La Morte Vivante (1982)",
    src: "/images/lamortevivante1982.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from La Morte Vivante (1982)",
    src: "/images/lamortevivante19822.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from La Morte Vivante (1982)",
    src: "/images/lamortevivante19823.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from La Morte Vivante (1982)",
    src: "/images/lamortevivante19824.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from La Morte Vivante (1982)",
    src: "/images/lamortevivante19825.jpeg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "La Morte Vivante (1982)",
    src: "/images/lamortevivantegif.gif",
    songTags: [SONGS.immaculate],
  },
  {
    title: "The Ronnettes",
    src: "/images/ronnettes1.jpeg",
  },
  {
    title: "The Ronnettes",
    src: "/images/ronnettes2.jpeg",
  },
  {
    title: "The Ronnettes",
    src: "/images/ronnettes3.jpeg",
  },
  {
    title: "Fallstreak hole over Austria",
    src: "/images/fallstreakholeoveraustria.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Fallstreak hole over Australia",
    src: "/images/fallstreakholeoveraustralia.jpeg",
    songTags: [SONGS.halo],
  },
  {
    title: "Iris (1886) - John Atkinson Grimshaw",
    src: "/images/johnatkinsongrimshaw.jpeg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Iris or A Midsummer Night's Dream - John Atkinson Grimshaw",
    src: "/images/johnatkinsongrimshaw2.jpeg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Ammonite fossil",
    src: "/images/ammonitefossil.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Automne (1903) - Albert Joseph Pénot",
    src: "/images/albertjosephpenot.jpeg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "",
    src: "/images/seagif1.gif",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "",
    src: "/images/seagif2.gif",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Janet Jackson for Tu-Ka mobile, 90s",
    src: "/images/janetad90s.jpg",
  },
  {
    title: "Janet Jackson for Tu-Ka mobile, 90s",
    src: "/images/janetad90s2.png",
  },
  {
    title: "Janet Jackson for Tu-Ka mobile, 90s",
    src: "/images/janetad90s3.png",
  },
  {
    title: "Janet Jackson for Tu-Ka mobile, 90s",
    src: "/images/janetad90s4.png",
  },
  {
    title: "Janet Jackson for Tu-Ka mobile, 90s",
    src: "/images/janetad90s5.png",
  },
  {
    title: "Madonna by Kenji Wakasugi, 1985",
    src: "/images/madonna3.jpg",
  },
  {
    title: "Madonna by Kenji Wakasugi, 1985",
    src: "/images/madonna4.jpg",
  },
  {
    title: "Madonna by Kenji Wakasugi, 1985",
    src: "/images/madonna1985.jpg",
  },
  {
    title: "Madonna by Kenji Wakasugi, 1985",
    src: "/images/madonna19852.jpg",
  },
  {
    title: "Jennifer Connelly in a Panasonic ad, 1986",
    src: "/images/jenniferconnelly1986.jpg",
  },
  {
    title: "Jennifer Connelly in a Panasonic ad, 1986",
    src: "/images/jenniferconnelly19862.jpg",
  },
  {
    title: "Jennifer Connelly in a Panasonic ad, 1986",
    src: "/images/jenniferconnelly19863.jpg",
  },
  {
    title: "Jennifer Connelley in a Perky Jean ad, 1986",
    src: "/images/jenniferconnelly19865.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house2.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house3.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house4.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house5.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house6.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house7.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house8.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house9.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house10.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house11.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house13.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house14.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house15.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house16.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house17.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house18.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house19.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house20.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house21.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house22.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house23.jpg",
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house24.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house25.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house26.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house27.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from House (Hausu) (1977)",
    src: "/images/house28.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "80s Japanese fashion",
    src: "/images/japanfashion80s.jpg",
  },
  {
    title: "Still from The Thing (1982)",
    src: "/images/thething.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from The Thing (1982)",
    src: "/images/thething2.jpg",
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows.jpg",
    songTags: [SONGS.myAngel, SONGS.imNotSure],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows2.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows3.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows4.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows5.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows6.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows7.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows8.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows9.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows10.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from It Follows (2014)",
    src: "/images/itfollows11.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Images (1972)",
    src: "/images/images.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Images (1972)",
    src: "/images/images2.jpg",
  },
  {
    title: "Still from Images (1972)",
    src: "/images/images3.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Images (1972)",
    src: "/images/images4.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Images (1972)",
    src: "/images/images5.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Images (1972)",
    src: "/images/images6.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Aliens (1986)",
    src: "/images/aliens.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Aliens (1986)",
    src: "/images/aliens2.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Aliens (1986)",
    src: "/images/aliens3.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Aliens (1986)",
    src: "/images/aliens4.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Aliens (1986)",
    src: "/images/aliens5.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Repulsion (1965)",
    src: "/images/repulsion.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Repulsion (1965)",
    src: "/images/repulsion2.jpg",
  },
  {
    title: "Still from Repulsion (1965)",
    src: "/images/repulsion3.jpg",
  },
  {
    title: "Still from Repulsion (1965)",
    src: "/images/repulsion4.jpg",
  },
  {
    title: "Still from Repulsion (1965)",
    src: "/images/repulsion5.jpg",
  },
  {
    title: "Still from Repulsion (1965)",
    src: "/images/repulsion6.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Repulsion (1965)",
    src: "/images/repulsion7.jpg",
  },
  {
    title: "Still from Repulsion (1965)",
    src: "/images/repulsion8.jpg",
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock2.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock3.jpg",
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock4.jpg",
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock5.jpg",
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock6.jpg",
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock7.jpg",
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock8.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock9.jpg",
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock10.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock11.jpg",
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock12.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Picnic at Hanging Rock (1975)",
    src: "/images/picnicathangingrock13.jpg",
  },
  {
    title: "Still from Videodrome (1983)",
    src: "/images/videodrome.jpg",
  },
  {
    title: "Still from Videodrome (1983)",
    src: "/images/videodrome2.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Videodrome (1983)",
    src: "/images/videodrome3.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Videodrome (1983)",
    src: "/images/videodrome4.jpg",
    songTags: [SONGS.myAngel, SONGS.immaculate],
  },
  {
    title: "Still from Videodrome (1983)",
    src: "/images/videodrome5.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Videodrome (1983)",
    src: "/images/videodrome6.jpg",
  },
  {
    title: "Still from Videodrome (1983)",
    src: "/images/videodrome7.jpg",
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession2.jpg",
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession3.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession4.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession5.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession6.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession7.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession8.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession9.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Possession (1981)",
    src: "/images/possession10.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof.jpg",
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof2.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof3.jpg",
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof4.jpg",
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof5.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof6.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof7.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof8.jpg",
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof9.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof10.jpg",
  },
  {
    title: "Still from Death Proof (2007)",
    src: "/images/deathproof11.jpg",
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie.jpg",
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie2.jpg",
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie3.jpg",
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie4.jpg",
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie5.jpg",
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie6.jpg",
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie7.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie8.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie9.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie10.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Carrie (1976)",
    src: "/images/carrie11.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Ring (2002)",
    src: "/images/thering.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Witch (2016)",
    src: "/images/thewitch.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Witch (2016)",
    src: "/images/thewitch2.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Witch (2016)",
    src: "/images/thewitch3.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos2.jpg",
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos3.jpg",
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos4.jpg",
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos5.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos6.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos7.jpg",
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos8.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos9.jpg",
  },
  {
    title: "Still from Vampyros Lesbos (1976)",
    src: "/images/vampyroslesbos10.jpg",
  },
  {
    title: "Still from The Company of Wolves (1984)",
    src: "/images/companyofwolves.jpg",
  },
  {
    title: "Still from The Company of Wolves (1984)",
    src: "/images/companyofwolves2.jpg",
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne.jpg",
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne2.jpg",
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne3.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne4.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne5.jpg",
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne6.jpg",
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne7.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne8.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne9.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Strange Case of Dr. Jekyll and Miss Osbourne (1981)",
    src: "/images/drjekyllmissosbourne10.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Raw (2016)",
    src: "/images/raw.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Raw (2016)",
    src: "/images/raw2.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Angel Heart (1987)",
    src: "/images/angelheart.jpg",
  },
  {
    title: "Still from Angel Heart (1987)",
    src: "/images/angelheart2.jpg",
  },
  {
    title: "Still from Angel Heart (1987)",
    src: "/images/angelheart3.jpg",
  },
  {
    title: "Still from Angel Heart (1987)",
    src: "/images/angelheart4.jpg",
  },
  {
    title: "Still from Angel Heart (1987)",
    src: "/images/angelheart5.jpg",
  },
  {
    title: "Still from Angel Heart (1987)",
    src: "/images/angelheart6.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Angel Heart (1987)",
    src: "/images/angelheart7.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Angel Heart (1987)",
    src: "/images/angelheart8.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch2.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch3.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch4.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch5.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch6.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch7.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch8.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch9.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch10.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch11.jpg",
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch12.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch13.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch14.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch15.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch16.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch17.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch18.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch19.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Love Witch (2016)",
    src: "/images/lovewitch20.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Ganja & Hess (1973)",
    src: "/images/ganjaandhess.png",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Ganja & Hess (1973)",
    src: "/images/ganjaandhess2.png",
  },
  {
    title: "Still from Ganja & Hess (1973)",
    src: "/images/ganjaandhess3.png",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Ganja & Hess (1973)",
    src: "/images/ganjaandhess4.png",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Ganja & Hess (1973)",
    src: "/images/ganjaandhess5.png",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Ganja & Hess (1973)",
    src: "/images/ganjaandhess6.png",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar2.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar3.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar4.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar5.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar6.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar7.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar8.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar9.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar10.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar11.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar12.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar13.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar14.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar15.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar16.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar17.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar18.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar19.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar20.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar21.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar22.jpg",
  },
  {
    title: "Still from Morvern Callar (2002)",
    src: "/images/morverncallar23.jpg",
  },
  {
    title: "Still from Marnie (1964)",
    src: "/images/marnie.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Marnie (1964)",
    src: "/images/marnie2.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Marnie (1964)",
    src: "/images/marnie3.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue2.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue3.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue4.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue5.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue6.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue7.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue8.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue9.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue10.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue11.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue12.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue13.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue14.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue15.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue16.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue17.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue18.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue19.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue20.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue21.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue22.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue23.jpg",
  },
  {
    title: "Still from Perfect Blue (1997)",
    src: "/images/perfectblue24.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights.jpg",
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights2.jpg",
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights3.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights4.jpg",
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights5.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights6.jpg",
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights7.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights8.jpg",
  },
  {
    title: "Still from Boogie Nights (1997)",
    src: "/images/boogienights9.jpg",
  },
  {
    title: "Still from Gattaca (1997)",
    src: "/images/gattaca.jpg",
  },
  {
    title: "Still from Gattaca (1997)",
    src: "/images/gattaca2.jpg",
  },
  {
    title: "Still from Gattaca (1997)",
    src: "/images/gattaca3.jpg",
  },
  {
    title: "Still from Gattaca (1997)",
    src: "/images/gattaca4.jpg",
  },
  {
    title: "Still from Gattaca (1997)",
    src: "/images/gattaca5.jpg",
  },
  {
    title: "Still from Gattaca (1997)",
    src: "/images/gattaca6.jpg",
  },
  {
    title: "Still from Gattaca (1997)",
    src: "/images/gattaca7.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Gattaca (1997)",
    src: "/images/gattaca8.jpg",
  },
  {
    title: "Still from Dazed and Confused (1993)",
    src: "/images/dazedandconfused.png",
  },
  {
    title: "Still from Dazed and Confused (1993)",
    src: "/images/dazedandconfused2.jpeg",
  },
  {
    title: "Still from Dazed and Confused (1993)",
    src: "/images/dazedandconfused3.jpg",
  },
  {
    title: "Still from Dazed and Confused (1993)",
    src: "/images/dazedandconfused4.jpeg",
  },
  {
    title: "Still from Dazed and Confused (1993)",
    src: "/images/dazedandconfused5.jpg",
  },
  {
    title: "Still from Dazed and Confused (1993)",
    src: "/images/dazedandconfused6.jpg",
  },
  {
    title: "Still from Dazed and Confused (1993)",
    src: "/images/dazedandconfused7.jpg",
  },
  {
    title: "Still from Dazed and Confused (1993)",
    src: "/images/dazedandconfused8.jpg",
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader.jpg",
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader2.jpg",
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader3.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader4.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader5.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader6.jpg",
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader7.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader8.jpg",
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader9.jpg",
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader10.jpg",
  },
  {
    title: "Still from But I’m A Cheerleader (1999)",
    src: "/images/butimacheerleader11.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley2.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley3.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley4.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley5.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley6.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley7.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley8.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley9.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley10.jpg",
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley11.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley12.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from The Talented Mr. Ripley (1999)",
    src: "/images/talentedmrripley13.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly2.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly3.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly4.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly5.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly6.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly7.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly8.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly9.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly10.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly11.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly12.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly13.jpg",
  },
  {
    title: "Still from Belly (1998)",
    src: "/images/belly14.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls2.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls3.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls4.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls5.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls6.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls7.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls8.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls9.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls10.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls11.jpg",
  },
  {
    title: "Still from Showgirls (1995)",
    src: "/images/showgirls12.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown2.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown3.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown4.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown5.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown6.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown7.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown8.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown9.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown10.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown11.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown12.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown13.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown14.jpg",
  },
  {
    title: "Still from Women on the Verge of a Nervous Breakdown (1988)",
    src: "/images/womenonthevergeofanervousbreakdown15.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers2.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers3.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers4.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers5.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers6.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers7.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers8.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers9.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers10.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers11.jpg",
  },
  {
    title: "Still from Natural Born Killers (1994)",
    src: "/images/naturalbornkillers12.jpg",
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures2.jpg",
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures3.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures4.jpg",
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures5.jpg",
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures6.jpg",
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures7.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures8.jpg",
  },
  {
    title: "Still from Heavenly Creatures (1994)",
    src: "/images/heavenlycreatures9.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels2.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels3.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels4.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels5.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels6.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels7.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels8.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels9.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels10.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels11.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels12.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels13.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels14.jpg",
  },
  {
    title: "Still from Fallen Angels (1995)",
    src: "/images/fallenangels15.jpg",
  },
  {
    title: "Still from Three Colours: White (1994)",
    src: "/images/threecolourswhite.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Three Colours: White (1994)",
    src: "/images/threecolourswhite2.jpg",
  },
  {
    title: "Still from Three Colours: White (1994)",
    src: "/images/threecolourswhite3.jpg",
  },
  {
    title: "Still from Three Colours: White (1994)",
    src: "/images/threecolourswhite4.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Three Colours: White (1994)",
    src: "/images/threecolourswhite5.jpg",
  },
  {
    title: "Still from Three Colours: White (1994)",
    src: "/images/threecolourswhite6.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Three Colours: Blue (1993)",
    src: "/images/threecoloursblue.jpg",
  },
  {
    title: "Still from Three Colours: Blue (1993)",
    src: "/images/threecoloursblue2.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Three Colours: Blue (1993)",
    src: "/images/threecoloursblue3.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Three Colours: Blue (1993)",
    src: "/images/threecoloursblue4.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Three Colours: Blue (1993)",
    src: "/images/threecoloursblue5.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from Three Colours: Blue (1993)",
    src: "/images/threecoloursblue6.jpg",
  },
  {
    title: "Still from Three Colours: Blue (1993)",
    src: "/images/threecoloursblue7.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor2.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor3.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor4.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor5.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor6.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor7.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor8.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor9.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor10.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor11.jpg",
  },
  {
    title: "Still from To Die For (1995)",
    src: "/images/todiefor12.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance.jpg",
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance2.jpg",
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance3.jpg",
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance4.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance5.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance6.jpg",
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance7.jpg",
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance8.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance9.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance10.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance11.jpg",
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance12.jpg",
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance13.jpg",
  },
  {
    title: "Still from True Romance (1993)",
    src: "/images/trueromance14.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme2.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme3.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme4.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme5.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme6.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme7.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme8.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme9.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme10.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme11.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme12.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme13.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme14.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme15.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme16.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme17.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme18.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme19.jpg",
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme20.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Twin Peaks: Fire Walk With Me (1992)",
    src: "/images/twinpeaksfirewalkwithme21.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides2.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides3.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides4.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides5.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides6.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides7.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides8.jpg",
    songTags: [SONGS.halo],
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides9.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides10.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides11.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides12.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides13.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides14.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides15.jpg",
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides16.jpg",
    songTags: [SONGS.imNotSure],
  },
  {
    title: "Still from The Virgin Suicides (1999)",
    src: "/images/virginsuicides17.jpg",
  },
  {
    title: "Still from Wild At Heart (1990)",
    src: "/images/wildatheart.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Wild At Heart (1990)",
    src: "/images/wildatheart2.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Wild At Heart (1990)",
    src: "/images/wildatheart3.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Wild At Heart (1990)",
    src: "/images/wildatheart4.jpg",
  },
  {
    title: "Still from Wild At Heart (1990)",
    src: "/images/wildatheart5.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title: "Still from Wild At Heart (1990)",
    src: "/images/wildatheart6.jpg",
    songTags: [SONGS.immaculate],
  },
  {
    title: "Still from Wild At Heart (1990)",
    src: "/images/wildatheart7.jpg",
    songTags: [SONGS.neverBeSorry],
  },
  {
    title: "Still from Wild At Heart (1990)",
    src: "/images/wildatheart8.jpg",
    songTags: [SONGS.halo],
  },
  {
    title:
      "Amethyst with Pyrite from Guanajuato, Mexico, currently in British Museum of Natural History",
    src: "/images/pyritequartz.jpg",
  },
  {
    title: "Rose quartz from Minas Gerais, Brazil",
    src: "/images/rosequartz.jpg",
  },
  {
    title: "Quartz Prase from Tuscany, Italy",
    src: "/images/quartzprase.jpg",
  },
  {
    title: "Bibliothèque humaniste de Sélestat, Alsace, France",
    src: "/images/humanistlibrary.jpg",
  },
  {
    title:
      "An illustration for Edgar Allan Poe's story A Descent into the Maelström (1919)",
    src: "/images/maelstrom.jpg",
  },
  {
    title: "The Nightmare (1781), Henry Fuseli",
    src: "/images/thenightmarefuseli.jpg",
    songTags: [SONGS.myAngel],
  },
  {
    title:
      "Illustration from the Nuremberg Chronicle, Hartmann Schedel (1440-1514)",
    src: "/images/danceofdeathnuremberg.jpg",
  },
  {
    title: "Usha's Dream, Raja Ravi Varma (1848 - 1906)",
    src: "/images/ushasdreamvarma.jpg",
  },
  {
    title: "Landscape with the Dream of Jacob, Michael Willmann (1691)",
    src: "/images/dreamofjacobwillmann.jpg",
  },
  {
    title: 'Hupao ("Dreaming of the Tiger"). Sculpture in Hangzhou, China',
    src: "/images/dreamingoftiger.jpg",
  },
  {
    title: "“A dream I had one day” - Yoshimi Sekiguchi",
    src: "/images/yoshimisekiguchi.jpg",
  },
  {
    title: "“A dream I had one day” - Yoshimi Sekiguchi",
    src: "/images/yoshimisekiguchi2.jpg",
  },
  {
    title: "Albino kitten",
    src: "/images/albinocat.jpg",
  },
  {
    title: "Alicia Silvertone as Cher Horowitz from Clueless (1995)",
    src: "/images/alicia.jpg",
  },
  {
    title: "Alicia Silvertone as Cher Horowitz from Clueless (1995)",
    src: "/images/alicia2.jpg",
  },
  {
    title: "Alicia Silvertone as Cher Horowitz from Clueless (1995)",
    src: "/images/alicia3.jpeg",
  },
  {
    title: "Alicia Silvertone as Cher Horowitz from Clueless (1995)",
    src: "/images/alicia4.jpg",
  },
  {
    title: "Front of Cher's Guide to... Whatever (1995)",
    src: "/images/chersguidetowhateverfront.jpg",
  },
  {
    title: "Back of Cher's Guide to... Whatever (1995)",
    src: "/images/chersguidetowhateverback.jpg",
  },
  {
    title: "Juno Temple (2016)",
    src: "/images/junotemple.jpg",
  },
  {
    title: "Faxlore warning about tattoo stickers allegedly laced with drugs",
    src: "/images/mickeyfaxlore.jpg",
  },
  {
    title: "Odd-eyed cat",
    src: "/images/oddeyedcat.jpg",
  },
  {
    title: "Phoebe Cates in 1986",
    src: "/images/phoebecates1986.jpg",
  },
  {
    title: "The cover of La fin du film - Sydne Rome (1976)",
    src: "/images/sydnerome.jpg",
  },
  {
    title: "Twiggy in 1966",
    src: "/images/twiggy.webp",
  },
  {
    title: "Twiggy in 1966",
    src: "/images/twiggy2.webp",
  },
].map((el) => ({ ...el, contentType: "image" }));
