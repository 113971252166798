import { CreativeCommonsImage, Image } from "./interfaces";
import { SONGS } from "../constants";

export const creativeCommonsImages: CreativeCommonsImage[] = [
  // {
  //   title: "Aporia crataegi, photo by Sven Damerow",
  //   src: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Baumwei%C3%9Fling_%28Aporia_crataegi%29.jpg",
  //   link: "https://commons.wikimedia.org/wiki/File:Baumwei%C3%9Fling_(Aporia_crataegi).jpg",
  //   attribution:
  //     '<a href="https://commons.wikimedia.org/wiki/File:Baumwei%C3%9Fling_(Aporia_crataegi).jpg">Sven Damerow</a>, <a href="https://creativecommons.org/attributions/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  // },
  {
    title: "Polyommatus bellargus, photo by David Iliff",
    src: "https://upload.wikimedia.org/wikipedia/commons/3/35/Polyommatus_bellargus_male%2C_Aveyron%2C_France_-_Diliff.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Polyommatus_bellargus_male,_Aveyron,_France_-_Diliff.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Polyommatus_bellargus_male,_Aveyron,_France_-_Diliff.jpg">Diliff</a>, <a href="https://creativecommons.org/attributions/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
    songTags: [SONGS.test],
  },
  {
    title: "Peacock butterfly, photo by Charles J. Sharp",
    src: "https://upload.wikimedia.org/wikipedia/commons/a/a6/Peacock_butterfly_%28Aglais_io%29_2.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Peacock_butterfly_(Aglais_io)_2.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Peacock_butterfly_(Aglais_io)_2.jpg">Charles J. Sharp</a>, <a href="https://creativecommons.org/attributions/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title: "Peacock butterfly, photo by Korall",
    src: "https://upload.wikimedia.org/wikipedia/commons/4/44/Inachis_io_Lill-Jansskogen.JPG",
    link: "https://commons.wikimedia.org/wiki/File:Inachis_io_Lill-Jansskogen.JPG",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Inachis_io_Lill-Jansskogen.JPG">Korall</a>, <a href="https://creativecommons.org/attributions/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title: "Peacock butterfly, photo by Michael Apel",
    src: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Aglais_io_top_MichaD.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Aglais_io_top_MichaD.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Aglais_io_top_MichaD.jpg">I, MichaD</a>, <a href="https://creativecommons.org/attributions/by-sa/2.5">CC BY-SA 2.5</a>, via Wikimedia Commons',
  },
  {
    title: "Peacock butterfly, photo by Luc Viatour",
    src: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Nymphalis_io_Luc_Viatour.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Nymphalis_io_Luc_Viatour.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Nymphalis_io_Luc_Viatour.jpg">I, Luc Viatour</a>, <a href="http://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title: "Brachylophus fasciatus, photo by H. Zell",
    src: "https://upload.wikimedia.org/wikipedia/commons/9/92/Brachylophus_fasciatus_-_Reptilium_Landau.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Brachylophus_fasciatus_-_Reptilium_Landau.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Brachylophus_fasciatus_-_Reptilium_Landau.jpg">H. Zell</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title: "Viperine water snake (Natrix maura), photo by Charles J. Sharp",
    src: "https://upload.wikimedia.org/wikipedia/commons/1/11/Viperine_water_snake_%28Natrix_maura%29.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Viperine_water_snake_(Natrix_maura).jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Viperine_water_snake_(Natrix_maura).jpg">Charles J. Sharp</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Grass snake (Natrix natrix), photo by Andreas Eichler",
    src: "https://upload.wikimedia.org/wikipedia/commons/d/df/2017.07.17.-21-Tiefer_See_oder_Grubensee-Storkow_%28Mark%29--Ringelnatter.jpg",
    link: "https://commons.wikimedia.org/wiki/File:2017.07.17.-21-Tiefer_See_oder_Grubensee-Storkow_(Mark)--Ringelnatter.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:2017.07.17.-21-Tiefer_See_oder_Grubensee-Storkow_(Mark)--Ringelnatter.jpg">Andreas Eichler</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Green Violet-ear",
    src: "https://upload.wikimedia.org/wikipedia/commons/d/db/Colibri-thalassinus-001-edit.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Colibri-thalassinus-001-edit.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Colibri-thalassinus-001-edit.jpg">Mdf</a>, <a href="http://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Lilac-breasted roller (Coracias caudatus caudatus) in Botswana, photo by Charles J. Sharp",
    src: "https://upload.wikimedia.org/wikipedia/commons/0/06/Lilac-breasted_roller_%28Coracias_caudatus_caudatus%29_Botswana.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Lilac-breasted_roller_(Coracias_caudatus_caudatus)_Botswana.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Lilac-breasted_roller_(Coracias_caudatus_caudatus)_Botswana.jpg">Charles J. Sharp</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "European bee-eater, photo by Dûrzan cîrano",
    src: "https://upload.wikimedia.org/wikipedia/commons/e/e7/%C5%9Eahl%C3%BBr-33.jpg",
    link: "https://commons.wikimedia.org/wiki/File:%C5%9Eahl%C3%BBr-33.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:%C5%9Eahl%C3%BBr-33.jpg">Dûrzan cîrano</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title: "Peacock feather close-up",
    src: "https://upload.wikimedia.org/wikipedia/commons/e/e2/Peacock_feather_close-up.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Peacock_feather_close-up.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Peacock_feather_close-up.jpg">Mister rf</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Peacock feather close-up",
    src: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Plumepaon.JPG",
    link: "https://commons.wikimedia.org/wiki/File:Plumepaon.JPG",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Plumepaon.JPG">Jebulon</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Greater flamingos (Phoenicopterus roseus) in Bahrain, photo by Charles J. Sharp",
    src: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Greater_flamingos_%28Phoenicopterus_roseus%29_Bahrain.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Greater_flamingos_(Phoenicopterus_roseus)_Bahrain.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Greater_flamingos_(Phoenicopterus_roseus)_Bahrain.jpg">Charles J. Sharp</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Barn owl (Tyto alba), photo by Carlos Delgado",
    src: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Tyto_alba_-_Cetrer%C3%ADa_-_01.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Tyto_alba_-_Cetrer%C3%ADa_-_01.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Tyto_alba_-_Cetrer%C3%ADa_-_01.jpg">Carlos Delgado</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Barn owl (Tyto alba), photo by Michael Gäbler",
    src: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Tyto_alba_%28Scopoli%2C_1769%29.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Tyto_alba_(Scopoli,_1769).jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Tyto_alba_(Scopoli,_1769).jpg">Michael Gäbler</a>, <a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Plains Zebra in Etosha National Park, Namibia, photo by Yathin S Krishnappa",
    src: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Equus_quagga_%28Namutoni%2C_2012%29.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Equus_quagga_(Namutoni,_2012).jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Equus_quagga_(Namutoni,_2012).jpg">Yathin S Krishnappa</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Plains Zebra in Etosha National Park, Namibia, photo by Yathin S Krishnappa",
    src: "https://upload.wikimedia.org/wikipedia/commons/4/45/Equus_quagga_burchellii_-_Etosha%2C_2014.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Equus_quagga_burchellii_-_Etosha,_2014.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Equus_quagga_burchellii_-_Etosha,_2014.jpg">Yathin S Krishnappa</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Wild horses,  Šar Mountains National Park in Dragaš, Kosovo, photo by AljabakPhoto",
    src: "https://upload.wikimedia.org/wikipedia/commons/6/61/Wild_horses%2C_%C5%A0ar_Mountains.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Wild_horses,_%C5%A0ar_Mountains.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Wild_horses,_%C5%A0ar_Mountains.jpg">Aljabakphoto</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Domestic horse in Suryachaur, Nepal, photo by Shadow Ayush",
    src: "https://upload.wikimedia.org/wikipedia/commons/b/be/Domestic_horse_at_Suryachaur_and_the_mountains_in_the_back1.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Domestic_horse_at_Suryachaur_and_the_mountains_in_the_back1.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Domestic_horse_at_Suryachaur_and_the_mountains_in_the_back1.jpg">Shadow Ayush</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Burchell's zebras (Equus quagga burchellii), Okavango Delta, Botswana, photo by Diego Delso",
    src: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Cebras_de_Burchell_%28Equus_quagga_burchellii%29%2C_vista_a%C3%A9rea_del_delta_del_Okavango%2C_Botsuana%2C_2018-08-01%2C_DD_30.jpg",
    link: "https://commons.wikimedia.org/wiki/File:Cebras_de_Burchell_(Equus_quagga_burchellii),_vista_a%C3%A9rea_del_delta_del_Okavango,_Botsuana,_2018-08-01,_DD_30.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Cebras_de_Burchell_(Equus_quagga_burchellii),_vista_a%C3%A9rea_del_delta_del_Okavango,_Botsuana,_2018-08-01,_DD_30.jpg">Diego Delso</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Black-tufted marmoset, La Palma, Canary Islands, photo by H. Zell",
    link: "https://commons.wikimedia.org/wiki/File:Callithrix_penicillata_-_Maroparque_02.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Callithrix_penicillata_-_Maroparque_02.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Callithrix_penicillata_-_Maroparque_02.jpg">H. Zell</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Caudal fin of a southern right whale (Eubalaena australis) near Valdés, Argentina, photo by Dr.Haus",
    link: "https://commons.wikimedia.org/wiki/File:Southern_right_whale_caudal_fin-2.JPG",
    src: "https://upload.wikimedia.org/wikipedia/commons/8/84/Southern_right_whale_caudal_fin-2.JPG",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Southern_right_whale_caudal_fin-2.JPG">Southern_right_whale_caudal_fin.JPG: Dr.Hausderivative work: Parzi</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "View from of Langtang Himal from Suryachaur (सुर्यचौर), Nepal, photo by Shadow Ayush",
    link: "https://commons.wikimedia.org/wiki/File:View_of_Langtang_Himal_from_Suryachaur,_Nuwakot.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/9/94/View_of_Langtang_Himal_from_Suryachaur%2C_Nuwakot.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:View_of_Langtang_Himal_from_Suryachaur,_Nuwakot.jpg">Shadow Ayush</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Lake Nyos, Cameroon, photo by Jack Lockwood, USGS",
    link: "https://commons.wikimedia.org/wiki/File:Nyos_Lake.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/8/89/Nyos_Lake.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Nyos_Lake.jpg">Jack Lockwood, USGS</a>, Public domain, via Wikimedia Commons',
  },
  {
    title:
      "Humpback whale (Megaptera novaeangliae) near its whale calf, Tahiti, French Polynesia, photo by Jérémie Silvestro",
    link: "https://commons.wikimedia.org/wiki/File:Baleine_%C3%A0_bosse_et_son_baleineau_2.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Baleine_%C3%A0_bosse_et_son_baleineau_2.jpg",
    attribution:
      '<span style="display:inline-block; transform: rotate(180deg);">©</span> <a href="/wiki/User:Avatea" title="User:Avatea">Jérémie Silvestro</a>&nbsp;/&nbsp;<a href="/wiki/Main_Page" title="Main Page">Wikimedia Commons</a>',
  },
  {
    title:
      "Arctic Wolf in the Wisentgehege Springe game park, Germany, photo by Michael Gäbler",
    link: "https://commons.wikimedia.org/wiki/File:Canis_lupus_arctos_(Pocock,_1935).jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/8/89/Canis_lupus_arctos_%28Pocock%2C_1935%29.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Canis_lupus_arctos_(Pocock,_1935).jpg">Michael Gäbler</a>, <a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Cheetah (Acinonyx jubatus) brothers, Okavango Delta, Botswana, photo by Arturo de Frias Marques",
    link: "https://commons.wikimedia.org/wiki/File:Cheetah_Brothers_AdF.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Cheetah_Brothers_AdF.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Cheetah_Brothers_AdF.jpg">Arturo de Frias Marques</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Cheetah (Acinonyx jubatus) in Masai Mara National Reserve, Kenya, photo by Thomas Fuhrmann",
    link: "https://commons.wikimedia.org/wiki/File:Masai_Mara_National_Reserve_07_-_cheetah_(Acinonyx_jubatus).jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/2/23/Masai_Mara_National_Reserve_07_-_cheetah_%28Acinonyx_jubatus%29.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Masai_Mara_National_Reserve_07_-_cheetah_(Acinonyx_jubatus).jpg">Thomas Fuhrmann</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Lion (Panthera leo) in Chobe National Park, Botswana, photo by Charles J. Sharp",
    link: "https://commons.wikimedia.org/wiki/File:Lion_(Panthera_leo)_old_male_Chobe.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Lion_%28Panthera_leo%29_old_male_Chobe.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Lion_(Panthera_leo)_old_male_Chobe.jpg">Charles J. Sharp</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Malayan Tiger in Dortmund zoological garden, Germany, photo by Hans Stieglitz",
    link: "https://commons.wikimedia.org/wiki/File:Tiger-2.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/4/41/Tiger-2.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Tiger-2.jpg">Hans Stieglitz</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "European Wildcat (Felis silvestris silvestris), photo by Luc Viatour",
    link: "https://commons.wikimedia.org/wiki/File:Felis_silvestris_silvestris_Luc_Viatour.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Felis_silvestris_silvestris_Luc_Viatour.jpg",
    notes: "https://commons.wikimedia.org/wiki/User:Lviatour",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Felis_silvestris_silvestris_Luc_Viatour.jpg">Lviatour</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title: "Blue-and-yellow macaw (Ara ararauna), photo by Luc Viatour",
    link: "https://commons.wikimedia.org/wiki/File:Ara_ararauna_Luc_Viatour.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Ara_ararauna_Luc_Viatour.jpg",
    notes: "https://commons.wikimedia.org/wiki/User:Lviatour",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Ara_ararauna_Luc_Viatour.jpg">I, Luc Viatour</a>, <a href="https://creativecommons.org/licenses/by/2.0">CC BY 2.0</a>, via Wikimedia Commons',
  },
  {
    title: "Cat, photo by Von Grzanka",
    link: "https://commons.wikimedia.org/wiki/File:Felis_catus-cat_on_snow.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Felis_catus-cat_on_snow.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Felis_catus-cat_on_snow.jpg">Von.grzanka</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
    notes: "von.grzanka@gmail.com",
  },
  {
    title: "White cat with heterochromia, photo by Keith Kissel",
    link: "https://commons.wikimedia.org/wiki/File:June_odd-eyed-cat_cropped.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/6/69/June_odd-eyed-cat_cropped.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:June_odd-eyed-cat_cropped.jpg">Keith Kissel</a>, <a href="https://creativecommons.org/licenses/by/2.0">CC BY 2.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Pouting (Trisopterus luscus), Arrábida National Park, Portugal, photo by Diego Delso",
    link: "https://commons.wikimedia.org/wiki/File:Faneca_(Trisopterus_luscus),_Parque_natural_de_la_Arr%C3%A1bida,_Portugal,_2022-07-29,_DD_43.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Faneca_%28Trisopterus_luscus%29%2C_Parque_natural_de_la_Arr%C3%A1bida%2C_Portugal%2C_2022-07-29%2C_DD_43.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Faneca_(Trisopterus_luscus),_Parque_natural_de_la_Arr%C3%A1bida,_Portugal,_2022-07-29,_DD_43.jpg">Diego Delso</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Orbicular batfish (Platax orbicularis), Red Sea, Egypt, photo by Diego Delso",
    link: "https://commons.wikimedia.org/wiki/File:Pez_murci%C3%A9lago_orbicular_(Platax_orbicularis),_mar_Rojo,_Egipto,_2023-04-19,_DD_01.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Pez_murci%C3%A9lago_orbicular_%28Platax_orbicularis%29%2C_mar_Rojo%2C_Egipto%2C_2023-04-19%2C_DD_01.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Pez_murci%C3%A9lago_orbicular_(Platax_orbicularis),_mar_Rojo,_Egipto,_2023-04-19,_DD_01.jpg">Diego Delso</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Paracanthurus hepatus, photo by Holger Krisp",
    link: "https://commons.wikimedia.org/wiki/File:Paracanthurus-hepatus-paletten-doktorfisch.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Paracanthurus-hepatus-paletten-doktorfisch.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Paracanthurus-hepatus-paletten-doktorfisch.jpg">Holger Krisp</a>, <a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Longnose parrotfish (Hipposcarus harid), Red Sea, Egypt, photo by Diego Delso",
    link: "https://commons.wikimedia.org/wiki/File:Pez_loro_candelamoa_(Hipposcarus_harid),_mar_Rojo,_Egipto,_2023-04-15,_DD_113.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Pez_loro_candelamoa_%28Hipposcarus_harid%29%2C_mar_Rojo%2C_Egipto%2C_2023-04-15%2C_DD_113.jpg/1600px-Pez_loro_candelamoa_%28Hipposcarus_harid%29%2C_mar_Rojo%2C_Egipto%2C_2023-04-15%2C_DD_113.jpg?20231221210554",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Pez_loro_candelamoa_(Hipposcarus_harid),_mar_Rojo,_Egipto,_2023-04-15,_DD_113.jpg">Diego Delso</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "A flying gurnard (Dactylopterus volitans), Crete, Greece, photo by Beckmannjan",
    link: "https://commons.wikimedia.org/wiki/File:Flughahn.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Flughahn.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Flughahn.jpg">Beckmannjan</a>, <a href="http://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "White seabream (Diplodus sargus), Arrábida National Park, Portugal, photo by Diego Delso",
    link: "https://commons.wikimedia.org/wiki/File:Sargo_com%C3%BAn_(Diplodus_sargus),_Parque_natural_de_la_Arr%C3%A1bida,_Portugal,_2020-07-21,_DD_56.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/9/99/Sargo_com%C3%BAn_%28Diplodus_sargus%29%2C_Parque_natural_de_la_Arr%C3%A1bida%2C_Portugal%2C_2020-07-21%2C_DD_56.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Sargo_com%C3%BAn_(Diplodus_sargus),_Parque_natural_de_la_Arr%C3%A1bida,_Portugal,_2020-07-21,_DD_56.jpg">Diego Delso</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Blue shark (Prionace glauca), Faial-Pico Channel, Azores Islands, Portugal, photo by Diego Delso",
    link: "https://commons.wikimedia.org/wiki/File:Tibur%C3%B3n_azul_(Prionace_glauca),_canal_Fayal-Pico,_islas_Azores,_Portugal,_2020-07-27,_DD_14.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/7/71/Tibur%C3%B3n_azul_%28Prionace_glauca%29%2C_canal_Fayal-Pico%2C_islas_Azores%2C_Portugal%2C_2020-07-27%2C_DD_14.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Tibur%C3%B3n_azul_(Prionace_glauca),_canal_Fayal-Pico,_islas_Azores,_Portugal,_2020-07-27,_DD_14.jpg">Diego Delso</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Urban Golden-backed Frog (Indosylvirana urbis), Kerala, India, photo by Jeevan Jose",
    link: "https://commons.wikimedia.org/wiki/File:Indosylvirana_urbis-Kadavoor-2017-05-05-001.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/1/19/Indosylvirana_urbis-Kadavoor-2017-05-05-001.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Indosylvirana_urbis-Kadavoor-2017-05-05-001.jpg">© 2017 Jee &amp; Rani Nature Photography (License: CC BY-SA 4.0)</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Three moon jellyfishes (Aurelia aurita), photo by W.carter",
    link: "https://commons.wikimedia.org/wiki/File:Three_moon_jellyfishes_captured_by_a_lion%27s_mane_jellyfish_1.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Three_moon_jellyfishes_captured_by_a_lion%27s_mane_jellyfish_1.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Three_moon_jellyfishes_captured_by_a_lion%27s_mane_jellyfish_1.jpg">W.carter</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "A Mandarin Duck, photo by Luc Viatour",
    link: "https://commons.wikimedia.org/wiki/File:Aix_galericulata_Luc_Viatour.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Aix_galericulata_Luc_Viatour.jpg",
    notes: "https://commons.wikimedia.org/wiki/User:Lviatour",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Aix_galericulata_Luc_Viatour.jpg">Lviatour</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Mute Swan (Cygnus olor) landing at its nest, photo by Böhringer Friedrich",
    link: "https://commons.wikimedia.org/wiki/File:Cygnus_olor,_nests_with_eggs,_H%C3%B6ckerschwan_mit_Nest.JPG",
    src: "https://upload.wikimedia.org/wikipedia/commons/2/23/Cygnus_olor%2C_nests_with_eggs%2C_H%C3%B6ckerschwan_mit_Nest.JPG",
    notes:
      "Feel free to use my photos, but please mention me as the author and if you want send me a message. or (rufre@lenz-nenning.at)",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Cygnus_olor,_nests_with_eggs,_H%C3%B6ckerschwan_mit_Nest.JPG">Böhringer Friedrich</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0/at/deed.en">CC BY-SA 3.0 AT</a>, via Wikimedia Commons',
  },
  {
    title: "Mute Swan (Cygnus olor) with nine cygnets, photo by	S Sepp",
    link: "https://commons.wikimedia.org/wiki/File:Swan_with_nine_cygnets_3.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Swan_with_nine_cygnets_3.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Swan_with_nine_cygnets_3.jpg">I, S Sepp</a>, <a href="http://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Quiver Tree Forest near Keetmanshoop, Namibia, photo by	Hans Stieglitz",
    link: "https://commons.wikimedia.org/wiki/File:K%C3%B6cherbaumwald-01.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/6/69/K%C3%B6cherbaumwald-01.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:K%C3%B6cherbaumwald-01.jpg">Hans Stieglitz</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title: "Orange Daylily, Ukraine, Vinnytsia, photo by George Chernilevsky",
    link: "https://commons.wikimedia.org/wiki/File:Hemerocallis_fulva_2012_G1.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/2/23/Hemerocallis_fulva_2012_G1.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Hemerocallis_fulva_2012_G1.jpg">George Chernilevsky</a>, Public domain, via Wikimedia Commons',
  },
  {
    title:
      "A sunflower field (Helianthus annuus), Basque Country, Spain, photo by Basotxerri",
    link: "https://commons.wikimedia.org/wiki/File:Arkaia_-_Girasoles_-BT-_04.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Arkaia_-_Girasoles_-BT-_04.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Arkaia_-_Girasoles_-BT-_04.jpg">Basotxerri</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Rainbow over the volcanic Tolbachik area, Kamchatka, Russia",
    link: "https://commons.wikimedia.org/wiki/File:%D0%A0%D0%B0%D0%B4%D1%83%D0%B3%D0%B0_%D0%BD%D0%B0%D0%B4_%D0%B2%D1%83%D0%BB%D0%BA%D0%B0%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%BC%D0%B8_%D0%BA%D0%BE%D0%BD%D1%83%D1%81%D0%B0%D0%BC%D0%B8.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/3/34/%D0%A0%D0%B0%D0%B4%D1%83%D0%B3%D0%B0_%D0%BD%D0%B0%D0%B4_%D0%B2%D1%83%D0%BB%D0%BA%D0%B0%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%BC%D0%B8_%D0%BA%D0%BE%D0%BD%D1%83%D1%81%D0%B0%D0%BC%D0%B8.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:%D0%A0%D0%B0%D0%B4%D1%83%D0%B3%D0%B0_%D0%BD%D0%B0%D0%B4_%D0%B2%D1%83%D0%BB%D0%BA%D0%B0%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%BC%D0%B8_%D0%BA%D0%BE%D0%BD%D1%83%D1%81%D0%B0%D0%BC%D0%B8.jpg">Ted.ns</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Aurora over Flakstad, Lofoten, Norway, photo by Johannes Groll",
    link: "https://commons.wikimedia.org/wiki/File:Lofoten,_Norway_(Unsplash).jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/8/86/Lofoten%2C_Norway_%28Unsplash%29.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Lofoten,_Norway_(Unsplash).jpg">Johannes Groll followhansi</a>, CC0, via Wikimedia Commons',
  },
  {
    title: "Northern lights in Maardu, Estonia, photo by Maxim Bilovitskiy",
    link: "https://commons.wikimedia.org/wiki/File:Maardu_Northern_lights_%E2%80%94_Estonia,_2022.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Maardu_Northern_lights_%E2%80%94_Estonia%2C_2022.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Maardu_Northern_lights_%E2%80%94_Estonia,_2022.jpg">Maxim Bilovitskiy</a>, <a href="https://creativecommons.org/licenses/by/4.0">CC BY 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Coves dels Hams, Mallorca, Spain, photo by A.Saviny",
    link: "https://commons.wikimedia.org/wiki/File:Mallorca_Porto_Cristo_Coves_dels_Hams_asv2023-04_img04.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Mallorca_Porto_Cristo_Coves_dels_Hams_asv2023-04_img04.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Mallorca_Porto_Cristo_Coves_dels_Hams_asv2023-04_img04.jpg">A.Savin</a>, FAL, via Wikimedia Commons',
  },
  {
    title: "Icy shores of Lake Michigan, photo by Chris Wemmert",
    link: "https://commons.wikimedia.org/wiki/File:Icy_shores_of_Lake_Michigan.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Icy_shores_of_Lake_Michigan.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Icy_shores_of_Lake_Michigan.jpg">NOAA Photo Library</a>, Public domain, via Wikimedia Commons',
  },
  {
    title:
      "Steam phase eruption of Castle Geyser in Yellowstone National Park, photo by Brocken Inaglory",
    link: "https://commons.wikimedia.org/wiki/File:Steam_Phase_eruption_of_Castle_geyser_with_double_rainbow.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/4/49/Steam_Phase_eruption_of_Castle_geyser_with_double_rainbow.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Steam_Phase_eruption_of_Castle_geyser_with_double_rainbow.jpg">Brocken Inaglory</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title: "Lightning over Schaffhausen, Switzerland, photo by Hansueli Krapf",
    link: "https://commons.wikimedia.org/wiki/File:Lightning_14.07.2009_20-42-33.JPG",
    src: "https://upload.wikimedia.org/wikipedia/commons/2/22/Lightning_14.07.2009_20-42-33.JPG",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Lightning_14.07.2009_20-42-33.JPG">Hansueli Krapf</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Clouds above Baba Mountain, North Macedonia, photo by Hansueli Krapf",
    link: "https://commons.wikimedia.org/wiki/File:%D0%9F%D0%B0%D1%80%D0%BA_%D0%9F%D0%B5%D0%BB%D0%B8%D1%81%D1%82%D0%B5%D1%80_2015.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/d/d1/%D0%9F%D0%B0%D1%80%D0%BA_%D0%9F%D0%B5%D0%BB%D0%B8%D1%81%D1%82%D0%B5%D1%80_2015.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:%D0%9F%D0%B0%D1%80%D0%BA_%D0%9F%D0%B5%D0%BB%D0%B8%D1%81%D1%82%D0%B5%D1%80_2015.jpg">Шпиц</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Serranía de Hornocal rock formation near Huamahuaca, Argentina, photo by Havardtl",
    link: "https://commons.wikimedia.org/wiki/File:Serran%C3%ADa_de_Hornocal_up_close_near_Humahuaca.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/2/24/Serran%C3%ADa_de_Hornocal_up_close_near_Humahuaca.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Serran%C3%ADa_de_Hornocal_up_close_near_Humahuaca.jpg">Havardtl</a>, <a href="https://creativecommons.org/licenses/by/4.0">CC BY 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "James flamingoes in the Laguna Colorada, Bolivia, photo by Havardtl",
    link: "https://commons.wikimedia.org/wiki/File:James%27s_Flamingoes_in_Laguna_Colorada,_Bolivia.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/e/e5/James%27s_Flamingoes_in_Laguna_Colorada%2C_Bolivia.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:James%27s_Flamingoes_in_Laguna_Colorada,_Bolivia.jpg">Havardtl</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Mount Ararat and the Araratian plain, near Artashat, Armenia, photo by Serouj Ourishian",
    link: "https://commons.wikimedia.org/wiki/File:Mount_Ararat_and_the_Araratian_plain.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Mount_Ararat_and_the_Araratian_plain.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Mount_Ararat_and_the_Araratian_plain.jpg">Սէրուժ Ուրիշեան (Serouj Ourishian)</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "Cayo Coco beach at sunrise, Cuba, photo by Didier Baertschiger",
    link: "https://commons.wikimedia.org/wiki/File:Cuba_-_Cayo_Coco.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/9/96/Cuba_-_Cayo_Coco.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Cuba_-_Cayo_Coco.jpg">Didier Baertschiger</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0">CC BY-SA 2.0</a>, via Wikimedia Commons',
  },
  {
    title:
      "Kuang Si Falls, Luang Prabang province, Laos, photo by Basile Morin",
    link: "https://commons.wikimedia.org/wiki/File:Kuang_Si_Falls_and_its_emerald_water_pools_in_Luang_Prabang_province_Laos.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Kuang_Si_Falls_and_its_emerald_water_pools_in_Luang_Prabang_province_Laos.jpg",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Kuang_Si_Falls_and_its_emerald_water_pools_in_Luang_Prabang_province_Laos.jpg">Basile Morin</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  {
    title: "El Guamache Bay, Margarita island, Venezuela, photo by Wilfredor",
    link: "https://commons.wikimedia.org/wiki/File:El_Guamache_Bay,_Margarita_island.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/d/d8/El_Guamache_Bay%2C_Margarita_island.jpg",
    notes: "https://commons.wikimedia.org/wiki/User:Wilfredor",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:El_Guamache_Bay,_Margarita_island.jpg">Wilfredor</a>, CC0, via Wikimedia Commons',
  },
  {
    title: "Mercado Municipal (São Paulo, Brazil), photo by Wilfredor",
    link: "https://commons.wikimedia.org/wiki/File:Mercado_Municipal_(S%C3%A3o_Paulo)_07.jpg",
    src: "https://upload.wikimedia.org/wikipedia/commons/3/3a/Mercado_Municipal_%28S%C3%A3o_Paulo%29_07.jpg",
    notes: "https://commons.wikimedia.org/wiki/User:Wilfredor",
    attribution:
      '<a href="https://commons.wikimedia.org/wiki/File:Mercado_Municipal_(S%C3%A3o_Paulo)_07.jpg">Wilfredor</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>, via Wikimedia Commons',
  },
  // {
  //   title: "Atlas moth (Attacus atlas), photo by Thomas Bresson",
  //   link: "https://commons.wikimedia.org/wiki/File:ComputerHotline_-_Lepidoptera_sp._(by)_(30).jpg",
  //   src: "https://upload.wikimedia.org/wikipedia/commons/d/d2/ComputerHotline_-_Lepidoptera_sp._%28by%29_%2830%29.jpg",
  //   attribution:
  //     '<a href="https://commons.wikimedia.org/wiki/File:ComputerHotline_-_Lepidoptera_sp._(by)_(30).jpg">Thomas Bresson</a>, <a href="https://creativecommons.org/licenses/by/2.0">CC BY 2.0</a>, via Wikimedia Commons',
  //   notes:
  //     "This photo / video was taken by Thomas Bresson. Please credit this with : Photo : Thomas Bresson or Video : Thomas Bresson in the immediate vicinity of the image. If you use one of my photos, please email me (account needed) or leave me a short message on my discussion page. It would be greatly appreciated. Do not copy this image illegally by ignoring the terms of the attribution below, as it is not in the public domain.  If you would like special permission to use, attribution, or purchase the image please contact me to negotiate terms.",
  // },
  // {
  //   title: "Aphid on a snowdrop flower, photo by Reinhold Möller",
  //   link: "https://commons.wikimedia.org/wiki/File:Galanthus_nivalis_mit_Aphididae--20210301-RM-153708.jpg",
  //   src: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Galanthus_nivalis_mit_Aphididae--20210301-RM-153708.jpg",
  //   notes:
  //     "Feel free to use my photos, but please mention me as the author and send me a message: https://commons.wikimedia.org/w/index.php?title=User_talk:Ermell&action=edit&section=new",
  // },
  // Contact before making live
  // {
  //   title: "Great Mormon (Papilio memnon)",
  //   src: "/images/butterflygreatmormon.jpg",
  //   link: 'https://commons.wikimedia.org/wiki/File:Papilio-pjt1.jpg',
  //   notes:
  //     "If you use the picture outside Wikipedia I would appreciate a short e-mail to pjt56@gmx.net",
  // },
].map((el) => ({
  ...el,
  contentType: "image",
  title: `${el.title} (Wikipedia)`,
}));
