/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getMediaQuery } from "../../../utils";
import { aboutCopy } from "../../../copy/aboutCopy";

export const WebLinks = () => (
  <div
    css={css`
      /* width: 100%; */
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0px;
      ${getMediaQuery("tabletMd")} {
        flex-direction: column;
      }
      ${getMediaQuery("tabletLg")} {
        flex-direction: row;
      }
      ${getMediaQuery("desktopMd")} {
        justify-content: space-between;
        padding: 10px;
      }
      padding-top: 10px;
      .globe {
        /* ${getMediaQuery("tabletMd")} {
          display: none;
        } */
      }
    `}
  >
    <img src="/images/globegif.gif" className="globe" />
    <div
      css={css`
        background: no-repeat url("/images/display/pinkpostit.png");
        background-size: contain;
        width: 350px;
        height: 350px;
        color: black;
        padding: 10px;
      `}
    >
      <div
        css={css`
          font-family: "Comic Sans MS", "Comic Sans", Times;
          font-size: 14px;
          width: 90%;
          ul {
            margin: 20px;
            padding: 0;
          }
        `}
      >
        <p
          css={css`
            padding-left: 65px;
          `}
        >
          {aboutCopy.linksSection.heading}
        </p>
        <ul>
          {aboutCopy.linksSection.webLinks.map((link) => (
            <li key={link}>
              <a href={link} target="_blank">
                {link}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);
