/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getMediaQuery } from "../../../utils";
import { myLinks } from "../../../data/links";

export const Letter = () => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    `}
  >
    <div
      css={css`
        align-self: flex-end;
        display: flex;
        ${getMediaQuery("desktopSm")} {
          width: 90%;
        }
        ${getMediaQuery("desktopMd")} {
          justify-content: space-between;
          width: 80%;
        }
        ${getMediaQuery("desktopMd")} {
          width: 100%;
        }
        .eternity-gifs {
          display: none;
          ${getMediaQuery("desktopSm")} {
            display: block;
          }
        }
        .kiss-gif-1 {
          ${getMediaQuery("tabletLg")} {
            display: none;
          }
          ${getMediaQuery("desktopMd")} {
            display: block;
          }
        }
      `}
    >
      <div className="eternity-gifs">
        {/* <img src="/images/eternitygif.gif" /> */}
        <img src="/images/vortexgif.gif" />
      </div>
      <img
        src="/images/kissgif2.gif"
        css={css`
          align-self: flex-end;
        `}
        className="kiss-gif-1"
      />
    </div>
    <div css={letterContainer} className="contact">
      <div>
        <i>
          You can get in touch by sending me an{" "}
          <img src="/images/mailboxsigngif.gif" alt="Email" /> at{" "}
          <a href="mailto:kyriehalomusic@gmail.com">kyriehalomusic@gmail.com</a>
        </i>
        .
      </div>
      <div>
        <i>
          You can check out my music on <a href={myLinks.spotify}>Spotify</a> or{" "}
          <a href={myLinks.bandcamp}>Bandcamp</a> and I also make video collages
          that I share on <a href={myLinks.youtube}>YouTube</a>.
        </i>
        <img src="/images/musicstavegif.gif" />
      </div>
      <div>
        <i>
          I owe every song I’ve ever written to my dad’s CD collection, my
          incredibly patient piano teachers, all the talented musicians I’ve
          been lucky enough to call my friends over the years and all the
          amazing music I've discovered online.
        </i>
      </div>
      <div>
        <i>
          To everyone who has taken the time to listen, I am incredibly
          grateful.
        </i>
      </div>
      <div
        css={css`
          align-self: flex-start;
        `}
      >
        <div>
          <i>Love,</i>
        </div>
        <div>
          <i>Kyrie</i>
        </div>
        <div>
          <i>xo</i>
        </div>
      </div>
    </div>
    <div
      css={css`
        ${getMediaQuery("desktopSm")} {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 80%;
        }
        ${getMediaQuery("desktopMd")} {
          .piano-keys {
            padding-top: 20px;
            width: 300px;
          }
        }
      `}
    >
      <img
        src="/images/kissgif.gif"
        css={css`
          padding: 10px;
        `}
      />
      <img
        src="/images/pianokeysgifhorizontal.gif"
        css={css`
          padding: 10px;
        `}
        className="piano-keys"
      />
    </div>
  </div>
);

const letterContainer = css`
  background: no-repeat url("/images/display/notepadsheet.png");
  padding: 10px 10px 10px 80px;
  color: black;
  a {
    color: black;
  }
  font-family: Times, "Times New Roman", serif;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  img {
    width: 50px;
  }
  font-weight: 540;
  max-width: 270px;
  ${getMediaQuery("desktopMd")} {
    width: 390px;
    max-width: 400px;
  }
`;
