import { useEffect, useState } from "react";
import { arrayIncludesObj } from "../utils/arrayIncludesObj";
import { Something, somethings, SongTag } from "../somethings";
import { shuffleArray } from "../utils/shuffleArray";
import * as R from "ramda";

type SomethingArr = (Something | null)[];

export const useGetSomethings = (songTag?: SongTag, quantity = 3) => {
  const [refresh, setRefresh] = useState(false);
  const [initialSomethings, setInitialSomethings] = useState<Something[]>([]);
  const [somethingsToDisplay, setSomethingsToDisplay] = useState<SomethingArr>(
    []
  );

  const getInitialSomethings = () => {
    localStorage.removeItem("somethingsAlreadyDisplayed");
    if (songTag) {
      return somethings.filter(
        (something) =>
          something.songTags &&
          something.songTags.length > 0 &&
          something.songTags.includes(songTag)
      );
    }
    return somethings;
  };

  useEffect(() => {
    if (refresh) {
      localStorage.removeItem("somethingsAlreadyDisplayed");
      const initialSomethings = getInitialSomethings();
      setInitialSomethings(initialSomethings);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setInitialSomethings(getInitialSomethings());
  }, [songTag]);

  useEffect(() => {
    if (initialSomethings.length > 0) {
      const shuffledSomethings = shuffleArray(initialSomethings).slice(
        0,
        quantity
      );
      setSomethingsToDisplay(shuffledSomethings);
    }
  }, [initialSomethings]);

  const getSomethingsAlreadyDisplayed = (): (Something | undefined)[] => {
    const somethingsDisplayed = localStorage.getItem(
      "somethingsAlreadyDisplayed"
    );
    if (somethingsDisplayed && somethingsDisplayed !== undefined) {
      const somethingsDisplayedArr = JSON.parse(
        somethingsDisplayed
      ) as Something[];
      return somethingsDisplayedArr.filter(
        (obj, index, arr) =>
          arr.findIndex((item) => item.id === obj.id) === index
      );
    }
    return [];
  };

  useEffect(() => {
    const somethingsAlreadyDisplayed = getSomethingsAlreadyDisplayed();
    if (somethingsToDisplay.length > 0) {
      const filteredSomethingsAlreadyDisplayed =
        somethingsAlreadyDisplayed.filter((something) => !R.isNil(something));
      const filteredSomethingsToDisplay = somethingsToDisplay.filter(
        (something) => !R.isNil(something)
      );

      localStorage.setItem(
        "somethingsAlreadyDisplayed",
        JSON.stringify([
          ...filteredSomethingsAlreadyDisplayed,
          ...filteredSomethingsToDisplay,
        ])
      );
    }
  }, [somethingsToDisplay]);

  const getNewSomething = (something: Something) => {
    const itemToRemove = somethingsToDisplay.find((el) =>
      R.equals(el, something)
    ) as Something;
    const somethingsAlreadyDisplayed = getSomethingsAlreadyDisplayed();
    const somethingsRemaining = initialSomethings.filter(
      (el) => !arrayIncludesObj(somethingsAlreadyDisplayed, el)
    );
    if (somethingsRemaining.length < 1) {
      const newSomethingsToDisplay: SomethingArr = [
        ...somethingsToDisplay.slice(
          0,
          somethingsToDisplay.indexOf(itemToRemove!)
        ),
        null,
        ...somethingsToDisplay.slice(
          somethingsToDisplay.indexOf(itemToRemove!) + 1
        ),
      ];
      setSomethingsToDisplay(newSomethingsToDisplay);
    } else {
      const randomNumber = Math.floor(
        Math.random() * somethingsRemaining.length
      );
      const newSomething = somethingsRemaining[randomNumber];
      const newSomethingsToDisplay = [
        ...somethingsToDisplay.slice(
          0,
          somethingsToDisplay.indexOf(itemToRemove)
        ),
        newSomething,
        ...somethingsToDisplay.slice(
          somethingsToDisplay.indexOf(itemToRemove) + 1
        ),
      ];
      setSomethingsToDisplay(newSomethingsToDisplay);
    }
  };

  return { somethingsToDisplay, getNewSomething, setRefresh };
};
