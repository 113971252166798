import { Something } from "../somethings";

export const getTitle = (something: Something) => {
  switch (something.contentType) {
    case "writing":
      return `${something.title} - ${something.author}`;
    case "music":
      return `${something.title} - ${something.artist}`;
    default:
      return something.title;
  }
};
