import { css } from "@emotion/react";
import { ContentType } from "../somethings";

export const getPadding = (contentType?: ContentType, titleLength?: number) => {
  const getPaddingBottom = () => {
    const defaultPaddingBottom = 10;
    if (contentType !== "image" && contentType !== "writing") {
      if (titleLength) {
        let counter = 1;
        let checkpoint = 40;
        for (let i = 0; i < titleLength; i++) {
          if (i > checkpoint) {
            counter++;
            checkpoint += 40;
          }
        }

        const padding = 15 * counter;
        return `${padding}px`;
      }
    }
    return `${defaultPaddingBottom}px`;
  };

  const paddingBottom = getPaddingBottom();

  switch (contentType) {
    case "image":
      return css`
        padding: 0px 10px ${paddingBottom} 10px;
      `;
    case "music":
    case "video":
      return css`
        padding: 0px 10px calc(${paddingBottom} + 20px) 10px;
      `;
    case "website":
      return css`
        padding: 0px 10px calc(${paddingBottom} + 40px) 10px;
      `;
    case "writing":
      return css`
        padding: 0px 10px ${paddingBottom} 10px;
      `;
    default:
      return css`
        padding: 0px 10px ${paddingBottom} 10px;
      `;
  }
};
