/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ContentType, CreativeCommonsImage, Something } from "../somethings";
import { Iframe } from "./";
import parse from "html-react-parser";
import { getMediaQuery, getWidthAndHeight } from "../utils";
import {
  CloseButtonProps,
  ComputerWindowProps,
  WindowContentProps,
  TopBarProps,
} from "./interfaces";
import { NoDragSection } from "./NoDragSection";
import { getPadding } from "../utils/getPadding";
import { RiExternalLinkLine } from "react-icons/ri";

export const ComputerWindow = ({
  children,
  contentType,
  titleLength,
}: ComputerWindowProps) => (
  <div css={draggableContentContainer(titleLength, contentType)}>
    {children}
  </div>
);

const TopBar = ({ title, children }: TopBarProps) => (
  <div css={topBar}>
    {children}
    <span>{title}</span>
  </div>
);

const CloseButton = ({ borderType, onClick }: CloseButtonProps) => (
  <NoDragSection>
    <button
      css={closeButton(borderType)}
      onClick={onClick}
      aria-label="Close window"
    >
      X
    </button>
  </NoDragSection>
);

const WindowContent = ({ something, onContentLoad }: WindowContentProps) => {
  switch (something.contentType) {
    case "video":
    case "music":
      return (
        <Iframe
          link={something.link}
          onLoad={onContentLoad}
          contentType={something.contentType}
        />
      );
    case "website":
      return (
        <div>
          <Iframe
            link={something.link}
            onLoad={onContentLoad}
            contentType={something.contentType}
          />
          <a
            href={something.link}
            target="_blank"
            css={css`
              color: white;
              font-size: 12px;
            `}
          >
            Visit site
          </a>
        </div>
      );
    case "image":
      return (
        <div css={imageContent}>
          <img src={something.src} onLoad={onContentLoad} />
          <div
            css={css`
              font-size: 12px;
              padding: 0px 5px 0px 5px;
            `}
          >
            {isCreativeCommons(something) && parse(something.attribution)}
          </div>
        </div>
      );
    case "writing":
    default:
      return (
        <div css={writingContent}>
          <NoDragSection width="100%">
            {something.link && (
              <a
                href={something.link}
                target="_blank"
                css={css`
                  color: blue;
                `}
              >
                Full text
              </a>
            )}
            <p>{parse(something.text)}</p>
          </NoDragSection>
        </div>
      );
  }
};

TopBar.CloseButton = CloseButton;
ComputerWindow.TopBar = TopBar;
ComputerWindow.Content = WindowContent;

const isCreativeCommons = (
  something: Something
): something is CreativeCommonsImage => Object.hasOwn(something, "attribution");

export const draggableContentContainer = (
  titleLength?: number,
  contentType?: ContentType
) =>
  css`
    ${getWidthAndHeight("mobile", contentType)}
    ${getMediaQuery("tabletSm")} {
      ${getWidthAndHeight("tablet", contentType)}
    }
    ${getMediaQuery("desktopSm")} {
      ${getWidthAndHeight("desktop", contentType)}
    }
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: silver;
    background-color: rgba(236, 147, 232, 1);
    border-left: ridge 2px #fff;
    border-top: ridge 2px #fff;
    border-right: ridge 2px grey;
    border-bottom: ridge 2px grey;
    ${getPadding(contentType, titleLength)};
    div[class$="DraggableComputerWindow"] {
      width: 100%;
    }
  `;

const topBar = css`
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(222, 97, 149, 1) 0%,
    rgb(236, 147, 232) 76%
  );
  color: #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: calc(100% + 10px);
  padding-left: 10px;
  span {
    height: max-content;
    min-height: 18px;
    width: 90%;
  }
  font-family: "Rainy Hearts";
  -webkit-font-smoothing: auto;
  font-smoothing: auto;
`;

const closeButton = (borderType: string) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
  color: black;
  background-color: silver;
  background: rgba(222, 97, 149, 1);
  border-left: ${borderType} 2px #fff;
  border-top: ${borderType} 2px #fff;
  border-right: ${borderType} 2px grey;
  border-bottom: ${borderType} 2px grey;
  width: 20px;
`;

const writingContent = css`
  background-color: white;
  color: black;
  padding: 15px;
  width: calc(100% - 20px);
  height: 200px;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-image: url("/images/display/bgscrollbartrack.png");
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(236, 147, 232);
    border-left: ridge 2px white;
    border-top: ridge 2px white;
    border-right: ridge 2px rgba(222, 97, 149);
    border-bottom: ridge 2px rgba(222, 97, 149);
  }
  overflow-x: hidden;
  overflow-y: scroll;
  ol {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
    margin: 0px;
    li {
      margin-left: 15px;
    }
  }
`;

const imageContent = () => css`
  color: white;
  a {
    color: white;
  }
  width: 100%;
  img {
    ${getWidthAndHeight("mobile", "image")}
    ${getMediaQuery("tabletSm")} {
      ${getWidthAndHeight("tablet", "image")}
    }
    ${getMediaQuery("desktopSm")} {
      ${getWidthAndHeight("desktop", "image")}
    }
    height: auto;
  }
`;
